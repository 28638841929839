export const localize = {
    "vi": {
        "ERP_403_loi": "Bạn không có quyền truy cập để xem trang này",
        "ERP_404_loi": "Trang bạn đang tìm kiếm có thể đã bị xóa, có tên thay đổi hoặc tạm thời không có.",
        "ERP_AC_vui_long_chon_y_kien_cua_minh": "Anh/Chị vui lòng chọn ý kiến của mình tương ứng với mức độ đồng ý cho những dịch vụ cung cấp dưới đây",
        "ERP_Ban_can_nhap_hang_hoa_cho_hoa_don": "Bạn cần nhập hàng hóa cho hóa đơn",
        "ERP_Ban_chac_chan_muon_sao_chep_cot_gia_tri_nay?": "Bạn chắc chắn muốn sao chép giá trị này?",
        "ERP_Ban_chi_duoc_dieu_chinh_giam_0_5_phep": "Bạn chỉ được điều chỉnh giảm 0.5 phép",
        "ERP_Ban_chi_duoc_phep_sua_so_luong_lon_hon_hoac_bang_so_luong_da_trien_khai_la": "Bạn chỉ được phép sửa số lượng lớn hơn hoặc bằng số lượng đã triển khai là",
        "ERP_Ban_chua_trien_khai_hop_dong_ban_khong_duoc_ket_thuc_hop_dong": "Bạn chưa triển khai hợp đồng. Bạn không được kết thúc hợp đồng.",
        "ERP_Ban_co_chac_chan_muon_xac_nhan_chi_tiet_giao_hang_nay_khong": "Bạn có chắc chắn muốn xác nhận chi tiết giao hàng này không ?",
        "ERP_Ban_co_chac_chan_muon_xac_nhan_tat_ca_cac_chi_tiet_giao_hang_khong": "Bạn có chắc chắn muốn xác nhận tất cả các chi tiết giao hàng không ?",
        "ERP_Ban_co_chac_chan_muon_xoa_du_lieu_nay": "Bạn có chắc chắn muốn xóa dữ liệu này ?",
        "ERP_Ban_co_chac_chan_muon_xoa_thong_tin_thanh_toan_nay_khong": "Bạn có chắc chắn muốn xóa thông tin thanh toán này không ?",
        "ERP_Ban_co_chac_muon_bo_trang_thai_nay?": "Bạn có chắc muốn bỏ trạng thái này?",
        "ERP_Ban_co_chac_muon_duyet": "Bạn có chắc muốn duyệt?",
        "ERP_Ban_co_chac_muon_huy_phep?": "Bạn có chắc muốn hủy phép?",
        "ERP_Ban_co_chac_muon_luu_khong?": "Bạn có chắc muốn lưu không?",
        "ERP_Ban_co_chac_muon_xoa?": "Bạn có chắc muốn xoá?",
        "ERP_Ban_co_muon_chuyen_trang_thai_chung_tu": "Bạn có muốn chuyển trạng thái chứng từ thành chưa hoàn tất",
        "ERP_Ban_co_muon_tiep_tuc_sua": "Bạn đã nhập dữ liệu Chi tiết phun trùng. Bạn có muốn tiếp tục sửa không?",
        "ERP_Ban_co_muon_xoa_du_lieu": "Bạn có muốn xóa dữ liệu",
        "ERP_Ban_da_check_hoan_tat_chung_tu_nay": "Bạn đã check hoàn thành chứng từ này",
        "ERP_Ban_da_check_hoan_thanh_chung_tu_nay": "Bạn đã check hoàn thành chứng từ này",
        "ERP_Ban_da_chon_phuong_thuc_nay": "Bạn đã chọn phương thức này",
        "ERP_Ban_giao": "Bàn giao",
        "ERP_Ban_khong_co_quyen_duyet": "Bạn không có quyền duyệt",
        "ERP_Ban_khong_duoc_sua_so_luong_hang_hoa_nho_hon_so_luong_dang_trien_khai_la": "Bạn không được sửa số lượng hàng hóa nhỏ hơn số lượng đang triển khai là",
        "ERP_Ban_khong_duoc_xoa_hang_hoa_da_trien_khai": "Bạn không được xóa hàng hóa đã triển khai",
        "ERP_Ban_khong_the_luu": "Bạn không thể lưu",
        "ERP_Ban_phai_check_su_dung_truoc": "Bạn phải check sử dụng trước",
        "ERP_Ban_phai_chon_doanh_nghiep": "Bạn phải chọn doanh nghiệp",
        "ERP_Bao_cao": "Báo cáo",
        "ERP_Bao_cao_cong": "Báo cáo công",
        "ERP_Bao_yeu_cau": "Bao yêu cầu",
        "ERP_Bat_buoc": "Bắt buộc",
        "ERP_Bat_buoc_nhap_du_lieu": "Bắt buộc nhập dữ liệu",
        "ERP_Bat_dau": "Bắt đầu",
        "ERP_Bo_duyet": "Bỏ duyệt",
        "ERP_Bo_xac_nhan": "Bỏ xác nhận",
        "ERP_Boi": "Bởi",
        "ERP_CDN_chua_san_sang": "CDN chưa sẵn sàng",
        "ERP_CMND": "CMND",
        "ERP_Ca": "Ca",
        "ERP_Ca_nhan": "Cá nhân",
        "ERP_Cac_dot_trien_khai_chua_ket_thuc_ban_khong_the_ket_thuc_hop_dong": "Các đợt triển khai chưa kết thúc. Bạn không thể kết thúc hợp đồng.",
        "ERP_Cac_gop_y_khac": "Các góp ý khác",
        "ERP_Cac_tham_so_la_bat_buoc": "Các tham số là bắt buộc",
        "ERP_Cac_tieu_chi_la_bat_buoc_nhap": "Các chỉ tiêu bắt buộc nhập",
        "ERP_Cac_truong_khong_bang_nhau": "Các trường không bằng nhau",
        "ERP_Cai_dat": "Cài đặt",
        "ERP_Cang_den": "Cảng đến",
        "ERP_Cang_di": "Cảng đi",
        "ERP_Cap": "Cấp",
        "ERP_Cap_co_cau": "Cấp cơ cấu",
        "ERP_Cap_khen_thuong": "Cấp khen thưởng",
        "ERP_Cap_ky_luat": "Cấp kỷ luật",
        "ERP_Cap_nhat_Lo": "Cập nhật Lô",
        "ERP_Cap_nhat_chung_tu": "Cập nhật chứng từ",
        "ERP_Cap_nhat_cuoc_phi": "Cập nhật cước phí",
        "ERP_Cap_nhat_thong_tin_huy": "Cập nhật thông tin hủy",
        "ERP_Cap_nhat_trang_thai": "Cập nhật trạng thái",
        "ERP_Cap_nhat_trang_thai_duyet": "Cập nhật trạng thái duyệt",
        "ERP_Cap_nhat_trang_thai_duyet_phu_luc": "Cập nhật trạng thái duyệt phụ lục",
        "ERP_Cap_nhat_trang_thai_hoa_don": "Cập nhật trạng thái hóa đơn",
        "ERP_Cap_nhat_trang_thai_hop_dong_lao_dong": "Cập nhật trạng thái hợp đồng lao động",
        "ERP_Cham_cong_chi_tiet": "Chấm công chi tiết",
        "ERP_Cham_cong_theo_du_an": "Chấm công theo dự án",
        "ERP_Chao_buoi_sang": "Chào buổi sáng!",
        "ERP_Chao_mung_login": "Chào mừng đến \\n hệ sinh thái DigiNet",
        "ERP_Chi_phi_huy": "Chi phí hủy",
        "ERP_Chi_tiet": "Chi tiết",
        "ERP_Chi_tiet_bao_bi": "Chi tiết bao bì",
        "ERP_Chi_tiet_can_phan_bo": "Chi tiết cần phân bổ",
        "ERP_Chi_tiet_dieu_kien": "Chi tiết điều kiện",
        "ERP_Chi_tiet_dot_trien_khai": "Chi tiết đợt triển khai",
        "ERP_Chi_tiet_giao_hang": "Chi tiết giao hàng",
        "ERP_Chi_tiet_hoa_don": "Chi tiết hóa đơn",
        "ERP_Chi_tiet_hop_dong": "Chi tiết hợp đồng",
        "ERP_Chi_tiet_hop_dong_ban": "Chi tiết hợp đồng bán",
        "ERP_Chi_tiet_hop_dong_mua": "Chi tiết hợp đồng mua",
        "ERP_Chi_tiet_hop_dong_trong_mapping": "Chi tiết hợp đồng trong mapping",
        "ERP_Chi_tiet_mapping": "Chi tiết mapping",
        "ERP_Chi_tiet_nhan_vien": "Chi tiết nhân viên",
        "ERP_Chi_tiet_phep": "Chi tiết phép",
        "ERP_Chi_tiet_phu_luc_hop_dong_ban": "Chi tiết phụ lục hợp đồng bán",
        "ERP_Chi_tiet_phu_luc_hop_dong_mua": "Chi tiết phụ lục hợp đồng mua",
        "ERP_Chinh_sua": "Chỉnh sửa",
        "ERP_Chinh_tri": "Chính trị",
        "ERP_Cho_duyet": "Chờ duyệt",
        "ERP_Chon": "Chọn",
        "ERP_Chon_ban_giao": "Chọn mẫu bàn giao",
        "ERP_Chon_co_cau_to_chuc": "Chọn cơ cấu tổ chức",
        "ERP_Chon_du_an": "Chọn dự án",
        "ERP_Chon_hop_dong": "Chọn hợp đồng",
        "ERP_Chon_hop_dong_lap_phu_luc": "Chọn hợp đồng lập phụ lục",
        "ERP_Chon_ky": "Chọn kỳ",
        "ERP_Chon_ky_luong": "Chọn kỳ lương",
        "ERP_Chon_mapping": "Chọn mapping",
        "ERP_Chon_mau_bao_cao": "Chọn mẫu báo cáo",
        "ERP_Chon_ngay": "Chọn ngày",
        "ERP_Chon_nhan_vien": "Chọn nhân viên",
        "ERP_Chon_nhom_luong": "Chọn nhóm lương",
        "ERP_Chon_thoi_gian": "Chọn thời gian",
        "ERP_Chon_trang_thai": "Chọn trạng thái",
        "ERP_Chon_trang_thai_duyet": "Chọn trạng thái duyệt",
        "ERP_Chu_de": "Chủ đề",
        "ERP_Chua_chon_hang_hoa": "Vui lòng chọn hàng hóa",
        "ERP_Chua_co_lien_ket_nao": "Chưa có liên kết nào",
        "ERP_Chua_dap_ung": "Chưa đáp ứng",
        "ERP_Chua_duyet": "Chưa duyệt",
        "ERP_Chua_hoan_thanh": "Chưa hoàn thành",
        "ERP_Chua_xac_nhan": "Chưa xác nhận",
        "ERP_Chuc_danh": "Chức danh",
        "ERP_Chuc_danh_cong_viec": "Chức danh công việc",
        "ERP_Chuc_vu": "Chức vụ",
        "ERP_Chuc_vu_bat_buoc_nhap": "Chức vụ bắt buộc nhập",
        "ERP_Chung_minh_nhan_dan": "Chứng minh nhân dân",
        "ERP_Chung_tu": "Chứng từ",
        "ERP_Chung_tu_da_hoan_thanh_khong_the_sua": "Chứng từ đã hoàn thành không thể sửa",
        "ERP_Chung_tu_da_hoan_thanh_khong_the_xoa": "Chứng từ đã hoàn thành không thể xóa",
        "ERP_Chuyen_doi_trang_thai_LOT": "Chuyển đổi trạng thái LOT",
        "ERP_Chuyen_mapping": "Chuyển Mapping",
        "ERP_Chuyen_mon": "Chuyên môn",
        "ERP_Chuyen_nganh": "Chuyên ngành",
        "ERP_Chuyen_trang_thai_Lot": "Chuyển trạng thái LOT",
        "ERP_Co1": "Có",
        "ERP_Co_cau_cha": "Cơ cấu cha",
        "ERP_Co_cau_to_chuc": "Cơ cấu tổ chức",
        "ERP_Co_hop_dong_ban_chua_thuc_hien_mapping": "Có hợp đồng bán chưa thực hiện mapping",
        "ERP_Co_loi_khi_luu": "Có lỗi khi lưu",
        "ERP_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "Có lỗi xảy ra trong quá trình xử lý.",
        "ERP_Co_so_luong_ban_khong_duoc_phep_xoa": "Đợt triển khai có số lượng triển khai. Bạn không được phép xóa.",
        "ERP_Con_dong_chua_duoc_nhap_lieu/Ban_co_muon_luu_khong?": "Còn dòng chưa được nhập liệu. Bạn có muốn lưu không?",
        "ERP_Con_dong_chua_duoc_nhap_lieu_Ban_co_muon_luu_khong": "Còn dòng chưa được nhập liệu. Bạn có muốn lưu không?",
        "ERP_Cong_trinh/Cong_ty": "Công trình/Công ty",
        "ERP_Cong_ty": "Công ty",
        "ERP_Cong_ty_bao_hiem": "Công ty bảo hiểm",
        "ERP_Cong_ty_phun": "Công ty phun",
        "ERP_Cong_viec": "Công việc",
        "ERP_Cong_viec_moi": "Công việc mới",
        "ERP_Cua_lot": "Của Lot",
        "ERP_DHR_Ban_chac_chan_muon_sao_chep_cot_gia_tri_nay?": "Bạn chắc chắn muốn sao chép giá trị này?",
        "ERP_DHR_Gia_tri": "Giá trị",
        "ERP_DHR_Xoa": "Xóa",
        "ERP_Da_duyet": "Đã duyệt",
        "ERP_Da_hoan_tat": "Đã hoàn tất",
        "ERP_Da_ket_noi": "Đã kết nối tới internet",
        "ERP_Da_mapping": "Đã mapping",
        "ERP_Da_nghi_viec": "Đã nghỉ việc",
        "ERP_Da_sao_chep": "Đã sao chép",
        "ERP_Da_trien_khai": "Đã triển khai",
        "ERP_Da_vuot_qua_so_luong_cho_phep": "Đã vược quá số lượng cho phép",
        "ERP_Da_xac_nhan": "Đã xác nhận",
        "ERP_Dai_ly_tau": "Đại lý tàu",
        "ERP_Dan_toc": "Dân tộc",
        "ERP_Dang_Lam_Viec": "Đang làm việc",
        "ERP_Dang_ky_nghi": "Đăng ký nghỉ",
        "ERP_Dang_ky_nghi_viec": "Đăng ký nghỉ việc",
        "ERP_Dang_nhap": "Đăng nhập",
        "ERP_Dang_nhap_lai2": "Bạn đã đăng nhập tài khoản trên một thiết bị khác. Vui lòng đăng nhập lại.",
        "ERP_Dang_xuat": "Dạng xuất",
        "ERP_Dang_xuat1": "Đăng xuất",
        "ERP_Danh_sach_bao_bi": "Danh sách bao bì",
        "ERP_Danh_sach_bao_cao": "Danh sách báo cáo",
        "ERP_Danh_sach_cong_ty": "Danh sách công ty",
        "ERP_Danh_sach_cuoc_phi": "Danh sách cước phí",
        "ERP_Danh_sach_doi_tuong": "Danh sách đối tượng",
        "ERP_Danh_sach_dot_trien_khai": "Danh sách đợt triển khai",
        "ERP_Danh_sach_giao_hang": "Danh sách giao hàng",
        "ERP_Danh_sach_hang_hoa": "Danh sách hàng hóa",
        "ERP_Danh_sach_hang_hoa_chua_trien_khai": "Danh sách hàng hóa chưa triển khai",
        "ERP_Danh_sach_hoa_donU": "Danh sách hóa đơn",
        "ERP_Danh_sach_hop_dong": "Danh sách hợp đồng",
        "ERP_Danh_sach_hop_dong_ban": "Danh sách hợp đồng bán",
        "ERP_Danh_sach_hop_dong_mua": "Danh sách hợp đồng mua",
        "ERP_Danh_sach_lo": "Danh sách Lô",
        "ERP_Danh_sach_lot": "Danh sách Lot",
        "ERP_Danh_sach_mapping": "Danh sách mapping",
        "ERP_Danh_sach_mapping_cu": "Danh sách mapping cũ",
        "ERP_Danh_sach_nhan_vien": "Danh sách nhân viên",
        "ERP_Danh_sach_phu_luc_hop_dong_ban": "Danh sách phụ lục hợp đồng bán",
        "ERP_Danh_sach_phu_luc_hop_dong_mua": "Danh sách phụ lục hợp đồng mua",
        "ERP_Danh_sach_vai_tro": "Danh sách vai trò",
        "ERP_Dap_ung": "Đáp ứng",
        "ERP_De_xuat_dieu_chinh_lao_dong": "Đề xuất điều chuyển lao động",
        "ERP_De_xuat_khen_thuong": "Đề xuất khen thưởng",
        "ERP_De_xuat_ky_luat": "Đề xuất kỷ luật",
        "ERP_Den": "Đến",
        "ERP_Den_ngay": "Đến ngày",
        "ERP_Di_cont": "Đi Cont",
        "ERP_Di_dong": "Di động",
        "ERP_Di_tau": "Đi tàu",
        "ERP_Dia_chi": "Địa chỉ",
        "ERP_Dia_chi_lien_lac": "Địa chỉ liên lạc",
        "ERP_Dia_chi_tam_tru": "Địa chỉ tạm trú",
        "ERP_Dia_chi_thuong_tru": "Địa chỉ thường trú",
        "ERP_Dien_giai": "Diễn giải",
        "ERP_Dien_thoai": "Điện thoại",
        "ERP_Dieu_khoan_thuong_mai": "Điều khoản thương mại",
        "ERP_Dieu_kien": "Điều kiện",
        "ERP_Dieu_kien_dieu_chinh": "Điều kiện điều chỉnh",
        "ERP_Dieu_kien_dieu_chinh_dot_trien_khai": "Điều kiện điều chỉnh đợt triển khai",
        "ERP_Dieu_kien_khong_duoc_de_trong": "Điều kiện không được để trống",
        "ERP_Dinh_dang_file_khong_hop_le": "Định dạng file không hợp lệ",
        "ERP_Dinh_dang_ngay_khong_dung": "Định dạng ngày không hợp lệ!",
        "ERP_Dinh_kem": "Đính kèm",
        "ERP_Dinh_vi_khoa": "Định vị bị khoá",
        "ERP_Doanh_nghiep": "Doanh nghiệp",
        "ERP_Doi_tuong_ap_dung": "Đối tượng áp dụng",
        "ERP_Doi_tuong_cong_phep": "Đối tượng công/ phép",
        "ERP_Doi_tuong_lao_dong": "Đối tượng lao động",
        "ERP_Doi_tuong_tinh_luong": "ĐT tính lương",
        "ERP_Don_gia": "Đơn giá",
        "ERP_Don_gia_dieu_chinh": "Đơn giá điều chỉnh",
        "ERP_Don_gia_khong_duoc_de_trong": "Đơn giá không được để trống",
        "ERP_Don_gia_thuc_te": "Đơn giá thực tế",
        "ERP_Don_vi_chiu_phi": "Đơn vị chịu phí",
        "ERP_Don_vi_det_bao": "Đơn vị dệt bao",
        "ERP_Don_vi_tinh": "Đơn vị tính",
        "ERP_Don_vi_uy_thac": "Đơn vị ủy thác",
        "ERP_Don_vi_uy_thac_khong_duoc_giong_voi_khach_hang_da_chon": "Đơn vị ủy thác không được giống với khách hàng đã chọn",
        "ERP_Don_vi_uy_thac_nay_khong_duoc_giong_voi_nha_cung_cap_da_chon": "Đơn vị ủy thác này không được giống với nhà cung cấp đã chọn",
        "ERP_Dong1": "Đóng",
        "ERP_Dong_goi": "Đóng gói",
        "ERP_Dong_y": "Đồng ý",
        "ERP_Dot_trien_khai": "Đợt triển khai",
        "ERP_Dot_trien_khai_co_lot_da_mapping_ban_khong_the_xoa_ban_co_muon_gui_yeu_cau_xoa_mapping_khong": "Đợt triển khai có Lot đã mapping, bạn không thể xóa. Bạn có muốn gửi yêu cầu xóa mapping không ?",
        "ERP_Dot_trien_khai_co_lot_da_mapping_ban_khong_the_xoa_dot_trien_khai": "Đợt triển khai có Lot đã mapping, bạn không thể xóa đợt triển khai",
        "ERP_Du_an": "Dự án",
        "ERP_Du_an_cong_trinh": "Dự án công trình",
        "ERP_Du_an_moi": "Dự án mới",
        "ERP_Du_lieu": "Dữ liệu",
        "ERP_Du_lieu_cham_phep": "Dữ liệu chấm phép",
        "ERP_Du_lieu_chua_duoc_luu_ban_co_muon_thoat_khong": "Dữ liệu chưa được lưu. Bạn có muốn thoát không ? ",
        "ERP_Du_lieu_chua_duoc_luu_ban_co_muon_tiep_tuc_khong": "Dữ liệu chưa được lưu. Bạn có muốn tiếp tục không ?",
        "ERP_Du_lieu_da_duoc_su_dung": "Dữ liệu đã được sử dụng",
        "ERP_Du_lieu_da_duoc_su_dung_ban_khong_duoc_xoa": "Dữ liệu đã được sử dụng. Bạn không được xóa",
        "ERP_Du_lieu_dac_biet": "Dữ liệu đặc biệt",
        "ERP_Du_lieu_dau_vao_la_bat_buoc": "Dữ liệu đầu vào là bắt buộc",
        "ERP_Du_lieu_phep_tong_hop": "Dữ liệu phép tổng hợp",
        "ERP_Du_lieu_xuat_excel_rong": "Báo cáo không có dữ liệu",
        "ERP_Dung_luong_File_khong_duoc_lon_hon": "Dung lượng File không được lớn hơn",
        "ERP_Dung_sai": "Dung sai",
        "ERP_Duyet": "Duyệt",
        "ERP_Duyet_chung": "Duyệt chung",
        "ERP_Duyet_cuoc_phi": "Duyệt cước phí",
        "ERP_Duyet_de_xuat_dieu_chuyen_lao_dong": "Duyệt đề xuất điều chuyển lao động",
        "ERP_Duyet_de_xuat_khen_thuong": "Duyệt đề xuất khen thưởng",
        "ERP_Duyet_dieu_chinh_thu_nhap": "Duyệt điều chỉnh thu nhập",
        "ERP_Duyet_hop_dong": "Duyệt hợp đồng",
        "ERP_Duyet_khong_thanh_cong": "Duyệt không thành công.",
        "ERP_Duyet_ky_luat": "Duyệt kỷ luật",
        "ERP_Duyet_nghi_phep": "Duyệt nghỉ phép",
        "ERP_Duyet_thanh_cong": "Duyệt thành công",
        "ERP_Duyet_yeu_cau_tach_lot": "Duyệt yêu cầu tách lot",
        "ERP_Email": "Email",
        "ERP_Email_ca_nhan": "Email cá nhân",
        "ERP_Email_ho_tro": "hr@diginet.com.vn",
        "ERP_Email_khong_hop_le": "Email không hợp lệ",
        "ERP_Ghi_chu": "Ghi chú",
        "ERP_Ghi_chu_deposit": "Ghi chú deposit",
        "ERP_Ghi_chu_duyet": "Ghi chú duyệt",
        "ERP_Gia": "Giá",
        "ERP_Gia_dinh": "Gia đình",
        "ERP_Gia_mo_to_khai": "Giá mở tờ khai",
        "ERP_Gia_tri": "Giá trị",
        "ERP_Gia_tri_bat_buoc_nhap": "Giá trị bắt buộc nhập!",
        "ERP_Gia_tri_den": "Giá trị đến",
        "ERP_Gia_tri_den_khong_de_trong": "Giá trị đến không được để trống",
        "ERP_Gia_tri_dot_trien_khai": "Giá trị đợt triển khai",
        "ERP_Gia_tri_dot_trien_khai_QD": "Giá trị đợt triển khai QĐ",
        "ERP_Gia_tri_hop_dong": "Giá trị hợp đồng",
        "ERP_Gia_tri_hop_dong_QD": "Giá trị hợp đồng QĐ",
        "ERP_Gia_tri_lot": "Giá trị LOT",
        "ERP_Gia_tri_lot_QD": "Giá trị LOT QĐ",
        "ERP_Gia_tri_tu": "Giá trị từ",
        "ERP_Gia_tri_tu_khong_de_trong": "Giá trị từ không được để trống",
        "ERP_Giam_dinh": "Giám định",
        "ERP_Giay_phep_lao_dong": "Giấy phép lao động",
        "ERP_Gio_cong": "Giờ công",
        "ERP_Gio_ra": "Giờ ra",
        "ERP_Gio_tu_phai_nho_hon_Gio_den": "Giờ từ phải nhỏ hơn Giờ đến.",
        "ERP_Gio_vao": "Giờ vào",
        "ERP_Gioi_tinh": "Giới tính",
        "ERP_Gop": "Gộp",
        "ERP_Gop_lot": "Gộp lot",
        "ERP_Gui": "Gửi",
        "ERP_Gui_yeu_cau_thanh_cong": "Gửi yêu cầu thành công",
        "ERP_Gui_yeu_cau_thanh_toan": "Gửi yêu cầu thanh toán",
        "ERP_Hai_long_can_cai_tien_them": "Hài lòng cần cải tiến thêm",
        "ERP_Hang_hoa": "Hàng hóa",
        "ERP_Hang_hoa_can_tim": "Hàng hóa cần tìm",
        "ERP_Hang_hoa_da_lap_dot_trien_khai_ban_khong_duoc_phep_xoa": "Hàng hóa đã lập đợt triển khai bạn không được phép xóa",
        "ERP_Hanh_dong": "Hành động",
        "ERP_Hanh_vi_vi_pham_ky_luat": "Hành vi vi phạm kỷ luật",
        "ERP_He_so_quy_cach_dong_goi": "Hệ số quy cách đóng gói ",
        "ERP_He_thong_dang_trong_qua_trinh_nang_cap": "Hệ thống đang trong quá trình nâng cấp.",
        "ERP_Hien_thi_tat_ca": "Hiển thị tất cả",
        "ERP_Hien_thi_vai_tro_khong_con_su_dung": "Hiển thị vai trò không còn sử dụng",
        "ERP_Hinh_thuc": "Hình thức",
        "ERP_Hinh_thuc_huong_luong": "Hình thức hưởng lương",
        "ERP_Hinh_thuc_khen_thuong": "Hình thức khen thưởng",
        "ERP_Hinh_thuc_ky_luat": "Hình thức kỷ luật",
        "ERP_Hinh_thuc_lam_viec": "Hình thức làm việc",
        "ERP_Hinh_thuc_nghi_viec": "Hình thức nghỉ việc",
        "ERP_Ho_chieu": "Hộ chiếu",
        "ERP_Ho_so_nhan_vien": "Hồ sơ nhân viên",
        "ERP_Ho_tro": "Hỗ trợ",
        "ERP_Ho_va_ten": "Họ và tên",
        "ERP_Hoan_tat": "Hoàn tất",
        "ERP_Hoan_thanh": "Hoàn thành",
        "ERP_Hoan_toan_hai_long": "Hoàn toàn hài lòng",
        "ERP_Hoc_van_chuyen_mon": "Học vấn - Chuyên môn",
        "ERP_Hom_nay": "Hôm nay",
        "ERP_Hom_qua": "Hôm qua",
        "ERP_Hop_dong": "Hợp đồng",
        "ERP_Hop_dong_ban": "Hợp đồng bán",
        "ERP_Hop_dong_chua_duyet_ban_khong_the_doi_trang_thai": "Hợp đồng chưa duyệt bạn không thể đổi trạng thái",
        "ERP_Hop_dong_co_lot_da_mapping_ban_khong_the_xoa": "Hợp đồng có Lot đã mapping, bạn không thể xóa.",
        "ERP_Hop_dong_da_co_dot_trien_khai_ban_khong_the_xoa": "Hợp đồng đã có Đợt triển khai, bạn không thể xóa.",
        "ERP_Hop_dong_da_duoc_duyet_ban_khong_the_sua": "Hợp đồng đã được duyệt, bạn không thể sửa!",
        "ERP_Hop_dong_da_duoc_duyet_ban_khong_the_xoa": "Hợp đồng đã được duyệt, bạn không thể xóa!",
        "ERP_Hop_dong_da_mapping_ban_khong_the_xoa": "Hợp đồng đã mapping, bạn không thể xóa",
        "ERP_Hop_dong_mapping": "Hợp đồng mapping",
        "ERP_Hop_dong_mua": "Hợp đồng mua",
        "ERP_Hun_trung": "Hun trùng",
        "ERP_Huong_dan_su_dung": "Hướng dẫn sử dụng",
        "ERP_Huy": "Hủy",
        "ERP_Huy_phep": "Hủy phép",
        "ERP_Huy_phep_thanh_cong": "Hủy phép thành công",
        "ERP_In_bao_cao": "In báo cáo",
        "ERP_Keo_tap_tin_vao_day": "Kéo tập tin vào đây",
        "ERP_Keo_va_tha_tap_tin_dinh_kem_vao_day": "Kéo và thả tập tin đính kèm vào đây!",
        "ERP_Ket_thuc": "Kết thúc",
        "ERP_KeyID_dang_rong_hoac_thieu_khong_the_tai_danh_sach_dinh_kem": "KeyID đang rỗng hoặc thiếu, không tải được danh sách đính kèm",
        "ERP_Khac": "Khác",
        "ERP_Khach_hang": "Khách hàng",
        "ERP_Khao_sat_y_kien_khach_hang": "Khảo sát ý kiến khách hàng",
        "ERP_Khi_xac_nhan_hoan_tat_ban_se_khong_duoc_sua": "Khi xác nhận hoàn tất thông tin chứng từ sẽ không được sửa, bạn có muốn tiếp tục ?",
        "ERP_Kho_dong_hang": "Kho đóng hàng",
        "ERP_Khoa_dong_mapping": "Khóa dòng mapping",
        "ERP_Khoi_la_bat_buoc_nhap": "Khối bắt buộc nhập",
        "ERP_Khong": "Không",
        "ERP_Khong_co_dong_du_lieu_duoc_cap_nhat": "Không có dòng dữ liệu được cập nhật",
        "ERP_Khong_co_ket_noi": "Không có kết nối tới internet",
        "ERP_Khong_co_tap_tin_dinh_kem": "Không có tập tin đính kèm",
        "ERP_Khong_co_thong_bao": "Không có thông báo",
        "ERP_Khong_du_quyen": "Bạn không đủ quyền, Vui lòng đăng nhập lại",
        "ERP_Khong_duoc_nhap_trung_so_lot": "Không được nhập trùng số Lot",
        "ERP_Khong_luu": "Không lưu",
        "ERP_Khong_su_dung": "Không sử dụng",
        "ERP_Khong_ten": "Không tên",
        "ERP_Khong_tim_thay_trang": "KHÔNG TÌM THẤY TRANG",
        "ERP_Khong_trang_thai": "Không trạng thái",
        "ERP_Kich_co_toi_da": "Kích cỡ tối đa",
        "ERP_Kien_thuc": "Kiến thức",
        "ERP_Ky_luong": "Kỳ lương",
        "ERP_Lan": "Lần",
        "ERP_Lap_de_xuat_dieu_chinh_thu_nhap": "Lập đề xuất điều chỉnh thu nhập",
        "ERP_Lap_de_xuat_dieu_chuyen_lao_dong": "Lập đề xuất điều chuyển lao động",
        "ERP_Lap_de_xuat_khen_thuong": "Lập đề xuất khen thưởng",
        "ERP_Lap_de_xuat_ky_luat": "Lập đề xuất kỷ luật",
        "ERP_Lap_nghi_phep": "Lập nghỉ phép",
        "ERP_Lap_phu_luc": "Lập phụ lục",
        "ERP_Len_tau": "Lên tàu",
        "ERP_Lich_su": "Lịch sử",
        "ERP_Lich_su_tac_dong": "Lịch sử tác động",
        "ERP_Lo": "Lô",
        "ERP_Lo1": "Lô",
        "ERP_Loai_HDLD": "Loại HĐLĐ",
        "ERP_Loai_bao_dong_goi": "Loại bao đóng gói",
        "ERP_Loai_chung_tu": "Loại chứng từ",
        "ERP_Loai_dieu_chinh": "Loại điều chỉnh",
        "ERP_Loai_doi_tuong_ap_dung": "Loại đối tượng áp dụng",
        "ERP_Loai_gao_PO": "Loại gạo (Theo hợp đồng nội)",
        "ERP_Loai_gao_SO": "Loại gạo (Theo hợp đồng ngoại)",
        "ERP_Loai_hoa_don": "Loại hóa đơn",
        "ERP_Loai_hop_dong": "Loại hợp đồng",
        "ERP_Loai_nghiep_vu": "Loại nghiệp vụ",
        "ERP_Loai_phep": "Loại phép",
        "ERP_Loai_phu_luc": "Loại phụ lục",
        "ERP_Loai_quy_trinh": "Loại quy trình",
        "ERP_Loai_tap_tin_ho_tro": "Loại tập tin hỗ trợ",
        "ERP_Loai_thu_nhap": "Loại thu nhập",
        "ERP_Loai_tien": "Loại tiền",
        "ERP_Loc_chon_hanh_dong": "Lọc chọn hành động",
        "ERP_Loi_chua_xac_dinh": "Lỗi chưa xác định",
        "ERP_Loi_khong_lay_duoc_token_CDN": "Lỗi không lấy được CDN token",
        "ERP_Lot_da_mapping_khong_the_xoa_ban_co_muon_gui_yeu_cau_xoa_mapping_khong": "Lot đã mapping không thể xóa. Bạn có muốn gửi yêu cầu xóa mapping không ?",
        "ERP_Lot_da_tach": "Lot đã tách",
        "ERP_Lot_yeu_cau_tach": "Lot yêu cầu tách",
        "ERP_Luu": "Lưu",
        "ERP_Luu_dinh_kem_khong_thanh_cong": "Lưu đính kèm không thành công!",
        "ERP_Luu_khong_thanh_cong": "Lưu không thành công!",
        "ERP_Luu_lich_su_khong_thanh_cong": "Lưu lịch sử không thành công",
        "ERP_Luu_thanh_cong": "Lưu thành công",
        "ERP_Luu_thong_tin_chung_tu_khong_thanh_cong": "Lưu thông tin chứng từ không thành công",
        "ERP_Luu_va_nhap_tiep": "Lưu và Nhập tiếp",
        "ERP_Ly_do": "Lý do",
        "ERP_Ly_do_huy": "Lý do huỷ",
        "ERP_Ma": "Mã",
        "ERP_Ma_HDLD": "Mã HĐLĐ",
        "ERP_Ma_LOT": "Mã LOT",
        "ERP_Ma_Lo_da_ton_tai": "Mã Lô đã tồn tại",
        "ERP_Ma_NV": "Mã NV",
        "ERP_Ma_booking": "Mã Booking",
        "ERP_Ma_cham_cong": "Mã chấm công",
        "ERP_Ma_chi_tiet_bao_bi": "Mã chi tiết bao bì",
        "ERP_Ma_chi_tiet_giao_hang": "Mã chi tiết giao hàng",
        "ERP_Ma_co_cau": "Mã cơ cấu",
        "ERP_Ma_cong_ty": "Mã công ty",
        "ERP_Ma_detail_cua_hop_dong_mua": "Mã chi tiết hợp đồng mua",
        "ERP_Ma_doi_tuong": "Mã đối tượng",
        "ERP_Ma_dot_trien_khai": "Mã đợt triển khai",
        "ERP_Ma_hang": "Mã hàng",
        "ERP_Ma_hang_PO": "Mã hàng (Theo hợp đồng nội)",
        "ERP_Ma_hang_SO": "Mã hàng (Theo hợp đồng ngoại)",
        "ERP_Ma_hang_hop_dong_ban": "Mã hàng hợp đồng bán",
        "ERP_Ma_hang_hop_dong_mua": "Mã hàng Hợp đồng mua",
        "ERP_Ma_hop_dong": "Mã hợp đồng",
        "ERP_Ma_khoa_cua_dot_trien_khai": "Mã khóa của đợt triển khai",
        "ERP_Ma_khoa_dong_hop_dong": "Mã khóa hợp dòng hợp đồng",
        "ERP_Ma_khoa_dong_mapping": "Mã khóa dòng mapping",
        "ERP_Ma_khoa_dot_trien_khai": "Mã khóa đợt triển khai",
        "ERP_Ma_khoa_hop_dong": "Mã khóa hợp đồng",
        "ERP_Ma_khoa_hop_dong_ban": "Mã khóa hợp đồng bán",
        "ERP_Ma_khoa_hop_dong_mua": "Mã khóa hợp đồng mua",
        "ERP_Ma_khoa_lot": "Mã khóa lot",
        "ERP_Ma_lo": "Mã lô",
        "ERP_Ma_loai_chung_tu": "Mã loại chứng từ",
        "ERP_Ma_lot_cua_dot_trien_khai": "Mã lot của đợt triển khai",
        "ERP_Ma_mapping": "Mã mapping",
        "ERP_Ma_nguoi_dung": "Mã người dùng",
        "ERP_Ma_nhan_vien": "Mã nhân viên",
        "ERP_Ma_nhan_vien_phu": "Mã nhân viên phụ",
        "ERP_Ma_phuong_thuc_thanh_toan": "Mã phương thức thanh toán",
        "ERP_Ma_so_thue": "Mã số thuế",
        "ERP_Ma_tau": "Mã tàu",
        "ERP_Ma_the_cham_cong": "Mã thẻ chấm công",
        "ERP_Ma_thong_tin_thanh_toan": "Mã thông tin thanh toán",
        "ERP_Ma_trien_khai": "Mã triển khai",
        "ERP_Ma_vai_tro": "Mã vai trò",
        "ERP_Man_hinh": "Màn hình",
        "ERP_Mapping": "Mapping",
        "ERP_Mapping_cu": "Mapping cũ",
        "ERP_Mat_hang": "Mặt hàng",
        "ERP_Mat_hang_khong_duoc_de_trong": "Mặt hàng không được để trống",
        "ERP_Mat_khau": "Mật khẩu",
        "ERP_Mau_bao_cao": "Mẫu báo cáo",
        "ERP_Mau_bao_cao_khong_duoc_tim_thay": "Mẫu báo báo không được tìm thấy",
        "ERP_Mau_giao_dien": "Màu giao diện",
        "ERP_Mo_rong": "Mở rộng",
        "ERP_Mo_ta": "Mô tả",
        "ERP_Muc_do_dong_y": "Mức độ đồng ý",
        "ERP_NVA_nghiep_vu_a": "NVA - Nghiệp vụ A",
        "ERP_Nam_nay": "Năm nay",
        "ERP_Nam_truoc": "Năm trước",
        "ERP_Ngan_hang": "Ngân hàng",
        "ERP_Ngay": "Ngày",
        "ERP_Ngay_bat_dau": "Ngày bắt đầu",
        "ERP_Ngay_cap": "Ngày cấp",
        "ERP_Ngay_cap_CMND": "Ngày cấp CMND",
        "ERP_Ngay_cham_cong": "Ngày chấm công",
        "ERP_Ngay_cong": "Ngày công",
        "ERP_Ngay_dang_ky_tu_khong_duoc_lon_hon_ngay_dang_ky_den": "Ngày đăng ký từ không được lớn hơn ngày đăng ký đến",
        "ERP_Ngay_danh_gia": "Ngày đánh giá",
        "ERP_Ngay_danh_gia_bat_buoc_nhap": "Ngày đánh giá bắt buộc nhập",
        "ERP_Ngay_de_xuat": "Ngày đề xuất",
        "ERP_Ngay_hieu_luc": "Ngày hiệu lực",
        "ERP_Ngay_hieu_luc_den": "Ngày hiệu lực đến",
        "ERP_Ngay_hieu_luc_tu": "Ngày hiệu lực từ",
        "ERP_Ngay_in": "Ngày in",
        "ERP_Ngay_ket_thuc": "Ngày kết thúc",
        "ERP_Ngay_ky": "Ngày ký",
        "ERP_Ngay_lap": "Ngày lập",
        "ERP_Ngay_nghi": "Ngày nghỉ",
        "ERP_Ngay_nghi_viec": "Ngày nghỉ việc",
        "ERP_Ngay_nhan": "Ngày nhận",
        "ERP_Ngay_phep": "Ngày phép",
        "ERP_Ngay_phieu": "Ngày phiếu",
        "ERP_Ngay_phun": "Ngày phun",
        "ERP_Ngay_sinh": "Ngày sinh",
        "ERP_Ngay_tac_dong": "Ngày tác động",
        "ERP_Ngay_tao": "Ngày tạo",
        "ERP_Ngay_tau_di": "Ngày tàu đi",
        "ERP_Ngay_tau_vao": "Ngày tàu vào",
        "ERP_Ngay_tham_gia_bao_hiem": "Ngày tham gia bảo hiểm",
        "ERP_Ngay_thuc_te_booking": "Ngày thực tế Booking",
        "ERP_Ngay_tu_khong_duoc_lon_hon_ngay_den": "Ngày từ không được lớn hơn ngày đến",
        "ERP_Ngay_vao": "Ngày vào",
        "ERP_Ngay_vao_lam": "Ngày vào làm",
        "ERP_Nghi_phep": "Nghỉ phép",
        "ERP_Nghi_viec": "Nghỉ việc",
        "ERP_Nghiep_vu": "Nghiệp vụ",
        "ERP_Nghiep_vu_da_co_du_lieu": "Nghiệp vụ này đã có dữ liệu bạn có muốn thay đổi không ?",
        "ERP_Nguoi_dai_dien": "Người đại diện",
        "ERP_Nguoi_danh_gia": "Người đánh giá",
        "ERP_Nguoi_danh_gia_bat_buoc_nhap": "Người đánh giá bắt buộc nhập",
        "ERP_Nguoi_dung_khong_su_dung": "Người dùng không sử dụng",
        "ERP_Nguoi_duyet": "Người duyệt",
        "ERP_Nguoi_lap": "Người lập",
        "ERP_Nguoi_lapU": "Người lập",
        "ERP_Nguoi_lien_he": "Người liên hệ",
        "ERP_Nguoi_nuoc_ngoai": "Người nước ngoài",
        "ERP_Nguoi_phu_trach": "Người phụ trách",
        "ERP_Nguoi_quan_ly_truc_tiep": "Người QL trực tiếp",
        "ERP_Nguoi_tao": "Người tạo",
        "ERP_Nguoi_uy_quyen": "Người ủy quyền",
        "ERP_Nha_cung_cap": "Nhà cung cấp",
        "ERP_Nhan_mac": "Nhãn mác",
        "ERP_Nhan_vien": "Nhân viên",
        "ERP_Nhap": "Nhập",
        "ERP_Nhap_Ten_Dang_Nhap": "Nhập tên đăng nhập",
        "ERP_Nhap_mat_khau": "Nhập mật khẩu",
        "ERP_Nhap_tiep": "Nhập tiếp",
        "ERP_Nhap_tu_tim_kiem": "Nhập từ tìm kiếm",
        "ERP_Nho_mat_khau": "Nhớ mật khẩu",
        "ERP_Nho_mat_khau1": "Nhớ mật khẩu",
        "ERP_Nhom_hanh_vi_vi_pham_ky_luat": "Nhóm hành vi vi phạm kỷ luật",
        "ERP_Nhom_luong": "Nhóm lương",
        "ERP_Nhom_nhan_vien": "Nhóm nhân viên",
        "ERP_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "Những ý kiến này sẽ được Công ty chúng tôi ghi nhận và làm cơ sở để nâng cao chất lượng dịch vụ nhằm đáp ứng nhu cầu của khách hàng ngày càng tốt hơn",
        "ERP_No": "Nợ",
        "ERP_No_data": "Không có dữ liệu",
        "ERP_No_link": "Không tìm thấy đường dẫn",
        "ERP_Noi_cap": "Nơi cấp",
        "ERP_Noi_cap_CMND": "Nơi cấp CMND",
        "ERP_Noi_dung": "Nội dung",
        "ERP_Noi_dung_can_tim_kiem": "Nội dung cần tìm kiếm",
        "ERP_Noi_sinh": "Nơi sinh",
        "ERP_O_tab": "ở tab",
        "ERP_Phai_thuc_hien_phan_bo_cac_dong_chi_tiet_bao_bi_va_chi_tiet_giao_hang": "Phải thực hiện phân bổ các dòng chi tiết bao bì và chi tiết giao hàng.",
        "ERP_Phan_bo_chi_tiet_giao_hang": "Phân bổ chi tiết giao hàng",
        "ERP_Phan_bo_mapping_tu_yeu_cau_tach_lot": "Phân bổ mapping từ yêu cầu tách lot",
        "ERP_Phan_bo_thong_tin_chi_tiet_giao_hang": "Phân bổ thông tin chi tiết giao hàng",
        "ERP_Phan_quyen": "Phân quyền",
        "ERP_Phan_quyen_chuc_nang": "Phân quyền chức năng",
        "ERP_Phan_quyen_du_lieu": "Phân quyền dữ liệu",
        "ERP_Phan_quyen_theo_vai_tro": "Phân quyền theo vai trò",
        "ERP_Phep": "Phép",
        "ERP_Phien_ban": "Phiên bản",
        "ERP_Phieu_cham_cong": "Phiếu chấm công",
        "ERP_Phieu_dang_ky_da_duoc_duyet_ban_khong_duoc_phep_thay_doi": "Phiếu đăng ký đã được duyệt bạn không được phép thay đổi",
        "ERP_Phong_ban": "Phòng ban",
        "ERP_Phong_ban_moi": "Phòng ban mới",
        "ERP_Phu_luc_da_duoc_duyet": "Phụ lục đã được duyệt",
        "ERP_Phu_luc_da_duoc_duyet_ban_khong_duoc_sua": "Phụ lục đã được duyệt bạn không được sửa",
        "ERP_Phu_luc_da_duoc_duyet_ban_khong_duoc_xoa": "Phụ lục đã được duyệt bạn không được xóa",
        "ERP_Phu_luc_hop_dong": "Phụ lục hợp đồng",
        "ERP_Phu_trach_chung_tu": "Phụ trách chứng từ",
        "ERP_Phun_trung": "Phun trùng",
        "ERP_Phuong_thuc": "Phương thức",
        "ERP_Phuong_thuc_da_ton_tai": "Phương thức đã tồn tại",
        "ERP_Phuong_thuc_khong_duoc_de_trong": "Phương thức không được để trống",
        "ERP_Phuong_thuc_thanh_toan": "Phương thức thanh toán",
        "ERP_Phuong_tien": "Phương tiện",
        "ERP_Phuong_tien_van_chuyen": "Phương tiện vận chuyển",
        "ERP_Phuong_tien_van_chuyen_bat_buoc_nhap": "Phương tiện vận chuyển bắt buộc nhập",
        "ERP_Quan_he": "Quan hệ",
        "ERP_Quan_he_gia_dinh": "Quan hệ gia đình",
        "ERP_Quan_ly": "Quản lý",
        "ERP_Quoc_gia": "Quốc gia",
        "ERP_Quoc_tich": "Quốc tịch",
        "ERP_Quy_cach_dong_goi": "Quy cách đóng gói",
        "ERP_Quy_uoc_nhu_sau": "Quy ước như sau",
        "ERP_Quyen_han": "Quyền hạn",
        "ERP_SDT_ho_tro": "090 840 2668",
        "ERP_STT": "STT",
        "ERP_SXDong_goi": "SX/Đóng gói",
        "ERP_Sao_chep": "Sao chép",
        "ERP_Sinh_nhat": "Sinh nhật",
        "ERP_So_BL": "Số B/L",
        "ERP_So_Fax": "Số Fax",
        "ERP_So_bao_hiem": "Số bảo hiểm",
        "ERP_So_chi_tiet_bao_bi": "Số chi tiết bao bì",
        "ERP_So_chi_tiet_giao_hang": "Số chi tiết giao hàng",
        "ERP_So_dien_thoai": "Số điện thoại",
        "ERP_So_dien_thoai_khong_hop_le": "Số điện thoại không hợp lệ",
        "ERP_So_dong": "Số dòng",
        "ERP_So_hoa_don": "Số hóa đơn",
        "ERP_So_hop_dong": "Số hợp đồng",
        "ERP_So_hop_dong_ban": "Số hợp đồng bán",
        "ERP_So_hop_dong_da_ton_tai": "Số hợp đồng đã tồn tại. Bạn không thể lưu",
        "ERP_So_hop_dong_mua": "Số hợp đồng mua",
        "ERP_So_lan_khu_trung": "Số lần khử trùng",
        "ERP_So_lot": "Số Lot",
        "ERP_So_luong": "Số lượng",
        "ERP_So_luong_QD": "Số lượng QĐ",
        "ERP_So_luong_bao": "Số lượng bao",
        "ERP_So_luong_bao_yeu_cau": "Số lượng bao yêu cầu",
        "ERP_So_luong_bao_yeu_cau_mapping": "Số lượng bao yêu cầu mapping",
        "ERP_So_luong_bao_yeu_cau_vua_nhap_khong_hop_le": "Số lượng bao yêu cầu vừa nhập không hợp lệ",
        "ERP_So_luong_cho_phep": "Số lượng cho phép",
        "ERP_So_luong_chua_trien_khai": "Số lượng chưa triển khai",
        "ERP_So_luong_co_the_mapping": "Số lượng có thể mapping",
        "ERP_So_luong_con_lai": "Số lượng còn lại",
        "ERP_So_luong_con_lai_cua_hop_dong_mua_phai_bang_0": "Số lượng còn lại của hợp đồng mua phải bằng 0.",
        "ERP_So_luong_con_lai_dong_cho_sa_lan_toi_cuoi_ngay_nhap_lieu": "Số lượng còn lại có thể đóng cho sà lan tới cuối ngày nhập liệu",
        "ERP_So_luong_da_giao": "Số lượng đã giao",
        "ERP_So_luong_da_giao_vua_nhap_khong_hop_le": "Số lượng đã giao vừa nhập không hợp lệ",
        "ERP_So_luong_da_mapping": "Số lượng đã mapping",
        "ERP_So_luong_da_nghi_den_ky_hien_tai": "Số lượng đã nghỉ đến kỳ hiện tại",
        "ERP_So_luong_da_thuc_hien": "Số lượng đã thực hiện",
        "ERP_So_luong_da_trien_khai": "Số lượng đã triển khai",
        "ERP_So_luong_dang_trien_khai_la": "Số lượng đang triển khai là",
        "ERP_So_luong_di_cua_booking": "Số lượng đi của Booking",
        "ERP_So_luong_duoc_cap": "Số lượng được cấp",
        "ERP_So_luong_duoc_nhap_nho_hon_tong_so_luong_da_giao": "Số lượng đã giao vừa nhập không hợp lệ",
        "ERP_So_luong_huy1": "Số lượng hủy",
        "ERP_So_luong_khong_duoc_bang_0": "Số lượng không được bằng 0",
        "ERP_So_luong_khong_duoc_de_trong": "Số lượng không được để trống",
        "ERP_So_luong_khong_duoc_vuot_qua": "Số lượng không được vượt quá",
        "ERP_So_luong_khong_hop_le": "Số lượng không hợp lệ.",
        "ERP_So_luong_lot": "Số lượng lot",
        "ERP_So_luong_lot_tach": "Số lượng tách lot",
        "ERP_So_luong_phuong_tien_da_dong": "Số lượng phương tiện đã đóng",
        "ERP_So_luong_phuong_tien_da_dong_goi": "Số lượng phương tiện đã đóng gói",
        "ERP_So_luong_thuc_hien_cua_mapping": "Số lượng thực hiện của mapping",
        "ERP_So_luong_thuc_hien_mapping": "Số lượng thực hiện mapping",
        "ERP_So_luong_ton_den_cuoi_nam": "Số lượng tồn đến cuối năm",
        "ERP_So_luong_ton_den_ky_hien_tai": "Số lượng tồn đến kỳ hiện tại",
        "ERP_So_luong_tong_cua_dot_trien_khai": "Số lượng tổng của đợt triển khai",
        "ERP_So_luong_vua_nhap_nho_hon_so_luong_dang_trien_khai": "Số lượng vừa nhập nhỏ hơn số lượng đang triển khai, bạn có muốn chỉnh sửa số lượng đang triển khai của hàng hóa này ?",
        "ERP_So_luong_vua_nhap_nho_hon_so_luong_dang_trien_khai_ban_co_muon_chinh_sua_so_luong_dang_trien_khai_cua_hang_hoa_nay": "Số lượng vừa nhập nhỏ hơn số lượng đang triển khai, bạn có muốn chỉnh sửa số lượng đang triển khai của hàng hóa này?",
        "ERP_So_luong_yeu_cau": "Số lượng yêu cầu",
        "ERP_So_luong_yeu_cau_cua_mapping": "Số lượng yêu cầu của mapping",
        "ERP_So_luong_yeu_cau_mapping": "Số lượng yêu cầu mapping",
        "ERP_So_mapping": "Số mapping",
        "ERP_So_phieu": "Số phiếu",
        "ERP_So_phu_luc": "Số phụ lục",
        "ERP_So_tai_khoan": "Số tài khoản",
        "ERP_So_thang": "Số tháng",
        "ERP_So_thu_tu": "Số thứ tự",
        "ERP_So_tien_thanh_toan": "Số tiền thanh toán",
        "ERP_So_tien_thuc_te": "Số tiền thực tế",
        "ERP_So_tien_yeu_cau": "Số tiền yêu cầu",
        "ERP_Su_dung": "Sử dụng",
        "ERP_Sua": "Sửa",
        "ERP_Sua_khac": "Sửa khác",
        "ERP_Tach": "Tách",
        "ERP_Tach_lot": "Tách LOT",
        "ERP_Tai_khoan": "Tài khoản",
        "ERP_Tai_khoan_bi_khoa_10_phut": "Tài khoản bị khoá, hãy thử lại sau 10 phút",
        "ERP_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "Tài khoản đăng nhập hoặc mật khẩu không đúng.",
        "ERP_Tai_khoan_khong_co_quyen": "Bạn không có quyền truy cập vào hệ thống.",
        "ERP_Tai_lieu": "Tài liệu",
        "ERP_Tai_trong": "Tải trọng ",
        "ERP_Tai_trong_phuong_tien": "Tải trọng phương tiện",
        "ERP_Tam_dap_ung_Can_cai_tien_them": "Tạm đáp ứng, cần phải cải tiến thêm",
        "ERP_Tap_tin": "Tập tin",
        "ERP_Tap_tin_dinh_kem_dang_duoc_xu_ly": "Tập tin đính kèm đang được xử lý",
        "ERP_Tap_tin_tai_len": "Tập tin tải lên",
        "ERP_Tat_ca": "Tất cả",
        "ERP_Tat_ca_cac_lot_phai_ket_thuc_khi_ket_thuc_dot_trien_khai": "Tất cả các Lot phải kết thúc khi kết thúc đợt triển khai.",
        "ERP_Ten": "Tên",
        "ERP_Ten_Dang_Nhap": "Tên đăng nhập",
        "ERP_Ten_cong_ty": "Tên công ty",
        "ERP_Ten_dieu_chinh_chung_tu_cua_lot": "Tên điều chỉnh chứng từ của Lot",
        "ERP_Ten_doi_tuong": "Tên đối tượng",
        "ERP_Ten_hang": "Tên hàng",
        "ERP_Ten_hang_hop_dong_ban": "Tên hàng hợp đồng bán",
        "ERP_Ten_hang_hop_dong_mua": "Tên hàng Hợp đồng mua",
        "ERP_Ten_hang_khong_duoc_de_trong": "Tên hàng không được để trống",
        "ERP_Ten_hang_thuc_te": "Tên hàng thực tế",
        "ERP_Ten_lo": "Tên lô",
        "ERP_Ten_man_hinh": "Tên màn hình",
        "ERP_Ten_nguoi_dung": "Tên người dùng",
        "ERP_Ten_nguoi_quan_he": "Tên người quan hệ",
        "ERP_Ten_nhan_vien": "Tên nhân viên",
        "ERP_Ten_phong_ban": "Tên phòng ban",
        "ERP_Ten_tau": "Tên tàu",
        "ERP_Ten_thong_tin_thanh_toan": "Tên thông tin thanh toán",
        "ERP_Ten_vai_tro": "Tên vai trò",
        "ERP_Tham_nien": "Thâm niên",
        "ERP_Tham_so_truyen_vao_khong_hop_le": "Tham số truyền vào không hợp lệ",
        "ERP_Thang_nay": "Tháng này",
        "ERP_Thang_truoc": "Tháng trước",
        "ERP_Thanh_tien": "Thành tiền",
        "ERP_Thanh_tien_QD": "Thành tiền QĐ",
        "ERP_Thanh_tien_deposit": "Thành tiền deposit",
        "ERP_Thanh_tien_deposit_QD": "Thành tiền deposit QĐ",
        "ERP_Thanh_toan": "Thanh toán",
        "ERP_Thanh_vien_cua_Nihon_Grouping_va_Pan": "Thành viên của Nihon Grouping và Pan Group",
        "ERP_Thay_doi_hop_dong_se_lam_thay_doi_du_lieu": "Thay đổi hợp đồng sẽ làm thay đổi dữ liệu, bạn có muốn thay đổi không ?",
        "ERP_The_can_cuoc": "Thẻ căn cước",
        "ERP_The_luu_tru": "Thẻ lưu trú",
        "ERP_Them": "Thêm",
        "ERP_Them_chi_tiet_giao_hang_vuot_qua_so_luong_mapping": " Thêm chi tiết giao hàng vượt quá số lượng mapping.",
        "ERP_Them_dieu_kien": "Thêm điều kiện",
        "ERP_Them_dot_trien_khai": "Thêm đợt triển khai",
        "ERP_Them_hang_hoa": "Thêm hàng hóa",
        "ERP_Them_lo": "Thêm Lô",
        "ERP_Them_mapping": "Thêm mapping",
        "ERP_Them_moi": "<Thêm mới>",
        "ERP_Them_moi1": "Thêm mới",
        "ERP_Them_moi_lo": "Thêm mới lô",
        "ERP_Thoi_gian": "Thời gian",
        "ERP_Thoi_gian_bat_dau_dong_hang": "Thời gian bắt đầu đóng hàng",
        "ERP_Thoi_gian_den_cang": "Thời gian đến cảng",
        "ERP_Thoi_gian_dong_hang": "Thời gian đóng hàng",
        "ERP_Thoi_gian_giao_bao": "Thời gian giao bao",
        "ERP_Thoi_gian_giao_hang": "Thời gian giao hàng",
        "ERP_Thoi_gian_giao_hang_den_ngay": "Thời gian giao hàng đến ngày",
        "ERP_Thoi_gian_giao_hang_du_kien": "Thời gian giao hàng dự kiến",
        "ERP_Thoi_gian_giao_hang_du_kien_cua_dot_trien_khai": "Thời gian giao hàng dự kiến của đợt triển khai",
        "ERP_Thoi_gian_giao_hang_du_kien_den": "Thời gian giao hàng dự kiến đến",
        "ERP_Thoi_gian_giao_hang_du_kien_tu": "Thời gian giao hàng dự kiến từ",
        "ERP_Thoi_gian_giao_hang_theo_lot": "Thời gian giao hàng theo lot",
        "ERP_Thoi_gian_giao_hang_tu_ngay": "Thời gian giao hàng từ ngày",
        "ERP_Thoi_gian_hieu_luc": "Thời gian hiệu lực",
        "ERP_Thoi_gian_ket_thuc_dong_hang": "Thời gian kết thúc đóng hàng",
        "ERP_Thoi_gian_ra_vao_VN": "Thời gian ra vào VN",
        "ERP_Thong_bao": "Thông báo",
        "ERP_Thong_bao1": "Thông Báo",
        "ERP_Thong_baoU": "Thông báo",
        "ERP_Thong_bao_giao_hang": "Thông báo giao hàng",
        "ERP_Thong_bao_lot_da_duoc_mapping": "Lot đã được mapping. Số lượng Lot phải lớn hơn hoặc bằng số lượng đã mapping là",
        "ERP_Thong_bao_lot_da_mapping": "Lot đã mapping, bạn cần xóa Lot ở mapping.",
        "ERP_Thong_bao_xac_nhan_chung_tu": "Bạn có chắc chắn muốn chuyển trạng thái chứng từ sang chưa hoàn tất không ?",
        "ERP_Thong_tin_bao_bi": "Thông tin bao bì",
        "ERP_Thong_tin_ca_nhan": "Thông tin cá nhân",
        "ERP_Thong_tin_chinh": "Thông tin chính",
        "ERP_Thong_tin_chung": "Thông tin chung",
        "ERP_Thong_tin_cu": "Thông tin cũ",
        "ERP_Thong_tin_cung_cap_khong_hop_le": "Thông tin cung cấp không hợp lệ!",
        "ERP_Thong_tin_dong_goi": "Thông tin đóng gói",
        "ERP_Thong_tin_giao_hang": "Thông tin giao hàng",
        "ERP_Thong_tin_hoa_don": "Thông tin hóa đơn",
        "ERP_Thong_tin_huy": "Thông tin hủy",
        "ERP_Thong_tin_khac": "Thông tin khác",
        "ERP_Thong_tin_lien_lac": "Thông tin liên lạc",
        "ERP_Thong_tin_mapping": "Thông tin mapping",
        "ERP_Thong_tin_nguoi_dung": "Thông tin người dùng",
        "ERP_Thong_tin_phan_quyen": "Thông tin phân quyền",
        "ERP_Thong_tin_phu": "Thông tin phụ",
        "ERP_Thong_tin_phu_luc": "Thông tin phụ lục",
        "ERP_Thong_tin_tang_nhan_vien": "Thông tin tăng nhân viên",
        "ERP_Thong_tin_thanh_toan": "Thông tin thanh toán",
        "ERP_Thong_tin_thanh_toan_cua_lot": "Thông tin thanh toán của Lot",
        "ERP_Thong_tin_thanh_toan_lot": "Thông tin thanh toán Lot",
        "ERP_Thong_tin_vai_tro": "Thông tin vai trò",
        "ERP_Thuc_hien": "Thực hiện",
        "ERP_Thuc_hien_mapping": "Thực hiện mapping",
        "ERP_Thuc_hien_thanh_cong": "Thực hiện thành công",
        "ERP_Tieu_chuan_chat_luong": "Tiêu chuẩn - chất lượng",
        "ERP_Tim_doi_tuong": "Tìm đối tượng",
        "ERP_Tim_kiem": "Tìm kiếm",
        "ERP_Tim_kiem_cham_cong": "Tìm kiếm chấm công",
        "ERP_Tim_kiem_cong_ty": "Tìm kiếm công ty ",
        "ERP_Tim_kiem_doi_tuong": "Tìm kiếm đối tượng",
        "ERP_Tim_kiem_hang_hoa": "Tìm kiếm hàng hóa",
        "ERP_Tim_kiem_ho_so_nhan_su": "Tìm kiếm hồ sơ nhân sự",
        "ERP_Tim_kiem_hop_dong": "Tìm kiếm hợp đồng",
        "ERP_Tim_kiem_ten_nhan_vien_va_ma_nhan_vien": "Tìm kiếm tên nhân viên và mã nhân viên..",
        "ERP_Tinh_trang_hien_tai": "Tình trạng hiện tại",
        "ERP_Tinh_trang_hoan_thanh": "Tình trạng hoàn thành",
        "ERP_Tinh_trang_hon_nhan": "Tình trạng hôn nhân",
        "ERP_To_chuc": "Tổ chức",
        "ERP_To_nhom": "Tổ nhóm",
        "ERP_To_nhom_moi": "Tổ nhóm mới",
        "ERP_Toan_tu": "Toán tử",
        "ERP_Toan_tu_khong_duoc_de_trong": "Toán tử không được để trống",
        "ERP_Ton_giao": "Tôn giáo",
        "ERP_Tong": "Tổng",
        "ERP_Tong_diem": "Tổng điểm",
        "ERP_Tong_gia_tri_hoa_don": "Tổng giá trị hóa đơn ",
        "ERP_Tong_gia_tri_hoa_don_QD": "Tổng giá trị hóa đơn QĐ",
        "ERP_Tong_gia_tri_hop_dong": "Tổng giá trị hợp đồng",
        "ERP_Tong_gia_tri_hop_dong_QD": "Tổng giá trị hợp đồng QĐ",
        "ERP_Tong_so_luong": "Tổng số lượng",
        "ERP_Tong_so_luong_cac_lot_phai_bang_so_luong_cua_lot_tach": "Tổng số lượng các LOT phải bằng Số lượng của LOT tách",
        "ERP_Tong_so_luong_chi_tiet_giao_hang_duoc_phan_bo_cua_mapping": "Tổng số lượng chi tiết giao hàng được phân bổ của Mapping",
        "ERP_Tong_so_luong_dong_goi_cua_cac_mapping_phai_bang_so_luong_dong_goi_cua_chi_tiet_giao_hang": "Tổng số lượng đóng gói của các Mapping phải bằng số lượng đóng gói của Chi tiết giao hàng",
        "ERP_Tong_so_luong_dong_goi_cua_cac_mapping_vuot_qua_so_luong_dong_goi_cua_chi_tiet_giao_hang": "Tổng số lượng đóng gói của các Mapping vượt quá số lượng đóng gói của chi tiết giao hàng",
        "ERP_Tong_so_ngay": "Tổng số ngày",
        "ERP_Trang": "Trang",
        "ERP_Trang_Chu": "Trang chủ",
        "ERP_Trang_thai": "Trạng thái",
        "ERP_Trang_thai_Phu_luc_hop_dong": "Trạng thái Phụ lục hợp đồng",
        "ERP_Trang_thai_chi_tiet_giao_hang": "Trạng thái chi tiết giao hàng",
        "ERP_Trang_thai_cu": "Trạng thái cũ",
        "ERP_Trang_thai_deposit": "Trạng thái Deposit",
        "ERP_Trang_thai_duyet": "Trạng thái duyệt",
        "ERP_Trang_thai_duyet_Hop_dong": "Trạng thái duyệt Hợp đồng",
        "ERP_Trang_thai_hoa_don_": "Trạng thái hóa đơn",
        "ERP_Trang_thai_hoan_tat_ban_khong_duoc_phep_xoa": "Trạng thái đang là Hoàn tất đợt triển khai. Bạn không được phép xóa.",
        "ERP_Trang_thai_hop_dong": "Trạng thái hợp đồng",
        "ERP_Trang_thai_lam_viec": "Trạng thái làm việc",
        "ERP_Trang_thai_moi": "Trạng thái mới",
        "ERP_Trang_thai_phuong_tien": "Trại thái phương tiện",
        "ERP_Trinh_do_hoc_van": "Trình độ học vấn",
        "ERP_Trinh_do_van_hoaU": "Trình độ văn hóa",
        "ERP_Tro_giup": "Trợ giúp",
        "ERP_Trong_luong_Gross": "Trọng lượng Gross",
        "ERP_Trong_luong_bao_bi": "Trọng lượng bao bì",
        "ERP_Trong_luong_cont": "Trọng lượng Cont",
        "ERP_Truong_nay_bat_buoc_nhap": "Trường này bắt buộc nhập",
        "ERP_Truong_nay_khong_hop_le": "Trường này không hợp lệ",
        "ERP_Truong_ngay_la_bat_buoc": "Trường ngày là bắt buộc",
        "ERP_Truy_cap_khong_hop_le": "Truy cập không hợp lệ",
        "ERP_Truy_van_tang_nhan_vien": "Truy vấn tăng nhân viên",
        "ERP_Truy_van_thong_tin_cong_phep": "Truy vấn thông tin công phép",
        "ERP_Tu": "Từ",
        "ERP_Tu_choi": "Từ chối",
        "ERP_Tu_choi_W75": "Từ chối",
        "ERP_Tu_choi_khong_thanh_cong": "Từ chối không thành công!",
        "ERP_Tu_choi_thanh_cong": "Từ chối thành công",
        "ERP_Tu_ngay": "Từ ngày",
        "ERP_Tuan_nay": "Tuần này",
        "ERP_Tuan_truoc": "Tuần trước",
        "ERP_Tuoi": "Tuổi",
        "ERP_Tuyen_dung": "Tuyển dụng",
        "ERP_Ty_gia": "Tỷ giá",
        "ERP_Ty_le_deposit": "Tỷ lệ deposit",
        "ERP_URL": "URL",
        "ERP_URL_khong_hop_le": "URL không hợp lệ",
        "ERP_Username_va_Password_khong_hop_le!": "Username hoặc Password không đúng!",
        "ERP_Vai_tro": "Vai trò",
        "ERP_Van_chuyen_thanh_toan": "Vận chuyển - thanh toán",
        "ERP_VoucherID_khong_duoc_de_trong": "VoucherID không được để trống",
        "ERP_Vui_long_chon_hang_hoa": "Vui lòng chọn hàng hóa",
        "ERP_Vui_long_nhap_so_luong_va_gia_hang_hoa": "Vui lòng nhập đầy đủ số lượng và giá hàng hóa",
        "ERP_Vui_long_truy_cap_sau": "Vui lòng truy cập sau!",
        "ERP_Vuot_qua_so_luong_tap_tin_cho_phep": "Vượt quá số lượng tập tin cho phép!",
        "ERP_Xac_nhan": "Xác nhận",
        "ERP_Xac_nhan_giao_hang": "Xác nhận giao hàng",
        "ERP_Xac_nhan_nhan_vien_nhan_viec_tai_du_an": "Xác nhận nhân viên nhận việc tại dự án",
        "ERP_Xac_nhan_so_luong_da_giao": "Xác nhận số lượng đã giao",
        "ERP_Xac_nhan_tat_ca": "Xác nhận tất cả",
        "ERP_Xac_nhan_thanh_cong": "Xác nhận thành công",
        "ERP_Xem": "Xem",
        "ERP_Xem_chi_tiet": "Xem chi tiết",
        "ERP_Xem_hop_dong_goc": "Xem hợp đồng gốc",
        "ERP_Xem_tap_tin": "Xem tập tin",
        "ERP_Xem_tat_ca": "Xem tất cả",
        "ERP_Xem_them": "Xem thêm",
        "ERP_Xem_truoc": "Xem trước",
        "ERP_Xin_cam_on": "Xin cảm ơn",
        "ERP_Xoa": "Xóa",
        "ERP_Xoa_dong": "Xóa dòng",
        "ERP_Xoa_du_lieu": "Xóa dữ liệu",
        "ERP_Xoa_tam": "Xóa tạm",
        "ERP_Xoa_thanh_cong": "Xóa thành công",
        "ERP_Xuat": "Xuất",
        "ERP_Xuat_Excel": "Xuất Excel",
        "ERP_Xuat_PDF": "Xuất PDF",
        "ERP_Xuat_bao_cao": "Xuất báo cáo",
        "ERP_Xuat_du_lieu": "Xuất dữ liệu",
        "ERP_Xuat_du_lieu_thanh_cong": "Xuất dữ liệu thành công.",
        "ERP_Xuat_mau_in_xac_nhan_giao_hang": "Xuất mẫu in xác nhận giao hàng",
        "ERP_Xuat_thong_bao_giao_hang": "Xuất thông báo giao hàng",
        "ERP_Xuat_word": "Xuất Word",
        "ERP_Y_kien_dong_gop": "Ý kiến đóng góp",
        "ERP_Yes": "Có",
        "ERP_Yeu_cau_tach_lot": "Yêu cầu tách LOT",
        "ERP_Yeu_cau_thanh_toan": "Yêu cầu thanh toán",
        "ERP_dang_nhap_lai": "đăng nhập lại",
        "ERP_dong": "dòng",
        "ERP_khong_co_du_lieu": "Không có dữ liệu",
        "ERP_trong": "trống"
    },
    "en": {
        "ERP_403_loi": "You do not have permission to view this page",
        "ERP_404_loi": "The page you are looking for might have been removed had its name changed or is temporarily unavailable.",
        "ERP_AC_vui_long_chon_y_kien_cua_minh": "Please let us know your evaluation on service level",
        "ERP_Ban_can_nhap_hang_hoa_cho_hoa_don": "Ban_can_nhap_hang_hoa_cho_hoa_don",
        "ERP_Ban_chac_chan_muon_sao_chep_cot_gia_tri_nay?": "Are you sure to copy this value?",
        "ERP_Ban_chi_duoc_dieu_chinh_giam_0_5_phep": "You should adjust down only 0.5 leave days.",
        "ERP_Ban_chi_duoc_phep_sua_so_luong_lon_hon_hoac_bang_so_luong_da_trien_khai_la": "Ban_chi_duoc_phep_sua_so_luong_lon_hon_hoac_bang_so_luong_da_trien_khai_la",
        "ERP_Ban_chua_trien_khai_hop_dong_ban_khong_duoc_ket_thuc_hop_dong": "You have not deployed the contract. You can’t finish contract.",
        "ERP_Ban_co_chac_chan_muon_xac_nhan_chi_tiet_giao_hang_nay_khong": "Are you sure you want to confirm this delivery details ?",
        "ERP_Ban_co_chac_chan_muon_xac_nhan_tat_ca_cac_chi_tiet_giao_hang_khong": "Do you want to confirm all delivery details ?”",
        "ERP_Ban_co_chac_chan_muon_xoa_du_lieu_nay": "Are you sure to delete this data?",
        "ERP_Ban_co_chac_chan_muon_xoa_thong_tin_thanh_toan_nay_khong": "Are you sure you want to delete this payment information ?",
        "ERP_Ban_co_chac_muon_bo_trang_thai_nay?": "Are you sure to remove this status?",
        "ERP_Ban_co_chac_muon_duyet": "Do you want to acccpet this voucher?",
        "ERP_Ban_co_chac_muon_huy_phep?": "Are you sure to cancel the leave?",
        "ERP_Ban_co_chac_muon_luu_khong?": "Do you want to save this data?",
        "ERP_Ban_co_chac_muon_xoa?": "Are you sure to delete?",
        "ERP_Ban_co_muon_chuyen_trang_thai_chung_tu": "Do you want to change the receipt status into incomplete",
        "ERP_Ban_co_muon_tiep_tuc_sua": "You have input data Detail Fumigation. Do you want to continue editing?",
        "ERP_Ban_co_muon_xoa_du_lieu": "Do you want to delete data",
        "ERP_Ban_da_check_hoan_tat_chung_tu_nay": "You have completed check this voucher",
        "ERP_Ban_da_check_hoan_thanh_chung_tu_nay": "You have checked complete this voucher",
        "ERP_Ban_da_chon_phuong_thuc_nay": "You have selected this method",
        "ERP_Ban_giao": "Handover",
        "ERP_Ban_khong_co_quyen_duyet": "Ban_khong_co_quyen_duyet",
        "ERP_Ban_khong_duoc_sua_so_luong_hang_hoa_nho_hon_so_luong_dang_trien_khai_la": "You can’t modify quantity less than quantity of the deployment is",
        "ERP_Ban_khong_duoc_xoa_hang_hoa_da_trien_khai": "Ban_khong_duoc_xoa_hang_hoa_da_trien_khai",
        "ERP_Ban_khong_the_luu": "You cannot save.",
        "ERP_Ban_phai_check_su_dung_truoc": "You must check use first",
        "ERP_Ban_phai_chon_doanh_nghiep": "Ban_phai_chon_doanh_nghiep",
        "ERP_Bao_cao": "Report",
        "ERP_Bao_cao_cong": "Attendance report",
        "ERP_Bao_yeu_cau": "Package required",
        "ERP_Bat_buoc": "Compulsory",
        "ERP_Bat_buoc_nhap_du_lieu": "Input data required",
        "ERP_Bat_dau": "Start",
        "ERP_Bo_duyet": "Cancel Approval",
        "ERP_Bo_xac_nhan": "Cancel confirmation",
        "ERP_Boi": "By",
        "ERP_CDN_chua_san_sang": "CDN is not ready",
        "ERP_CMND": "ID Card",
        "ERP_Ca": "Shift",
        "ERP_Ca_nhan": "Personal",
        "ERP_Cac_dot_trien_khai_chua_ket_thuc_ban_khong_the_ket_thuc_hop_dong": "Deployed have not finished. You can’t finish contract.",
        "ERP_Cac_gop_y_khac": "Other feedback",
        "ERP_Cac_tham_so_la_bat_buoc": "The parameters are compulsory",
        "ERP_Cac_tieu_chi_la_bat_buoc_nhap": "Compulsory criteria",
        "ERP_Cac_truong_khong_bang_nhau": "The fields are not equal",
        "ERP_Cai_dat": "Settings",
        "ERP_Cang_den": "Transport to",
        "ERP_Cang_di": "Departure from",
        "ERP_Cap": "Level",
        "ERP_Cap_co_cau": "Cap_co_cau",
        "ERP_Cap_khen_thuong": "Awards level",
        "ERP_Cap_ky_luat": "Discipline Level",
        "ERP_Cap_nhat_Lo": "Update batch",
        "ERP_Cap_nhat_chung_tu": "Update receipt",
        "ERP_Cap_nhat_cuoc_phi": "Cap_nhat_cuoc_phi",
        "ERP_Cap_nhat_thong_tin_huy": "Update information cancel",
        "ERP_Cap_nhat_trang_thai": "Change Status",
        "ERP_Cap_nhat_trang_thai_duyet": "Update browsing status",
        "ERP_Cap_nhat_trang_thai_duyet_phu_luc": "Cap_nhat_trang_thai_duyet_phu_luc",
        "ERP_Cap_nhat_trang_thai_hoa_don": "Cap_nhat_trang_thai_hoa_don",
        "ERP_Cap_nhat_trang_thai_hop_dong_lao_dong": "Update labor contract status",
        "ERP_Cham_cong_chi_tiet": "Attendance details",
        "ERP_Cham_cong_theo_du_an": "Attendance by projects",
        "ERP_Chao_buoi_sang": "Good morning!",
        "ERP_Chao_mung_login": "Welcome to DIGINET ecosystem",
        "ERP_Chi_phi_huy": "Cancellation fee",
        "ERP_Chi_tiet": "Detail",
        "ERP_Chi_tiet_bao_bi": "Pack detail",
        "ERP_Chi_tiet_can_phan_bo": "Details need to be allocated",
        "ERP_Chi_tiet_dieu_kien": "Chi_tiet_dieu_kien",
        "ERP_Chi_tiet_dot_trien_khai": "Deployment details",
        "ERP_Chi_tiet_giao_hang": "Delivery detail",
        "ERP_Chi_tiet_hoa_don": "Detailed Invoice",
        "ERP_Chi_tiet_hop_dong": "Contract Detail",
        "ERP_Chi_tiet_hop_dong_ban": "Sell contract details",
        "ERP_Chi_tiet_hop_dong_mua": "Buy contract details",
        "ERP_Chi_tiet_hop_dong_trong_mapping": "Contract details in mapping",
        "ERP_Chi_tiet_mapping": "mapping detail",
        "ERP_Chi_tiet_nhan_vien": "Employee Detail",
        "ERP_Chi_tiet_phep": "Leave Detail",
        "ERP_Chi_tiet_phu_luc_hop_dong_ban": "Chi_tiet_phu_luc_hop_dong_ban",
        "ERP_Chi_tiet_phu_luc_hop_dong_mua": "Chi_tiet_phu_luc_hop_dong_mua",
        "ERP_Chinh_sua": "Edit",
        "ERP_Chinh_tri": "Political Level",
        "ERP_Cho_duyet": "Pending Approval",
        "ERP_Chon": "Select",
        "ERP_Chon_ban_giao": "Select handover form",
        "ERP_Chon_co_cau_to_chuc": "Select organization unit",
        "ERP_Chon_du_an": "Select project",
        "ERP_Chon_hop_dong": "Choose Contract",
        "ERP_Chon_hop_dong_lap_phu_luc": "Chon_hop_dong_lap_phu_luc",
        "ERP_Chon_ky": "Selct period",
        "ERP_Chon_ky_luong": "Select payroll period",
        "ERP_Chon_mapping": "Chon_mapping",
        "ERP_Chon_mau_bao_cao": "Select report format",
        "ERP_Chon_ngay": "Select date",
        "ERP_Chon_nhan_vien": "Select Employees",
        "ERP_Chon_nhom_luong": "Select salary group",
        "ERP_Chon_thoi_gian": "Time",
        "ERP_Chon_trang_thai": "Choose status",
        "ERP_Chon_trang_thai_duyet": "Chon_trang_thai_duyet_",
        "ERP_Chu_de": "Themes",
        "ERP_Chua_chon_hang_hoa": "Inventory is required",
        "ERP_Chua_co_lien_ket_nao": "There is no link",
        "ERP_Chua_dap_ung": "Not qualified",
        "ERP_Chua_duyet": "Not approve",
        "ERP_Chua_hoan_thanh": "Not completed",
        "ERP_Chua_xac_nhan": "Unconfimred",
        "ERP_Chuc_danh": "Title",
        "ERP_Chuc_danh_cong_viec": "Position",
        "ERP_Chuc_vu": "Position",
        "ERP_Chuc_vu_bat_buoc_nhap": "Position is required",
        "ERP_Chung_minh_nhan_dan": "ID card",
        "ERP_Chung_tu": "Voucher",
        "ERP_Chung_tu_da_hoan_thanh_khong_the_sua": "Chung_tu_da_hoan_thanh_khong_the_sua",
        "ERP_Chung_tu_da_hoan_thanh_khong_the_xoa": "Chung_tu_da_hoan_thanh_khong_the_xoa",
        "ERP_Chuyen_doi_trang_thai_LOT": "Lot status change",
        "ERP_Chuyen_mapping": "Chuyen_mapping",
        "ERP_Chuyen_mon": "Qualifications",
        "ERP_Chuyen_nganh": "Major",
        "ERP_Chuyen_trang_thai_Lot": "LOT status changes",
        "ERP_Co1": "Yes",
        "ERP_Co_cau_cha": "Co_cau_cha",
        "ERP_Co_cau_to_chuc": "Organization Structure",
        "ERP_Co_hop_dong_ban_chua_thuc_hien_mapping": "Co_hop_dong_ban_chua_thuc_hien_mapping",
        "ERP_Co_loi_khi_luu": "Error saving",
        "ERP_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "An error occurred during processing.",
        "ERP_Co_so_luong_ban_khong_duoc_phep_xoa": "Deployed have quantity. You not allowed delete.",
        "ERP_Con_dong_chua_duoc_nhap_lieu/Ban_co_muon_luu_khong?": "There is empty row. Do you want to save?",
        "ERP_Con_dong_chua_duoc_nhap_lieu_Ban_co_muon_luu_khong": "There is empty row. Do you want to save?",
        "ERP_Cong_trinh/Cong_ty": "Project/Company",
        "ERP_Cong_ty": "Company",
        "ERP_Cong_ty_bao_hiem": "Cong_ty_bao_hiem",
        "ERP_Cong_ty_phun": "Fumigation Company Name",
        "ERP_Cong_viec": "Job",
        "ERP_Cong_viec_moi": "New Job",
        "ERP_Cua_lot": "Of Lot",
        "ERP_DHR_Ban_chac_chan_muon_sao_chep_cot_gia_tri_nay?": "Bạn chắc chắn muốn sao chép giá trị này?",
        "ERP_DHR_Gia_tri": "Giá trị",
        "ERP_DHR_Xoa": "Xóa",
        "ERP_Da_duyet": "Approved",
        "ERP_Da_hoan_tat": "Đã hoàn tất",
        "ERP_Da_ket_noi": "Connected to internet",
        "ERP_Da_mapping": "Mapped",
        "ERP_Da_nghi_viec": "Terminated",
        "ERP_Da_sao_chep": "Copied",
        "ERP_Da_trien_khai": "Deployed",
        "ERP_Da_vuot_qua_so_luong_cho_phep": "Is greater the quantity quota",
        "ERP_Da_xac_nhan": "Confirmed",
        "ERP_Dai_ly_tau": "Shipping agent",
        "ERP_Dan_toc": "Ethnic",
        "ERP_Dang_Lam_Viec": "In working",
        "ERP_Dang_ky_nghi": "Leave registeration",
        "ERP_Dang_ky_nghi_viec": "Resignation registeration",
        "ERP_Dang_nhap": "Log in",
        "ERP_Dang_nhap_lai2": "You are already logged in on a different device. Please login again.",
        "ERP_Dang_xuat": "Export Type",
        "ERP_Dang_xuat1": "Logout",
        "ERP_Danh_sach_bao_bi": "Packaging list",
        "ERP_Danh_sach_bao_cao": "List reports",
        "ERP_Danh_sach_cong_ty": "List of Companies",
        "ERP_Danh_sach_cuoc_phi": "List of charges",
        "ERP_Danh_sach_doi_tuong": "Object list",
        "ERP_Danh_sach_dot_trien_khai": "List of deployments",
        "ERP_Danh_sach_giao_hang": "Delivery list",
        "ERP_Danh_sach_hang_hoa": "Inventory list",
        "ERP_Danh_sach_hang_hoa_chua_trien_khai": "Inventory list not deploy",
        "ERP_Danh_sach_hoa_donU": "Invoice list",
        "ERP_Danh_sach_hop_dong": "List of Contracts",
        "ERP_Danh_sach_hop_dong_ban": "List of sale contracts",
        "ERP_Danh_sach_hop_dong_mua": "List of purchase contracts",
        "ERP_Danh_sach_lo": "Danh_sach_lo",
        "ERP_Danh_sach_lot": "Danh_sach_lot",
        "ERP_Danh_sach_mapping": "Mapping list",
        "ERP_Danh_sach_mapping_cu": "Danh_sach_mapping_cu",
        "ERP_Danh_sach_nhan_vien": "List of Employees",
        "ERP_Danh_sach_phu_luc_hop_dong_ban": "Danh_sach_phu_luc_hop_dong_ban",
        "ERP_Danh_sach_phu_luc_hop_dong_mua": "Danh_sach_phu_luc_hop_dong_mua",
        "ERP_Danh_sach_vai_tro": "Role list",
        "ERP_Dap_ung": "Qualified",
        "ERP_De_xuat_dieu_chinh_lao_dong": "Transfer Request",
        "ERP_De_xuat_khen_thuong": "Reward Proposal",
        "ERP_De_xuat_ky_luat": "Discipline Proposal",
        "ERP_Den": "To",
        "ERP_Den_ngay": "Date to",
        "ERP_Di_cont": "Cont",
        "ERP_Di_dong": "Mobile",
        "ERP_Di_tau": "Vessel",
        "ERP_Dia_chi": "Address",
        "ERP_Dia_chi_lien_lac": "Contact Address",
        "ERP_Dia_chi_tam_tru": "Temporary Address",
        "ERP_Dia_chi_thuong_tru": "Permanent Address",
        "ERP_Dien_giai": "Description",
        "ERP_Dien_thoai": "Telephone",
        "ERP_Dieu_khoan_thuong_mai": "Payment Term",
        "ERP_Dieu_kien": "Conditions",
        "ERP_Dieu_kien_dieu_chinh": "Dieu_kien_dieu_chinh",
        "ERP_Dieu_kien_dieu_chinh_dot_trien_khai": "Dieu_kien_dieu_chinh_dot_trien_khai",
        "ERP_Dieu_kien_khong_duoc_de_trong": "Dieu_kien_khong_duoc_de_trong",
        "ERP_Dinh_dang_file_khong_hop_le": "Invalid file format",
        "ERP_Dinh_dang_ngay_khong_dung": "Invalid date format!",
        "ERP_Dinh_kem": "Attachments",
        "ERP_Dinh_vi_khoa": "GPS is locked",
        "ERP_Doanh_nghiep": "Company",
        "ERP_Doi_tuong_ap_dung": "Apply for",
        "ERP_Doi_tuong_cong_phep": "Leave/Attendance object",
        "ERP_Doi_tuong_lao_dong": "Employment object",
        "ERP_Doi_tuong_tinh_luong": "Payroll object",
        "ERP_Don_gia": "Unit Price",
        "ERP_Don_gia_dieu_chinh": "Adjusting Unit Price",
        "ERP_Don_gia_khong_duoc_de_trong": "Don_gia_khong_duoc_de_trong",
        "ERP_Don_gia_thuc_te": "Don_gia_thuc_te",
        "ERP_Don_vi_chiu_phi": "Cost bearer",
        "ERP_Don_vi_det_bao": "Unit Pack",
        "ERP_Don_vi_tinh": "Unit",
        "ERP_Don_vi_uy_thac": "Consignment object",
        "ERP_Don_vi_uy_thac_khong_duoc_giong_voi_khach_hang_da_chon": "Consignment object can’t be the same as the Customer object",
        "ERP_Don_vi_uy_thac_nay_khong_duoc_giong_voi_nha_cung_cap_da_chon": "Don_vi_uy_thac_nay_khong_duoc_giong_voi_nha_cung_cap_da_chon",
        "ERP_Dong1": "Close",
        "ERP_Dong_goi": "Packing",
        "ERP_Dong_y": "OK",
        "ERP_Dot_trien_khai": "Deployment",
        "ERP_Dot_trien_khai_co_lot_da_mapping_ban_khong_the_xoa_ban_co_muon_gui_yeu_cau_xoa_mapping_khong": "Deployment has Lot was used mapping, You can’t remove the deployment. Do you want to send the delete mapping request ?",
        "ERP_Dot_trien_khai_co_lot_da_mapping_ban_khong_the_xoa_dot_trien_khai": "Deployment has Lot was used mapping, You can’t remove Deployment.",
        "ERP_Du_an": "Project",
        "ERP_Du_an_cong_trinh": "Project",
        "ERP_Du_an_moi": "New projects",
        "ERP_Du_lieu": "Data",
        "ERP_Du_lieu_cham_phep": "Record leave data",
        "ERP_Du_lieu_chua_duoc_luu_ban_co_muon_thoat_khong": "Data is not saved. Do you want to exit ?",
        "ERP_Du_lieu_chua_duoc_luu_ban_co_muon_tiep_tuc_khong": "Data is not saved. Do you want to continue ?",
        "ERP_Du_lieu_da_duoc_su_dung": "Data in use",
        "ERP_Du_lieu_da_duoc_su_dung_ban_khong_duoc_xoa": "Data has been used. You cannot delete it",
        "ERP_Du_lieu_dac_biet": "Extra data",
        "ERP_Du_lieu_dau_vao_la_bat_buoc": "Data input is required",
        "ERP_Du_lieu_phep_tong_hop": "Leave summary data",
        "ERP_Du_lieu_xuat_excel_rong": "The report has no data",
        "ERP_Dung_luong_File_khong_duoc_lon_hon": "File size must not be larger than",
        "ERP_Dung_sai": "Tolerance",
        "ERP_Duyet": "Approve",
        "ERP_Duyet_chung": "General apporval",
        "ERP_Duyet_cuoc_phi": "Duyet_cuoc_phi",
        "ERP_Duyet_de_xuat_dieu_chuyen_lao_dong": "Approve labor transfer proposal",
        "ERP_Duyet_de_xuat_khen_thuong": "Approve reward proposals",
        "ERP_Duyet_dieu_chinh_thu_nhap": "Approve income adjustments",
        "ERP_Duyet_hop_dong": "Duyet_hop_dong",
        "ERP_Duyet_khong_thanh_cong": "Approve unsuccessful",
        "ERP_Duyet_ky_luat": "Approve disciplinary actions",
        "ERP_Duyet_nghi_phep": "Leave approval",
        "ERP_Duyet_thanh_cong": "Approved successfully",
        "ERP_Duyet_yeu_cau_tach_lot": "Approve split lot request",
        "ERP_Email": "Email",
        "ERP_Email_ca_nhan": "Personal email",
        "ERP_Email_ho_tro": "Support email",
        "ERP_Email_khong_hop_le": "Invalid email address",
        "ERP_Ghi_chu": "Notes",
        "ERP_Ghi_chu_deposit": "Note deposit",
        "ERP_Ghi_chu_duyet": "Approve Notes",
        "ERP_Gia": "Price",
        "ERP_Gia_dinh": "Family",
        "ERP_Gia_mo_to_khai": "Price declaration",
        "ERP_Gia_tri": "Value",
        "ERP_Gia_tri_bat_buoc_nhap": "Value Obligatory",
        "ERP_Gia_tri_den": "Value to",
        "ERP_Gia_tri_den_khong_de_trong": "Gia_tri_den_khong_de_trong",
        "ERP_Gia_tri_dot_trien_khai": "Deployment value",
        "ERP_Gia_tri_dot_trien_khai_QD": "Deploymentt QĐ value",
        "ERP_Gia_tri_hop_dong": "Contract amount",
        "ERP_Gia_tri_hop_dong_QD": "Contract Value (Converted)",
        "ERP_Gia_tri_lot": "Amount Lot",
        "ERP_Gia_tri_lot_QD": "Amount LOT QĐ",
        "ERP_Gia_tri_tu": "Value from",
        "ERP_Gia_tri_tu_khong_de_trong": "Gia_tri_tu_khong_de_trong",
        "ERP_Giam_dinh": "Estimate",
        "ERP_Giay_phep_lao_dong": "Work permit",
        "ERP_Gio_cong": "Man hours",
        "ERP_Gio_ra": "Time out",
        "ERP_Gio_tu_phai_nho_hon_Gio_den": "Time form <= Time to",
        "ERP_Gio_vao": "Time In",
        "ERP_Gioi_tinh": "Gender",
        "ERP_Gop": "Combine",
        "ERP_Gop_lot": "Merge Lot",
        "ERP_Gui": "Send",
        "ERP_Gui_yeu_cau_thanh_cong": "Send request successfully",
        "ERP_Gui_yeu_cau_thanh_toan": "Gui_yeu_cau_thanh_toan",
        "ERP_Hai_long_can_cai_tien_them": "Satifactory and needs improvement",
        "ERP_Hang_hoa": "Inventory",
        "ERP_Hang_hoa_can_tim": "Search inventory",
        "ERP_Hang_hoa_da_lap_dot_trien_khai_ban_khong_duoc_phep_xoa": "Hang_hoa_da_lap_dot_trien_khai_ban_khong_duoc_phep_xoa",
        "ERP_Hanh_dong": "Action",
        "ERP_Hanh_vi_vi_pham_ky_luat": "Discipline Violation Act",
        "ERP_He_so_quy_cach_dong_goi": "He_so_quy_cach_dong_goi",
        "ERP_He_thong_dang_trong_qua_trinh_nang_cap": "The system is currently undergoing maintenance.",
        "ERP_Hien_thi_tat_ca": "Show all",
        "ERP_Hien_thi_vai_tro_khong_con_su_dung": "Show unused roles",
        "ERP_Hinh_thuc": "Type",
        "ERP_Hinh_thuc_huong_luong": "Payroll type",
        "ERP_Hinh_thuc_khen_thuong": "Reward type",
        "ERP_Hinh_thuc_ky_luat": "Disciplinary type",
        "ERP_Hinh_thuc_lam_viec": "Working type",
        "ERP_Hinh_thuc_nghi_viec": "Termination type",
        "ERP_Ho_chieu": "Passport",
        "ERP_Ho_so_nhan_vien": "Employee profile",
        "ERP_Ho_tro": "Support",
        "ERP_Ho_va_ten": "Full name",
        "ERP_Hoan_tat": "Completed",
        "ERP_Hoan_thanh": "Completed",
        "ERP_Hoan_toan_hai_long": "Fully satisfied",
        "ERP_Hoc_van_chuyen_mon": "Education - Qualifications",
        "ERP_Hom_nay": "Today",
        "ERP_Hom_qua": "Yesterday",
        "ERP_Hop_dong": "Contract",
        "ERP_Hop_dong_ban": "Sales contract",
        "ERP_Hop_dong_chua_duyet_ban_khong_the_doi_trang_thai": "Hop_dong_chua_duyet_ban_khong_the_doi_trang_thai",
        "ERP_Hop_dong_co_lot_da_mapping_ban_khong_the_xoa": "Contract has Lot was used mapping, You can’t remove.",
        "ERP_Hop_dong_da_co_dot_trien_khai_ban_khong_the_xoa": "The contract is deploying, you can't delete. ",
        "ERP_Hop_dong_da_duoc_duyet_ban_khong_the_sua": "Hop_dong_da_duoc_duyet_ban_khong_the_sua",
        "ERP_Hop_dong_da_duoc_duyet_ban_khong_the_xoa": "Hop_dong_da_duoc_duyet_ban_khong_the_xoa",
        "ERP_Hop_dong_da_mapping_ban_khong_the_xoa": "Contract was used mapping, You can’t remove",
        "ERP_Hop_dong_mapping": "mapping contract",
        "ERP_Hop_dong_mua": "Purchase Contract",
        "ERP_Hun_trung": "Fumigation",
        "ERP_Huong_dan_su_dung": "User manuals",
        "ERP_Huy": "Cancel",
        "ERP_Huy_phep": "Cancel leaves",
        "ERP_Huy_phep_thanh_cong": "Leave cancelled successfully",
        "ERP_In_bao_cao": "Print Report",
        "ERP_Keo_tap_tin_vao_day": "Drag the files here",
        "ERP_Keo_va_tha_tap_tin_dinh_kem_vao_day": "Drag & drop attachment files here",
        "ERP_Ket_thuc": "End",
        "ERP_KeyID_dang_rong_hoac_thieu_khong_the_tai_danh_sach_dinh_kem": "The KeyID is empty or missing,failed to upload th attached files",
        "ERP_Khac": "Other",
        "ERP_Khach_hang": "Customer",
        "ERP_Khao_sat_y_kien_khach_hang": "Customer survey",
        "ERP_Khi_xac_nhan_hoan_tat_ban_se_khong_duoc_sua": "Confirm this voucher will not modify, do you want to continue",
        "ERP_Kho_dong_hang": "Warehouse Packing",
        "ERP_Khoa_dong_mapping": "Line lock mapping",
        "ERP_Khoi_la_bat_buoc_nhap": "Division is required",
        "ERP_Khong": "No",
        "ERP_Khong_co_dong_du_lieu_duoc_cap_nhat": "No data row is updated",
        "ERP_Khong_co_ket_noi": "No conection to internet",
        "ERP_Khong_co_tap_tin_dinh_kem": "No attachments",
        "ERP_Khong_co_thong_bao": "No notifications",
        "ERP_Khong_du_quyen": "Insufficient permissions",
        "ERP_Khong_duoc_nhap_trung_so_lot": "Do not text the same LotNo",
        "ERP_Khong_luu": "Not saved",
        "ERP_Khong_su_dung": "Disabled",
        "ERP_Khong_ten": "No name",
        "ERP_Khong_tim_thay_trang": "PAGE NOT FOUND",
        "ERP_Khong_trang_thai": "No status",
        "ERP_Kich_co_toi_da": "Max file size",
        "ERP_Kien_thuc": "Knowledge",
        "ERP_Ky_luong": "Salary Period",
        "ERP_Lan": "Times",
        "ERP_Lap_de_xuat_dieu_chinh_thu_nhap": "Propose income adjustments",
        "ERP_Lap_de_xuat_dieu_chuyen_lao_dong": "Propose personnel transfers",
        "ERP_Lap_de_xuat_khen_thuong": "Propose awards",
        "ERP_Lap_de_xuat_ky_luat": "Propose disciplinary actions",
        "ERP_Lap_nghi_phep": "Record leave data",
        "ERP_Lap_phu_luc": "Lap_phu_luc",
        "ERP_Len_tau": "Len_tau",
        "ERP_Lich_su": "History",
        "ERP_Lich_su_tac_dong": "Transaction History",
        "ERP_Lo": "Lot",
        "ERP_Lo1": "Lo1",
        "ERP_Loai_HDLD": "Labor Contract Types",
        "ERP_Loai_bao_dong_goi": "Packing type",
        "ERP_Loai_chung_tu": "Voucher Type",
        "ERP_Loai_dieu_chinh": "Adjustment Type",
        "ERP_Loai_doi_tuong_ap_dung": "Applied Object Types",
        "ERP_Loai_gao_PO": "Type of rice (According to the internal contract)",
        "ERP_Loai_gao_SO": "Type of rice (According to the external contract)",
        "ERP_Loai_hoa_don": "Invoice Type",
        "ERP_Loai_hop_dong": "Contract Type",
        "ERP_Loai_nghiep_vu": "Transaction Type",
        "ERP_Loai_phep": "Leave Types",
        "ERP_Loai_phu_luc": "Appendix type",
        "ERP_Loai_quy_trinh": "Workflow type",
        "ERP_Loai_tap_tin_ho_tro": "File types supported",
        "ERP_Loai_thu_nhap": "Income Type",
        "ERP_Loai_tien": "CUR",
        "ERP_Loc_chon_hanh_dong": "Select Action",
        "ERP_Loi_chua_xac_dinh": "Error is not determined",
        "ERP_Loi_khong_lay_duoc_token_CDN": "Error while getting CDN token ",
        "ERP_Lot_da_mapping_khong_the_xoa_ban_co_muon_gui_yeu_cau_xoa_mapping_khong": "Lot was used mapping, You can’t remove Lot. Do you want to request delete the mapping ?",
        "ERP_Lot_da_tach": "Lot already separate",
        "ERP_Lot_yeu_cau_tach": "Request lot splitting",
        "ERP_Luu": "Save",
        "ERP_Luu_dinh_kem_khong_thanh_cong": "Attachments cannot be saved!",
        "ERP_Luu_khong_thanh_cong": "Save is not successfully",
        "ERP_Luu_lich_su_khong_thanh_cong": "Save failed attempt histrory",
        "ERP_Luu_thanh_cong": "Saved successfully",
        "ERP_Luu_thong_tin_chung_tu_khong_thanh_cong": "Document data cannot be saved",
        "ERP_Luu_va_nhap_tiep": "Save and Add New",
        "ERP_Ly_do": "Reason",
        "ERP_Ly_do_huy": "Cancellation reason",
        "ERP_Ma": "Code",
        "ERP_Ma_HDLD": "Labor Contract Code",
        "ERP_Ma_LOT": "Lot no",
        "ERP_Ma_Lo_da_ton_tai": "Ma_Lo_da_ton_tai",
        "ERP_Ma_NV": "Employee ID",
        "ERP_Ma_booking": "Booking No",
        "ERP_Ma_cham_cong": "Attendance Type Code",
        "ERP_Ma_chi_tiet_bao_bi": "Package detail code",
        "ERP_Ma_chi_tiet_giao_hang": "Delivery detail code",
        "ERP_Ma_co_cau": "Organization Code",
        "ERP_Ma_cong_ty": "Company Code",
        "ERP_Ma_detail_cua_hop_dong_mua": "Detail code of the purchase contract",
        "ERP_Ma_doi_tuong": "Object Code",
        "ERP_Ma_dot_trien_khai": "Deployment code",
        "ERP_Ma_hang": "Item Code",
        "ERP_Ma_hang_PO": "Inventory code (According to the internal contract)",
        "ERP_Ma_hang_SO": "Inventory code (According to the external contract)",
        "ERP_Ma_hang_hop_dong_ban": "Inventory Sales Contract no",
        "ERP_Ma_hang_hop_dong_mua": "Inventory Purchase Contract no",
        "ERP_Ma_hop_dong": "Contract Code",
        "ERP_Ma_khoa_cua_dot_trien_khai": "Deployment key",
        "ERP_Ma_khoa_dong_hop_dong": "Purchase no",
        "ERP_Ma_khoa_dong_mapping": "Mapping",
        "ERP_Ma_khoa_dot_trien_khai": "Quotation ID",
        "ERP_Ma_khoa_hop_dong": "Contract ID",
        "ERP_Ma_khoa_hop_dong_ban": "Sale contract no",
        "ERP_Ma_khoa_hop_dong_mua": "Sale no",
        "ERP_Ma_khoa_lot": "Lot no",
        "ERP_Ma_lo": "Lot Code",
        "ERP_Ma_loai_chung_tu": "Vou. Type Code",
        "ERP_Ma_lot_cua_dot_trien_khai": "Deployment lot code",
        "ERP_Ma_mapping": "Mapping no",
        "ERP_Ma_nguoi_dung": "User ID",
        "ERP_Ma_nhan_vien": "Employee ID",
        "ERP_Ma_nhan_vien_phu": "Sub Employee Code",
        "ERP_Ma_phuong_thuc_thanh_toan": "Payment Method Code",
        "ERP_Ma_so_thue": "Tax Codes",
        "ERP_Ma_tau": "Vessel",
        "ERP_Ma_the_cham_cong": "Attendance Card No",
        "ERP_Ma_thong_tin_thanh_toan": "Payment information code",
        "ERP_Ma_trien_khai": "Deploy no",
        "ERP_Ma_vai_tro": "Role Code",
        "ERP_Man_hinh": "Form",
        "ERP_Mapping": "Mapping",
        "ERP_Mapping_cu": "Mapping_cu",
        "ERP_Mat_hang": "Item",
        "ERP_Mat_hang_khong_duoc_de_trong": "Mat_hang_khong_duoc_de_trong",
        "ERP_Mat_khau": "Password",
        "ERP_Mau_bao_cao": "Report Code",
        "ERP_Mau_bao_cao_khong_duoc_tim_thay": "The report template is not found",
        "ERP_Mau_giao_dien": "Theme color",
        "ERP_Mo_rong": "Extend",
        "ERP_Mo_ta": "Description",
        "ERP_Muc_do_dong_y": "Agreement level",
        "ERP_NVA_nghiep_vu_a": "TTA - Trans type A",
        "ERP_Nam_nay": "This Year",
        "ERP_Nam_truoc": "Last Year",
        "ERP_Ngan_hang": "Banks",
        "ERP_Ngay": "Date",
        "ERP_Ngay_bat_dau": "Start date",
        "ERP_Ngay_cap": "Issue date",
        "ERP_Ngay_cap_CMND": "ID Card issue date",
        "ERP_Ngay_cham_cong": "Attendance Date",
        "ERP_Ngay_cong": "Man days",
        "ERP_Ngay_dang_ky_tu_khong_duoc_lon_hon_ngay_dang_ky_den": "Application from (to) cannot be greater then application date (to)",
        "ERP_Ngay_danh_gia": "Evaluation date",
        "ERP_Ngay_danh_gia_bat_buoc_nhap": "Evaluation date required",
        "ERP_Ngay_de_xuat": "Proposal date",
        "ERP_Ngay_hieu_luc": "Valid date",
        "ERP_Ngay_hieu_luc_den": "Valid date to",
        "ERP_Ngay_hieu_luc_tu": "Valid date from",
        "ERP_Ngay_in": "Print Date",
        "ERP_Ngay_ket_thuc": "Finish date",
        "ERP_Ngay_ky": "Signed Date",
        "ERP_Ngay_lap": "Created date",
        "ERP_Ngay_nghi": "Day off",
        "ERP_Ngay_nghi_viec": "Termination date",
        "ERP_Ngay_nhan": "Received date",
        "ERP_Ngay_phep": "Leave date",
        "ERP_Ngay_phieu": "Voucher Date",
        "ERP_Ngay_phun": "Fumigation Date",
        "ERP_Ngay_sinh": "Date of Birth",
        "ERP_Ngay_tac_dong": "Trans Date",
        "ERP_Ngay_tao": "Created date",
        "ERP_Ngay_tau_di": "Date vessel out",
        "ERP_Ngay_tau_vao": "Date vessel in",
        "ERP_Ngay_tham_gia_bao_hiem": "Insurance Participation Date",
        "ERP_Ngay_thuc_te_booking": "Date actual",
        "ERP_Ngay_tu_khong_duoc_lon_hon_ngay_den": "Date (from) cannot be greater then date (to)",
        "ERP_Ngay_vao": "Start Date",
        "ERP_Ngay_vao_lam": "Start Date",
        "ERP_Nghi_phep": "Leave",
        "ERP_Nghi_viec": "Resignation",
        "ERP_Nghiep_vu": "Transaction",
        "ERP_Nghiep_vu_da_co_du_lieu": "This contract already has data you want to change ?",
        "ERP_Nguoi_dai_dien": "Representative person",
        "ERP_Nguoi_danh_gia": "Evaluation Person",
        "ERP_Nguoi_danh_gia_bat_buoc_nhap": "Evaluation person is required",
        "ERP_Nguoi_dung_khong_su_dung": "User is disabled",
        "ERP_Nguoi_duyet": "Approver",
        "ERP_Nguoi_lap": "Created by",
        "ERP_Nguoi_lapU": "Employee",
        "ERP_Nguoi_lien_he": "Contact",
        "ERP_Nguoi_nuoc_ngoai": "Foreigner",
        "ERP_Nguoi_phu_trach": "Person in Charge",
        "ERP_Nguoi_quan_ly_truc_tiep": "Direct Manager",
        "ERP_Nguoi_tao": "Created by",
        "ERP_Nguoi_uy_quyen": "Authorizing person",
        "ERP_Nha_cung_cap": "Supplier",
        "ERP_Nhan_mac": "Marking",
        "ERP_Nhan_vien": "Employee",
        "ERP_Nhap": "Text",
        "ERP_Nhap_Ten_Dang_Nhap": "Enter username",
        "ERP_Nhap_mat_khau": "Enter password",
        "ERP_Nhap_tiep": "Next",
        "ERP_Nhap_tu_tim_kiem": "Enter search key words",
        "ERP_Nho_mat_khau": "Save login and password",
        "ERP_Nho_mat_khau1": "Remember me",
        "ERP_Nhom_hanh_vi_vi_pham_ky_luat": "Violation Act Group",
        "ERP_Nhom_luong": "Salary Group",
        "ERP_Nhom_nhan_vien": "Employee Group",
        "ERP_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "This feedback will be taken by our company in oder to improve our service quality level and provide better service to our customers.",
        "ERP_No": "Debt",
        "ERP_No_data": "No Data",
        "ERP_No_link": "The link was not found  ",
        "ERP_Noi_cap": "Issue Place",
        "ERP_Noi_cap_CMND": "ID Card Issue Place",
        "ERP_Noi_dung": "Content",
        "ERP_Noi_dung_can_tim_kiem": "Content to search",
        "ERP_Noi_sinh": "Place of Birth",
        "ERP_O_tab": "at tab",
        "ERP_Phai_thuc_hien_phan_bo_cac_dong_chi_tiet_bao_bi_va_chi_tiet_giao_hang": "Must distributed detail pack and detail delivery.",
        "ERP_Phan_bo_chi_tiet_giao_hang": "Allocate delivery details",
        "ERP_Phan_bo_mapping_tu_yeu_cau_tach_lot": "Allocate mapping from the lot split request",
        "ERP_Phan_bo_thong_tin_chi_tiet_giao_hang": "Allocate delivery details",
        "ERP_Phan_quyen": "Permission",
        "ERP_Phan_quyen_chuc_nang": "Functional Permisstion",
        "ERP_Phan_quyen_du_lieu": "Data Permission",
        "ERP_Phan_quyen_theo_vai_tro": "Permission by roles",
        "ERP_Phep": "Leave",
        "ERP_Phien_ban": "Version",
        "ERP_Phieu_cham_cong": "Timesheet voucher",
        "ERP_Phieu_dang_ky_da_duoc_duyet_ban_khong_duoc_phep_thay_doi": "Application is approved, you cannot edit.",
        "ERP_Phong_ban": "Department",
        "ERP_Phong_ban_moi": "New Department",
        "ERP_Phu_luc_da_duoc_duyet": "This voucher has been approved",
        "ERP_Phu_luc_da_duoc_duyet_ban_khong_duoc_sua": "This voucher has been approved, you can’t edit",
        "ERP_Phu_luc_da_duoc_duyet_ban_khong_duoc_xoa": "This voucher has been approved, you can’t delete",
        "ERP_Phu_luc_hop_dong": "Appendix contract",
        "ERP_Phu_trach_chung_tu": "Phu_trach_chung_tu",
        "ERP_Phun_trung": "Fumigation",
        "ERP_Phuong_thuc": "Method",
        "ERP_Phuong_thuc_da_ton_tai": "Method already exists",
        "ERP_Phuong_thuc_khong_duoc_de_trong": "Method is required",
        "ERP_Phuong_thuc_thanh_toan": "Payment Method",
        "ERP_Phuong_tien": "Transportation",
        "ERP_Phuong_tien_van_chuyen": "Shipment",
        "ERP_Phuong_tien_van_chuyen_bat_buoc_nhap": "Shipment is required",
        "ERP_Quan_he": "Relationship",
        "ERP_Quan_he_gia_dinh": "Family Relationships",
        "ERP_Quan_ly": "Management",
        "ERP_Quoc_gia": "Country",
        "ERP_Quoc_tich": "Citizenship",
        "ERP_Quy_cach_dong_goi": "Pallet Size",
        "ERP_Quy_uoc_nhu_sau": "Agreed as follows",
        "ERP_Quyen_han": "Rights",
        "ERP_SDT_ho_tro": "Support phone number",
        "ERP_STT": "No.",
        "ERP_SXDong_goi": "Product/Packing",
        "ERP_Sao_chep": "Copy",
        "ERP_Sinh_nhat": "Birthday",
        "ERP_So_BL": "So_BL",
        "ERP_So_Fax": "Fax No",
        "ERP_So_bao_hiem": "Insurance Book",
        "ERP_So_chi_tiet_bao_bi": "Pack No",
        "ERP_So_chi_tiet_giao_hang": "Delivery No",
        "ERP_So_dien_thoai": "Tel no.",
        "ERP_So_dien_thoai_khong_hop_le": "Phone number is invalid",
        "ERP_So_dong": "Number of rows",
        "ERP_So_hoa_don": "Invoice No",
        "ERP_So_hop_dong": "Contract No",
        "ERP_So_hop_dong_ban": "Sales Order No",
        "ERP_So_hop_dong_da_ton_tai": "Contract No is existed. You can't save",
        "ERP_So_hop_dong_mua": "Purchase contract No",
        "ERP_So_lan_khu_trung": "So_lan_khu_trung",
        "ERP_So_lot": "Lot number",
        "ERP_So_luong": "Quantity",
        "ERP_So_luong_QD": "Converted QTY",
        "ERP_So_luong_bao": "So_luong_bao",
        "ERP_So_luong_bao_yeu_cau": "Quantity Pack",
        "ERP_So_luong_bao_yeu_cau_mapping": "Quantity Pack Mapping",
        "ERP_So_luong_bao_yeu_cau_vua_nhap_khong_hop_le": "So_luong_bao_yeu_cau_vua_nhap_khong_hop_le",
        "ERP_So_luong_cho_phep": "Available QTY",
        "ERP_So_luong_chua_trien_khai": "Quantity Deploy",
        "ERP_So_luong_co_the_mapping": "Mapping Quantity",
        "ERP_So_luong_con_lai": "Remain Quantity",
        "ERP_So_luong_con_lai_cua_hop_dong_mua_phai_bang_0": "The remaining amount of the purchase contract must be 0.",
        "ERP_So_luong_con_lai_dong_cho_sa_lan_toi_cuoi_ngay_nhap_lieu": "The remainder can be closed for the barges until the end of the data entry date",
        "ERP_So_luong_da_giao": "Quantity delivered",
        "ERP_So_luong_da_giao_vua_nhap_khong_hop_le": "So_luong_da_giao_vua_nhap_khong_hop_le",
        "ERP_So_luong_da_mapping": "Quantity Actual",
        "ERP_So_luong_da_nghi_den_ky_hien_tai": "Taken quantity until current period",
        "ERP_So_luong_da_thuc_hien": "Realized Original quantity",
        "ERP_So_luong_da_trien_khai": "Quantity deployed",
        "ERP_So_luong_dang_trien_khai_la": "So_luong_dang_trien_khai_la",
        "ERP_So_luong_di_cua_booking": "Quantity booking",
        "ERP_So_luong_duoc_cap": "Issued Quantity",
        "ERP_So_luong_duoc_nhap_nho_hon_tong_so_luong_da_giao": "So_luong_duoc_nhap_nho_hon_tong_so_luong_da_giao",
        "ERP_So_luong_huy1": "Cancel number",
        "ERP_So_luong_khong_duoc_bang_0": "Quantity cannot be zero",
        "ERP_So_luong_khong_duoc_de_trong": "The quantity is required",
        "ERP_So_luong_khong_duoc_vuot_qua": "Quantity can not over",
        "ERP_So_luong_khong_hop_le": "Leave quantity is invalid.",
        "ERP_So_luong_lot": "Quantity of Lot",
        "ERP_So_luong_lot_tach": "Quantity lot separate",
        "ERP_So_luong_phuong_tien_da_dong": "Number of vehicles closed",
        "ERP_So_luong_phuong_tien_da_dong_goi": "Quantity Pack",
        "ERP_So_luong_thuc_hien_cua_mapping": "Quantity Actual Mapping",
        "ERP_So_luong_thuc_hien_mapping": "Quantity Actual Mapping",
        "ERP_So_luong_ton_den_cuoi_nam": "Leave year end balance",
        "ERP_So_luong_ton_den_ky_hien_tai": "Leave balance until current period",
        "ERP_So_luong_tong_cua_dot_trien_khai": "Total Quantity Of Deploy",
        "ERP_So_luong_vua_nhap_nho_hon_so_luong_dang_trien_khai": "So_luong_vua_nhap_nho_hon_so_luong_dang_trien_khai",
        "ERP_So_luong_vua_nhap_nho_hon_so_luong_dang_trien_khai_ban_co_muon_chinh_sua_so_luong_dang_trien_khai_cua_hang_hoa_nay": "So_luong_vua_nhap_nho_hon_so_luong_dang_trien_khai_ban_co_muon_chinh_sua_so_luong_dang_trien_khai_cua_hang_hoa_nay",
        "ERP_So_luong_yeu_cau": "Request QTY",
        "ERP_So_luong_yeu_cau_cua_mapping": "Quantity mapping",
        "ERP_So_luong_yeu_cau_mapping": "Quantity mapping",
        "ERP_So_mapping": "Mapping no",
        "ERP_So_phieu": "Voucher No",
        "ERP_So_phu_luc": "Appendix No.",
        "ERP_So_tai_khoan": "Account No",
        "ERP_So_thang": "Number of months",
        "ERP_So_thu_tu": "Order No",
        "ERP_So_tien_thanh_toan": "Real Payment",
        "ERP_So_tien_thuc_te": "So_tien_thuc_te",
        "ERP_So_tien_yeu_cau": "So_tien_yeu_cau",
        "ERP_Su_dung": "Used",
        "ERP_Sua": "Edit",
        "ERP_Sua_khac": "Edit Other",
        "ERP_Tach": "Split",
        "ERP_Tach_lot": "Separate LOT",
        "ERP_Tai_khoan": "Account",
        "ERP_Tai_khoan_bi_khoa_10_phut": "Your account is locked, please try again in 10 minutes",
        "ERP_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "Incorrect login or password",
        "ERP_Tai_khoan_khong_co_quyen": "You doo not have the permissioin to access the system.",
        "ERP_Tai_lieu": "Document",
        "ERP_Tai_trong": "Weight",
        "ERP_Tai_trong_phuong_tien": "Weight Quantity",
        "ERP_Tam_dap_ung_Can_cai_tien_them": "Temporarily satisfied, needs improvement",
        "ERP_Tap_tin": "File",
        "ERP_Tap_tin_dinh_kem_dang_duoc_xu_ly": "Attachments in processing",
        "ERP_Tap_tin_tai_len": "Upload files",
        "ERP_Tat_ca": "All",
        "ERP_Tat_ca_cac_lot_phai_ket_thuc_khi_ket_thuc_dot_trien_khai": "All finished Lots will finish the deployment.",
        "ERP_Ten": "Name",
        "ERP_Ten_Dang_Nhap": "Username",
        "ERP_Ten_cong_ty": "Company Name",
        "ERP_Ten_dieu_chinh_chung_tu_cua_lot": "Voucher name of Lot",
        "ERP_Ten_doi_tuong": "Object Name",
        "ERP_Ten_hang": "Item Name",
        "ERP_Ten_hang_hop_dong_ban": "Inventory Name Sales Contract",
        "ERP_Ten_hang_hop_dong_mua": "Inventory Name Purchase Contract",
        "ERP_Ten_hang_khong_duoc_de_trong": "Ten_hang_khong_duoc_de_trong",
        "ERP_Ten_hang_thuc_te": "Ten_hang_thuc_te",
        "ERP_Ten_lo": "Lot No",
        "ERP_Ten_man_hinh": "Form Name",
        "ERP_Ten_nguoi_dung": "User Name",
        "ERP_Ten_nguoi_quan_he": "Contact Person",
        "ERP_Ten_nhan_vien": "Employee Name",
        "ERP_Ten_phong_ban": "Department Name",
        "ERP_Ten_tau": "Vessel Name",
        "ERP_Ten_thong_tin_thanh_toan": "Payment information name",
        "ERP_Ten_vai_tro": "Role name",
        "ERP_Tham_nien": "Seniority",
        "ERP_Tham_so_truyen_vao_khong_hop_le": "Input parameters invalid",
        "ERP_Thang_nay": "This month",
        "ERP_Thang_truoc": "Last month",
        "ERP_Thanh_tien": "Amount",
        "ERP_Thanh_tien_QD": "Converted Amount",
        "ERP_Thanh_tien_deposit": "Amount deposit",
        "ERP_Thanh_tien_deposit_QD": "Converted deposit amount QD",
        "ERP_Thanh_toan": "Payment",
        "ERP_Thanh_vien_cua_Nihon_Grouping_va_Pan": "Members of Nihon Group and PAN Group",
        "ERP_Thay_doi_hop_dong_se_lam_thay_doi_du_lieu": "Thay_doi_hop_dong_se_lam_thay_doi_du_lieu",
        "ERP_The_can_cuoc": "Citizen ID Card",
        "ERP_The_luu_tru": "Residence Card",
        "ERP_Them": "Add",
        "ERP_Them_chi_tiet_giao_hang_vuot_qua_so_luong_mapping": "Add new delivery details in excess of number of mapping.",
        "ERP_Them_dieu_kien": "Them_dieu_kien",
        "ERP_Them_dot_trien_khai": "Add deployments",
        "ERP_Them_hang_hoa": "Add inventory",
        "ERP_Them_lo": "Them_lo",
        "ERP_Them_mapping": "Add mapping",
        "ERP_Them_moi": "<Add new>",
        "ERP_Them_moi1": "Add new",
        "ERP_Them_moi_lo": "Them_moi_lo",
        "ERP_Thoi_gian": "Time",
        "ERP_Thoi_gian_bat_dau_dong_hang": "Start Date Pack",
        "ERP_Thoi_gian_den_cang": "Thoi_gian_den_cang",
        "ERP_Thoi_gian_dong_hang": "Packing time",
        "ERP_Thoi_gian_giao_bao": "Parking delivery date",
        "ERP_Thoi_gian_giao_hang": "Delivery Time",
        "ERP_Thoi_gian_giao_hang_den_ngay": "Thoi_gian_giao_hang_den_ngay",
        "ERP_Thoi_gian_giao_hang_du_kien": "Delivery date",
        "ERP_Thoi_gian_giao_hang_du_kien_cua_dot_trien_khai": "Delivery Date Of Deploy",
        "ERP_Thoi_gian_giao_hang_du_kien_den": "Thoi_gian_giao_hang_du_kien_den",
        "ERP_Thoi_gian_giao_hang_du_kien_tu": "Thoi_gian_giao_hang_du_kien_tu",
        "ERP_Thoi_gian_giao_hang_theo_lot": "DeliveryDate of Lot",
        "ERP_Thoi_gian_giao_hang_tu_ngay": "Thoi_gian_giao_hang_tu_ngay",
        "ERP_Thoi_gian_hieu_luc": "Valid Time",
        "ERP_Thoi_gian_ket_thuc_dong_hang": "End Date Pack",
        "ERP_Thoi_gian_ra_vao_VN": "Time in and out VN",
        "ERP_Thong_bao": "Announcement",
        "ERP_Thong_bao1": "Alert",
        "ERP_Thong_baoU": "Notifications",
        "ERP_Thong_bao_giao_hang": "Delivery Notice",
        "ERP_Thong_bao_lot_da_duoc_mapping": "Lot was used mapping. Quantity Lot must greater than or equal quantity of mapping is",
        "ERP_Thong_bao_lot_da_mapping": "Lot was used mapping, You must remove Lot of Mapping.",
        "ERP_Thong_bao_xac_nhan_chung_tu": "Are you sure you want to switch document status to incomplete?",
        "ERP_Thong_tin_bao_bi": "Pack note",
        "ERP_Thong_tin_ca_nhan": "Personal Information",
        "ERP_Thong_tin_chinh": "Main",
        "ERP_Thong_tin_chung": "General Information",
        "ERP_Thong_tin_cu": "Old information",
        "ERP_Thong_tin_cung_cap_khong_hop_le": "Provided data invalid",
        "ERP_Thong_tin_dong_goi": "Packing Information",
        "ERP_Thong_tin_giao_hang": "Delivery Information",
        "ERP_Thong_tin_hoa_don": "Invoice Information",
        "ERP_Thong_tin_huy": "Information cancel",
        "ERP_Thong_tin_khac": "Other Information",
        "ERP_Thong_tin_lien_lac": "Contact Information",
        "ERP_Thong_tin_mapping": "Mapping infomation",
        "ERP_Thong_tin_nguoi_dung": "User information",
        "ERP_Thong_tin_phan_quyen": "Permission information",
        "ERP_Thong_tin_phu": "Sub Information",
        "ERP_Thong_tin_phu_luc": "Information SubContract",
        "ERP_Thong_tin_tang_nhan_vien": "Staff Increase information",
        "ERP_Thong_tin_thanh_toan": "Information Billing",
        "ERP_Thong_tin_thanh_toan_cua_lot": "Billing Information of Lot",
        "ERP_Thong_tin_thanh_toan_lot": "Lot payment information",
        "ERP_Thong_tin_vai_tro": "Role information",
        "ERP_Thuc_hien": "Action",
        "ERP_Thuc_hien_mapping": "Mapping implementation",
        "ERP_Thuc_hien_thanh_cong": "Successfully saved",
        "ERP_Tieu_chuan_chat_luong": "Specification",
        "ERP_Tim_doi_tuong": "Tìm đối tượng",
        "ERP_Tim_kiem": "Finding Information",
        "ERP_Tim_kiem_cham_cong": "Search timesheet",
        "ERP_Tim_kiem_cong_ty": "Find company",
        "ERP_Tim_kiem_doi_tuong": "Search object",
        "ERP_Tim_kiem_hang_hoa": "Search Inventory",
        "ERP_Tim_kiem_ho_so_nhan_su": "Search for HR profiles",
        "ERP_Tim_kiem_hop_dong": "Tim_kiem_hop_dong",
        "ERP_Tim_kiem_ten_nhan_vien_va_ma_nhan_vien": "Search with employee name and employee id..",
        "ERP_Tinh_trang_hien_tai": "Current Status",
        "ERP_Tinh_trang_hoan_thanh": "Completion status",
        "ERP_Tinh_trang_hon_nhan": "Marital Status",
        "ERP_To_chuc": "Organization",
        "ERP_To_nhom": "Team",
        "ERP_To_nhom_moi": "New Team",
        "ERP_Toan_tu": "Operator",
        "ERP_Toan_tu_khong_duoc_de_trong": "Toan_tu_khong_duoc_de_trong",
        "ERP_Ton_giao": "Religion",
        "ERP_Tong": "Total",
        "ERP_Tong_diem": "Total points",
        "ERP_Tong_gia_tri_hoa_don": "Tong_gia_trị_hoa_don",
        "ERP_Tong_gia_tri_hoa_don_QD": "Tong_gia_trị_hoa_don_QD",
        "ERP_Tong_gia_tri_hop_dong": "Total contract amount",
        "ERP_Tong_gia_tri_hop_dong_QD": "Total value of contract QD",
        "ERP_Tong_so_luong": "Total quantity",
        "ERP_Tong_so_luong_cac_lot_phai_bang_so_luong_cua_lot_tach": "The total number of the LOT should be equal to the Number of the LOT split",
        "ERP_Tong_so_luong_chi_tiet_giao_hang_duoc_phan_bo_cua_mapping": "Total quantity of delivery details allocated by Mapping",
        "ERP_Tong_so_luong_dong_goi_cua_cac_mapping_phai_bang_so_luong_dong_goi_cua_chi_tiet_giao_hang": "Total quantity pack of the mappings is not equal quantity pack of the @DeliveryNo quantity",
        "ERP_Tong_so_luong_dong_goi_cua_cac_mapping_vuot_qua_so_luong_dong_goi_cua_chi_tiet_giao_hang": "The total packing quantity of the mapping exceeds the packing quantity of the delivery detail",
        "ERP_Tong_so_ngay": "Total days",
        "ERP_Trang": "Page",
        "ERP_Trang_Chu": "Home Page",
        "ERP_Trang_thai": "Status",
        "ERP_Trang_thai_Phu_luc_hop_dong": "Trang_thai_Phu_luc_hop_dong",
        "ERP_Trang_thai_chi_tiet_giao_hang": "Delivery detail status",
        "ERP_Trang_thai_cu": "Old state",
        "ERP_Trang_thai_deposit": "Trang_thai_deposit",
        "ERP_Trang_thai_duyet": "Approval Status",
        "ERP_Trang_thai_duyet_Hop_dong": "Trang_thai_duyet_Hop_dong",
        "ERP_Trang_thai_hoa_don_": "Invoice's Status",
        "ERP_Trang_thai_hoan_tat_ban_khong_duoc_phep_xoa": "Status is Finished deployed. You not allowed delete.",
        "ERP_Trang_thai_hop_dong": "Contract Status",
        "ERP_Trang_thai_lam_viec": "Working Status",
        "ERP_Trang_thai_moi": "New Status",
        "ERP_Trang_thai_phuong_tien": "Status Transport Means",
        "ERP_Trinh_do_hoc_van": "Educational Level",
        "ERP_Trinh_do_van_hoaU": "Education Level",
        "ERP_Tro_giup": "Help",
        "ERP_Trong_luong_Gross": "Gross Weight",
        "ERP_Trong_luong_bao_bi": "Trong_luong_bao_bi",
        "ERP_Trong_luong_cont": "Cont weight",
        "ERP_Truong_nay_bat_buoc_nhap": "This field is required",
        "ERP_Truong_nay_khong_hop_le": "This field is invalid",
        "ERP_Truong_ngay_la_bat_buoc": "Date is required",
        "ERP_Truy_cap_khong_hop_le": "Invalid access",
        "ERP_Truy_van_tang_nhan_vien": "Query increase staff",
        "ERP_Truy_van_thong_tin_cong_phep": "Query attendance information",
        "ERP_Tu": "From",
        "ERP_Tu_choi": "Reject",
        "ERP_Tu_choi_W75": "Refuse",
        "ERP_Tu_choi_khong_thanh_cong": "Reject unsuccessfully!",
        "ERP_Tu_choi_thanh_cong": "Reject successfully!",
        "ERP_Tu_ngay": "Date from",
        "ERP_Tuan_nay": "This week",
        "ERP_Tuan_truoc": "Last week",
        "ERP_Tuoi": "Age",
        "ERP_Tuyen_dung": "Recruitment",
        "ERP_Ty_gia": "Exchange Rate",
        "ERP_Ty_le_deposit": "Deposit rate",
        "ERP_URL": "URL",
        "ERP_URL_khong_hop_le": "Invalid URL",
        "ERP_Username_va_Password_khong_hop_le!": "Username or Password is incorrect!",
        "ERP_Vai_tro": "Roles",
        "ERP_Van_chuyen_thanh_toan": "Payment - shipment",
        "ERP_VoucherID_khong_duoc_de_trong": "VoucherID cannot be blank",
        "ERP_Vui_long_chon_hang_hoa": "Vui lòng chọn hàng hóa",
        "ERP_Vui_long_nhap_so_luong_va_gia_hang_hoa": "Please enter the full quantity and price of inventories",
        "ERP_Vui_long_truy_cap_sau": "Vui_long_truy_cap_sau",
        "ERP_Vuot_qua_so_luong_tap_tin_cho_phep": "Number of allowed files exceedeed",
        "ERP_Xac_nhan": "Confirm",
        "ERP_Xac_nhan_giao_hang": "Confirm delivery",
        "ERP_Xac_nhan_nhan_vien_nhan_viec_tai_du_an": "Confirm emloyees onboard in project",
        "ERP_Xac_nhan_so_luong_da_giao": "Confirm the amount delivered",
        "ERP_Xac_nhan_tat_ca": "Confirm all",
        "ERP_Xac_nhan_thanh_cong": "Confirm successful",
        "ERP_Xem": "View",
        "ERP_Xem_chi_tiet": "View Detail",
        "ERP_Xem_hop_dong_goc": "Xem_hop_dong_goc",
        "ERP_Xem_tap_tin": "View files",
        "ERP_Xem_tat_ca": "Search all",
        "ERP_Xem_them": "See more",
        "ERP_Xem_truoc": "Preview",
        "ERP_Xin_cam_on": "Xin_cam_on",
        "ERP_Xoa": "Delete",
        "ERP_Xoa_dong": "Delete row",
        "ERP_Xoa_du_lieu": "Delete data",
        "ERP_Xoa_tam": "Xoa_tam",
        "ERP_Xoa_thanh_cong": "Deleted successfully",
        "ERP_Xuat": "Export",
        "ERP_Xuat_Excel": "Export To Excel",
        "ERP_Xuat_PDF": "Export in PDF",
        "ERP_Xuat_bao_cao": "Export report",
        "ERP_Xuat_du_lieu": "Export Data",
        "ERP_Xuat_du_lieu_thanh_cong": "Export data is success",
        "ERP_Xuat_mau_in_xac_nhan_giao_hang": "Export delivery confirmation print form",
        "ERP_Xuat_thong_bao_giao_hang": "Export delivery Notice",
        "ERP_Xuat_word": "Eport in Word",
        "ERP_Y_kien_dong_gop": "Feedback",
        "ERP_Yes": "Yes",
        "ERP_Yeu_cau_tach_lot": "Separate LOT request ",
        "ERP_Yeu_cau_thanh_toan": "Payment Request",
        "ERP_dang_nhap_lai": "Login again",
        "ERP_dong": "lines",
        "ERP_khong_co_du_lieu": "No data",
        "ERP_trong": "empty"
    },
    "ja": {
        "ERP_403_loi": "",
        "ERP_404_loi": "",
        "ERP_AC_vui_long_chon_y_kien_cua_minh": "",
        "ERP_Ban_can_nhap_hang_hoa_cho_hoa_don": "",
        "ERP_Ban_chac_chan_muon_sao_chep_cot_gia_tri_nay?": "",
        "ERP_Ban_chi_duoc_dieu_chinh_giam_0_5_phep": "",
        "ERP_Ban_chi_duoc_phep_sua_so_luong_lon_hon_hoac_bang_so_luong_da_trien_khai_la": "",
        "ERP_Ban_chua_trien_khai_hop_dong_ban_khong_duoc_ket_thuc_hop_dong": "",
        "ERP_Ban_co_chac_chan_muon_xac_nhan_chi_tiet_giao_hang_nay_khong": "",
        "ERP_Ban_co_chac_chan_muon_xac_nhan_tat_ca_cac_chi_tiet_giao_hang_khong": "",
        "ERP_Ban_co_chac_chan_muon_xoa_du_lieu_nay": "",
        "ERP_Ban_co_chac_chan_muon_xoa_thong_tin_thanh_toan_nay_khong": "",
        "ERP_Ban_co_chac_muon_bo_trang_thai_nay?": "",
        "ERP_Ban_co_chac_muon_duyet": "",
        "ERP_Ban_co_chac_muon_huy_phep?": "",
        "ERP_Ban_co_chac_muon_luu_khong?": "",
        "ERP_Ban_co_chac_muon_xoa?": "",
        "ERP_Ban_co_muon_chuyen_trang_thai_chung_tu": "",
        "ERP_Ban_co_muon_tiep_tuc_sua": "",
        "ERP_Ban_co_muon_xoa_du_lieu": "",
        "ERP_Ban_da_check_hoan_tat_chung_tu_nay": "",
        "ERP_Ban_da_check_hoan_thanh_chung_tu_nay": "",
        "ERP_Ban_da_chon_phuong_thuc_nay": "",
        "ERP_Ban_giao": "",
        "ERP_Ban_khong_co_quyen_duyet": "",
        "ERP_Ban_khong_duoc_sua_so_luong_hang_hoa_nho_hon_so_luong_dang_trien_khai_la": "",
        "ERP_Ban_khong_duoc_xoa_hang_hoa_da_trien_khai": "",
        "ERP_Ban_khong_the_luu": "",
        "ERP_Ban_phai_check_su_dung_truoc": "",
        "ERP_Ban_phai_chon_doanh_nghiep": "",
        "ERP_Bao_cao": "",
        "ERP_Bao_cao_cong": "",
        "ERP_Bao_yeu_cau": "",
        "ERP_Bat_buoc": "",
        "ERP_Bat_buoc_nhap_du_lieu": "",
        "ERP_Bat_dau": "",
        "ERP_Bo_duyet": "",
        "ERP_Bo_xac_nhan": "",
        "ERP_Boi": "",
        "ERP_CDN_chua_san_sang": "",
        "ERP_CMND": "",
        "ERP_Ca": "シフト",
        "ERP_Ca_nhan": "Personal",
        "ERP_Cac_dot_trien_khai_chua_ket_thuc_ban_khong_the_ket_thuc_hop_dong": "",
        "ERP_Cac_gop_y_khac": "",
        "ERP_Cac_tham_so_la_bat_buoc": "",
        "ERP_Cac_tieu_chi_la_bat_buoc_nhap": "",
        "ERP_Cac_truong_khong_bang_nhau": "",
        "ERP_Cai_dat": "",
        "ERP_Cang_den": "",
        "ERP_Cang_di": "",
        "ERP_Cap": "レベル",
        "ERP_Cap_co_cau": "",
        "ERP_Cap_khen_thuong": "",
        "ERP_Cap_ky_luat": "",
        "ERP_Cap_nhat_Lo": "",
        "ERP_Cap_nhat_chung_tu": "",
        "ERP_Cap_nhat_cuoc_phi": "",
        "ERP_Cap_nhat_thong_tin_huy": "",
        "ERP_Cap_nhat_trang_thai": "",
        "ERP_Cap_nhat_trang_thai_duyet": "",
        "ERP_Cap_nhat_trang_thai_duyet_phu_luc": "",
        "ERP_Cap_nhat_trang_thai_hoa_don": "",
        "ERP_Cap_nhat_trang_thai_hop_dong_lao_dong": "",
        "ERP_Cham_cong_chi_tiet": "",
        "ERP_Cham_cong_theo_du_an": "",
        "ERP_Chao_buoi_sang": "",
        "ERP_Chao_mung_login": "",
        "ERP_Chi_phi_huy": "",
        "ERP_Chi_tiet": "詳細",
        "ERP_Chi_tiet_bao_bi": "",
        "ERP_Chi_tiet_can_phan_bo": "",
        "ERP_Chi_tiet_dieu_kien": "",
        "ERP_Chi_tiet_dot_trien_khai": "",
        "ERP_Chi_tiet_giao_hang": "",
        "ERP_Chi_tiet_hoa_don": "",
        "ERP_Chi_tiet_hop_dong": "",
        "ERP_Chi_tiet_hop_dong_ban": "",
        "ERP_Chi_tiet_hop_dong_mua": "",
        "ERP_Chi_tiet_hop_dong_trong_mapping": "",
        "ERP_Chi_tiet_mapping": "",
        "ERP_Chi_tiet_nhan_vien": "",
        "ERP_Chi_tiet_phep": "",
        "ERP_Chi_tiet_phu_luc_hop_dong_ban": "",
        "ERP_Chi_tiet_phu_luc_hop_dong_mua": "",
        "ERP_Chinh_sua": "",
        "ERP_Chinh_tri": "",
        "ERP_Cho_duyet": "承認待ち",
        "ERP_Chon": "Select",
        "ERP_Chon_ban_giao": "",
        "ERP_Chon_co_cau_to_chuc": "",
        "ERP_Chon_du_an": "",
        "ERP_Chon_hop_dong": "",
        "ERP_Chon_hop_dong_lap_phu_luc": "",
        "ERP_Chon_ky": "",
        "ERP_Chon_ky_luong": "",
        "ERP_Chon_mapping": "",
        "ERP_Chon_mau_bao_cao": "",
        "ERP_Chon_ngay": "",
        "ERP_Chon_nhan_vien": "",
        "ERP_Chon_nhom_luong": "",
        "ERP_Chon_thoi_gian": "",
        "ERP_Chon_trang_thai": "",
        "ERP_Chon_trang_thai_duyet": "",
        "ERP_Chu_de": "",
        "ERP_Chua_chon_hang_hoa": "",
        "ERP_Chua_co_lien_ket_nao": "",
        "ERP_Chua_dap_ung": "",
        "ERP_Chua_duyet": "",
        "ERP_Chua_hoan_thanh": "",
        "ERP_Chua_xac_nhan": "",
        "ERP_Chuc_danh": "",
        "ERP_Chuc_danh_cong_viec": "",
        "ERP_Chuc_vu": "位置",
        "ERP_Chuc_vu_bat_buoc_nhap": "",
        "ERP_Chung_minh_nhan_dan": "",
        "ERP_Chung_tu": "",
        "ERP_Chung_tu_da_hoan_thanh_khong_the_sua": "",
        "ERP_Chung_tu_da_hoan_thanh_khong_the_xoa": "",
        "ERP_Chuyen_doi_trang_thai_LOT": "",
        "ERP_Chuyen_mapping": "",
        "ERP_Chuyen_mon": "",
        "ERP_Chuyen_nganh": "",
        "ERP_Chuyen_trang_thai_Lot": "",
        "ERP_Co1": "",
        "ERP_Co_cau_cha": "",
        "ERP_Co_cau_to_chuc": "",
        "ERP_Co_hop_dong_ban_chua_thuc_hien_mapping": "",
        "ERP_Co_loi_khi_luu": "",
        "ERP_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "",
        "ERP_Co_so_luong_ban_khong_duoc_phep_xoa": "",
        "ERP_Con_dong_chua_duoc_nhap_lieu/Ban_co_muon_luu_khong?": "",
        "ERP_Con_dong_chua_duoc_nhap_lieu_Ban_co_muon_luu_khong": "",
        "ERP_Cong_trinh/Cong_ty": "",
        "ERP_Cong_ty": "会社",
        "ERP_Cong_ty_bao_hiem": "",
        "ERP_Cong_ty_phun": "",
        "ERP_Cong_viec": "仕事",
        "ERP_Cong_viec_moi": "",
        "ERP_Cua_lot": "",
        "ERP_DHR_Ban_chac_chan_muon_sao_chep_cot_gia_tri_nay?": "",
        "ERP_DHR_Gia_tri": "",
        "ERP_DHR_Xoa": "",
        "ERP_Da_duyet": "承認された",
        "ERP_Da_hoan_tat": "",
        "ERP_Da_ket_noi": "",
        "ERP_Da_mapping": "",
        "ERP_Da_nghi_viec": "",
        "ERP_Da_sao_chep": "",
        "ERP_Da_trien_khai": "",
        "ERP_Da_vuot_qua_so_luong_cho_phep": "",
        "ERP_Da_xac_nhan": "",
        "ERP_Dai_ly_tau": "",
        "ERP_Dan_toc": "民族",
        "ERP_Dang_Lam_Viec": "",
        "ERP_Dang_ky_nghi": "",
        "ERP_Dang_ky_nghi_viec": "",
        "ERP_Dang_nhap": "ログイン",
        "ERP_Dang_nhap_lai2": "",
        "ERP_Dang_xuat": "",
        "ERP_Dang_xuat1": "",
        "ERP_Danh_sach_bao_bi": "",
        "ERP_Danh_sach_bao_cao": "",
        "ERP_Danh_sach_cong_ty": "",
        "ERP_Danh_sach_cuoc_phi": "",
        "ERP_Danh_sach_doi_tuong": "",
        "ERP_Danh_sach_dot_trien_khai": "",
        "ERP_Danh_sach_giao_hang": "",
        "ERP_Danh_sach_hang_hoa": "",
        "ERP_Danh_sach_hang_hoa_chua_trien_khai": "",
        "ERP_Danh_sach_hoa_donU": "",
        "ERP_Danh_sach_hop_dong": "",
        "ERP_Danh_sach_hop_dong_ban": "",
        "ERP_Danh_sach_hop_dong_mua": "",
        "ERP_Danh_sach_lo": "",
        "ERP_Danh_sach_lot": "",
        "ERP_Danh_sach_mapping": "",
        "ERP_Danh_sach_mapping_cu": "",
        "ERP_Danh_sach_nhan_vien": "従業員のリスト",
        "ERP_Danh_sach_phu_luc_hop_dong_ban": "",
        "ERP_Danh_sach_phu_luc_hop_dong_mua": "",
        "ERP_Danh_sach_vai_tro": "",
        "ERP_Dap_ung": "",
        "ERP_De_xuat_dieu_chinh_lao_dong": "",
        "ERP_De_xuat_khen_thuong": "",
        "ERP_De_xuat_ky_luat": "",
        "ERP_Den": "まで",
        "ERP_Den_ngay": "Date to",
        "ERP_Di_cont": "",
        "ERP_Di_dong": "携帯電話",
        "ERP_Di_tau": "",
        "ERP_Dia_chi": "住所",
        "ERP_Dia_chi_lien_lac": "連絡先住所",
        "ERP_Dia_chi_tam_tru": "一時アドレス",
        "ERP_Dia_chi_thuong_tru": "本籍地",
        "ERP_Dien_giai": "説明",
        "ERP_Dien_thoai": "電話",
        "ERP_Dieu_khoan_thuong_mai": "",
        "ERP_Dieu_kien": "Filter",
        "ERP_Dieu_kien_dieu_chinh": "",
        "ERP_Dieu_kien_dieu_chinh_dot_trien_khai": "",
        "ERP_Dieu_kien_khong_duoc_de_trong": "",
        "ERP_Dinh_dang_file_khong_hop_le": "",
        "ERP_Dinh_dang_ngay_khong_dung": "",
        "ERP_Dinh_kem": "Attachments",
        "ERP_Dinh_vi_khoa": "",
        "ERP_Doanh_nghiep": "ビジネス",
        "ERP_Doi_tuong_ap_dung": "",
        "ERP_Doi_tuong_cong_phep": "Leave object",
        "ERP_Doi_tuong_lao_dong": "",
        "ERP_Doi_tuong_tinh_luong": "",
        "ERP_Don_gia": "価格",
        "ERP_Don_gia_dieu_chinh": "",
        "ERP_Don_gia_khong_duoc_de_trong": "",
        "ERP_Don_gia_thuc_te": "",
        "ERP_Don_vi_chiu_phi": "",
        "ERP_Don_vi_det_bao": "",
        "ERP_Don_vi_tinh": "",
        "ERP_Don_vi_uy_thac": "",
        "ERP_Don_vi_uy_thac_khong_duoc_giong_voi_khach_hang_da_chon": "",
        "ERP_Don_vi_uy_thac_nay_khong_duoc_giong_voi_nha_cung_cap_da_chon": "",
        "ERP_Dong1": "",
        "ERP_Dong_goi": "",
        "ERP_Dong_y": "",
        "ERP_Dot_trien_khai": "",
        "ERP_Dot_trien_khai_co_lot_da_mapping_ban_khong_the_xoa_ban_co_muon_gui_yeu_cau_xoa_mapping_khong": "",
        "ERP_Dot_trien_khai_co_lot_da_mapping_ban_khong_the_xoa_dot_trien_khai": "",
        "ERP_Du_an": "Project",
        "ERP_Du_an_cong_trinh": "",
        "ERP_Du_an_moi": "",
        "ERP_Du_lieu": "データ",
        "ERP_Du_lieu_cham_phep": "Record Leave Data",
        "ERP_Du_lieu_chua_duoc_luu_ban_co_muon_thoat_khong": "",
        "ERP_Du_lieu_chua_duoc_luu_ban_co_muon_tiep_tuc_khong": "",
        "ERP_Du_lieu_da_duoc_su_dung": "",
        "ERP_Du_lieu_da_duoc_su_dung_ban_khong_duoc_xoa": "",
        "ERP_Du_lieu_dac_biet": "",
        "ERP_Du_lieu_dau_vao_la_bat_buoc": "",
        "ERP_Du_lieu_phep_tong_hop": "",
        "ERP_Du_lieu_xuat_excel_rong": "",
        "ERP_Dung_luong_File_khong_duoc_lon_hon": "",
        "ERP_Dung_sai": "",
        "ERP_Duyet": "承認",
        "ERP_Duyet_chung": "",
        "ERP_Duyet_cuoc_phi": "",
        "ERP_Duyet_de_xuat_dieu_chuyen_lao_dong": "",
        "ERP_Duyet_de_xuat_khen_thuong": "",
        "ERP_Duyet_dieu_chinh_thu_nhap": "",
        "ERP_Duyet_hop_dong": "",
        "ERP_Duyet_khong_thanh_cong": "",
        "ERP_Duyet_ky_luat": "",
        "ERP_Duyet_nghi_phep": "",
        "ERP_Duyet_thanh_cong": "",
        "ERP_Duyet_yeu_cau_tach_lot": "",
        "ERP_Email": "メール",
        "ERP_Email_ca_nhan": "",
        "ERP_Email_ho_tro": "",
        "ERP_Email_khong_hop_le": "",
        "ERP_Ghi_chu": " 言い草",
        "ERP_Ghi_chu_deposit": "",
        "ERP_Ghi_chu_duyet": "Approve Notes",
        "ERP_Gia": "",
        "ERP_Gia_dinh": "",
        "ERP_Gia_mo_to_khai": "",
        "ERP_Gia_tri": "",
        "ERP_Gia_tri_bat_buoc_nhap": "",
        "ERP_Gia_tri_den": "",
        "ERP_Gia_tri_den_khong_de_trong": "",
        "ERP_Gia_tri_dot_trien_khai": "",
        "ERP_Gia_tri_dot_trien_khai_QD": "",
        "ERP_Gia_tri_hop_dong": "Contract amount",
        "ERP_Gia_tri_hop_dong_QD": "",
        "ERP_Gia_tri_lot": "",
        "ERP_Gia_tri_lot_QD": "",
        "ERP_Gia_tri_tu": "",
        "ERP_Gia_tri_tu_khong_de_trong": "",
        "ERP_Giam_dinh": "",
        "ERP_Giay_phep_lao_dong": "",
        "ERP_Gio_cong": "",
        "ERP_Gio_ra": "去る",
        "ERP_Gio_tu_phai_nho_hon_Gio_den": "",
        "ERP_Gio_vao": "入る",
        "ERP_Gioi_tinh": "性別",
        "ERP_Gop": "",
        "ERP_Gop_lot": "",
        "ERP_Gui": "Send",
        "ERP_Gui_yeu_cau_thanh_cong": "",
        "ERP_Gui_yeu_cau_thanh_toan": "",
        "ERP_Hai_long_can_cai_tien_them": "",
        "ERP_Hang_hoa": "",
        "ERP_Hang_hoa_can_tim": "",
        "ERP_Hang_hoa_da_lap_dot_trien_khai_ban_khong_duoc_phep_xoa": "",
        "ERP_Hanh_dong": "",
        "ERP_Hanh_vi_vi_pham_ky_luat": "",
        "ERP_He_so_quy_cach_dong_goi": "",
        "ERP_He_thong_dang_trong_qua_trinh_nang_cap": "",
        "ERP_Hien_thi_tat_ca": "",
        "ERP_Hien_thi_vai_tro_khong_con_su_dung": "",
        "ERP_Hinh_thuc": "タイプ",
        "ERP_Hinh_thuc_huong_luong": "",
        "ERP_Hinh_thuc_khen_thuong": "",
        "ERP_Hinh_thuc_ky_luat": "",
        "ERP_Hinh_thuc_lam_viec": "作業タイプ",
        "ERP_Hinh_thuc_nghi_viec": "",
        "ERP_Ho_chieu": "",
        "ERP_Ho_so_nhan_vien": "従業員ファイル",
        "ERP_Ho_tro": "",
        "ERP_Ho_va_ten": "姓名",
        "ERP_Hoan_tat": "完了",
        "ERP_Hoan_thanh": "",
        "ERP_Hoan_toan_hai_long": "",
        "ERP_Hoc_van_chuyen_mon": "",
        "ERP_Hom_nay": "今日",
        "ERP_Hom_qua": "",
        "ERP_Hop_dong": "",
        "ERP_Hop_dong_ban": "",
        "ERP_Hop_dong_chua_duyet_ban_khong_the_doi_trang_thai": "",
        "ERP_Hop_dong_co_lot_da_mapping_ban_khong_the_xoa": "",
        "ERP_Hop_dong_da_co_dot_trien_khai_ban_khong_the_xoa": "",
        "ERP_Hop_dong_da_duoc_duyet_ban_khong_the_sua": "",
        "ERP_Hop_dong_da_duoc_duyet_ban_khong_the_xoa": "",
        "ERP_Hop_dong_da_mapping_ban_khong_the_xoa": "",
        "ERP_Hop_dong_mapping": "",
        "ERP_Hop_dong_mua": "",
        "ERP_Hun_trung": "",
        "ERP_Huong_dan_su_dung": "",
        "ERP_Huy": "Cancel",
        "ERP_Huy_phep": "",
        "ERP_Huy_phep_thanh_cong": "",
        "ERP_In_bao_cao": "Print Report",
        "ERP_Keo_tap_tin_vao_day": "",
        "ERP_Keo_va_tha_tap_tin_dinh_kem_vao_day": "",
        "ERP_Ket_thuc": "",
        "ERP_KeyID_dang_rong_hoac_thieu_khong_the_tai_danh_sach_dinh_kem": "",
        "ERP_Khac": "その他",
        "ERP_Khach_hang": "顧客",
        "ERP_Khao_sat_y_kien_khach_hang": "",
        "ERP_Khi_xac_nhan_hoan_tat_ban_se_khong_duoc_sua": "",
        "ERP_Kho_dong_hang": "",
        "ERP_Khoa_dong_mapping": "",
        "ERP_Khoi_la_bat_buoc_nhap": "",
        "ERP_Khong": "いいえ",
        "ERP_Khong_co_dong_du_lieu_duoc_cap_nhat": "",
        "ERP_Khong_co_ket_noi": "",
        "ERP_Khong_co_tap_tin_dinh_kem": "",
        "ERP_Khong_co_thong_bao": "",
        "ERP_Khong_du_quyen": "",
        "ERP_Khong_duoc_nhap_trung_so_lot": "",
        "ERP_Khong_luu": "Not saved",
        "ERP_Khong_su_dung": "非活動",
        "ERP_Khong_ten": "",
        "ERP_Khong_tim_thay_trang": "",
        "ERP_Khong_trang_thai": "",
        "ERP_Kich_co_toi_da": "",
        "ERP_Kien_thuc": "",
        "ERP_Ky_luong": "",
        "ERP_Lan": "Times",
        "ERP_Lap_de_xuat_dieu_chinh_thu_nhap": "",
        "ERP_Lap_de_xuat_dieu_chuyen_lao_dong": "",
        "ERP_Lap_de_xuat_khen_thuong": "",
        "ERP_Lap_de_xuat_ky_luat": "",
        "ERP_Lap_nghi_phep": "",
        "ERP_Lap_phu_luc": "",
        "ERP_Len_tau": "",
        "ERP_Lich_su": "歴史",
        "ERP_Lich_su_tac_dong": "",
        "ERP_Lo": "",
        "ERP_Lo1": "",
        "ERP_Loai_HDLD": "",
        "ERP_Loai_bao_dong_goi": "",
        "ERP_Loai_chung_tu": "",
        "ERP_Loai_dieu_chinh": "",
        "ERP_Loai_doi_tuong_ap_dung": "",
        "ERP_Loai_gao_PO": "",
        "ERP_Loai_gao_SO": "",
        "ERP_Loai_hoa_don": "",
        "ERP_Loai_hop_dong": "契約形式",
        "ERP_Loai_nghiep_vu": "",
        "ERP_Loai_phep": "Leave Types",
        "ERP_Loai_phu_luc": "",
        "ERP_Loai_quy_trinh": "",
        "ERP_Loai_tap_tin_ho_tro": "",
        "ERP_Loai_thu_nhap": "",
        "ERP_Loai_tien": "通貨",
        "ERP_Loc_chon_hanh_dong": "",
        "ERP_Loi_chua_xac_dinh": "",
        "ERP_Loi_khong_lay_duoc_token_CDN": "",
        "ERP_Lot_da_mapping_khong_the_xoa_ban_co_muon_gui_yeu_cau_xoa_mapping_khong": "",
        "ERP_Lot_da_tach": "",
        "ERP_Lot_yeu_cau_tach": "",
        "ERP_Luu": "保存",
        "ERP_Luu_dinh_kem_khong_thanh_cong": "",
        "ERP_Luu_khong_thanh_cong": "",
        "ERP_Luu_lich_su_khong_thanh_cong": "",
        "ERP_Luu_thanh_cong": "",
        "ERP_Luu_thong_tin_chung_tu_khong_thanh_cong": "",
        "ERP_Luu_va_nhap_tiep": "",
        "ERP_Ly_do": "理由",
        "ERP_Ly_do_huy": "",
        "ERP_Ma": "Code",
        "ERP_Ma_HDLD": "",
        "ERP_Ma_LOT": "",
        "ERP_Ma_Lo_da_ton_tai": "",
        "ERP_Ma_NV": "従業員コード",
        "ERP_Ma_booking": "",
        "ERP_Ma_cham_cong": "",
        "ERP_Ma_chi_tiet_bao_bi": "",
        "ERP_Ma_chi_tiet_giao_hang": "",
        "ERP_Ma_co_cau": "",
        "ERP_Ma_cong_ty": "",
        "ERP_Ma_detail_cua_hop_dong_mua": "",
        "ERP_Ma_doi_tuong": "オブジェクトコード",
        "ERP_Ma_dot_trien_khai": "",
        "ERP_Ma_hang": "アイテムコード",
        "ERP_Ma_hang_PO": "",
        "ERP_Ma_hang_SO": "",
        "ERP_Ma_hang_hop_dong_ban": "",
        "ERP_Ma_hang_hop_dong_mua": "",
        "ERP_Ma_hop_dong": "",
        "ERP_Ma_khoa_cua_dot_trien_khai": "",
        "ERP_Ma_khoa_dong_hop_dong": "",
        "ERP_Ma_khoa_dong_mapping": "",
        "ERP_Ma_khoa_dot_trien_khai": "",
        "ERP_Ma_khoa_hop_dong": "",
        "ERP_Ma_khoa_hop_dong_ban": "",
        "ERP_Ma_khoa_hop_dong_mua": "",
        "ERP_Ma_khoa_lot": "",
        "ERP_Ma_lo": "",
        "ERP_Ma_loai_chung_tu": "",
        "ERP_Ma_lot_cua_dot_trien_khai": "",
        "ERP_Ma_mapping": "",
        "ERP_Ma_nguoi_dung": "ユーザーID",
        "ERP_Ma_nhan_vien": "従業員コード",
        "ERP_Ma_nhan_vien_phu": "",
        "ERP_Ma_phuong_thuc_thanh_toan": "",
        "ERP_Ma_so_thue": "",
        "ERP_Ma_tau": "",
        "ERP_Ma_the_cham_cong": "",
        "ERP_Ma_thong_tin_thanh_toan": "",
        "ERP_Ma_trien_khai": "",
        "ERP_Ma_vai_tro": "",
        "ERP_Man_hinh": "",
        "ERP_Mapping": "",
        "ERP_Mapping_cu": "",
        "ERP_Mat_hang": "Item",
        "ERP_Mat_hang_khong_duoc_de_trong": "",
        "ERP_Mat_khau": "パスワード",
        "ERP_Mau_bao_cao": "レポートコード",
        "ERP_Mau_bao_cao_khong_duoc_tim_thay": "",
        "ERP_Mau_giao_dien": "",
        "ERP_Mo_rong": "",
        "ERP_Mo_ta": "",
        "ERP_Muc_do_dong_y": "",
        "ERP_NVA_nghiep_vu_a": "",
        "ERP_Nam_nay": "",
        "ERP_Nam_truoc": "",
        "ERP_Ngan_hang": "",
        "ERP_Ngay": "年月日 \t",
        "ERP_Ngay_bat_dau": "開始日",
        "ERP_Ngay_cap": "発行日",
        "ERP_Ngay_cap_CMND": "",
        "ERP_Ngay_cham_cong": "",
        "ERP_Ngay_cong": "",
        "ERP_Ngay_dang_ky_tu_khong_duoc_lon_hon_ngay_dang_ky_den": "",
        "ERP_Ngay_danh_gia": "",
        "ERP_Ngay_danh_gia_bat_buoc_nhap": "",
        "ERP_Ngay_de_xuat": "Proposal Date",
        "ERP_Ngay_hieu_luc": "有効な日付",
        "ERP_Ngay_hieu_luc_den": "",
        "ERP_Ngay_hieu_luc_tu": "",
        "ERP_Ngay_in": "",
        "ERP_Ngay_ket_thuc": "終了日",
        "ERP_Ngay_ky": "調印日",
        "ERP_Ngay_lap": "Created Date",
        "ERP_Ngay_nghi": "",
        "ERP_Ngay_nghi_viec": "",
        "ERP_Ngay_nhan": "Received Date",
        "ERP_Ngay_phep": "Leave date",
        "ERP_Ngay_phieu": "バウチャー日",
        "ERP_Ngay_phun": "",
        "ERP_Ngay_sinh": "誕生日",
        "ERP_Ngay_tac_dong": "",
        "ERP_Ngay_tao": "",
        "ERP_Ngay_tau_di": "",
        "ERP_Ngay_tau_vao": "",
        "ERP_Ngay_tham_gia_bao_hiem": "",
        "ERP_Ngay_thuc_te_booking": "",
        "ERP_Ngay_tu_khong_duoc_lon_hon_ngay_den": "",
        "ERP_Ngay_vao": "開始日",
        "ERP_Ngay_vao_lam": "開始日",
        "ERP_Nghi_phep": "",
        "ERP_Nghi_viec": "",
        "ERP_Nghiep_vu": "",
        "ERP_Nghiep_vu_da_co_du_lieu": "",
        "ERP_Nguoi_dai_dien": "Agency",
        "ERP_Nguoi_danh_gia": "",
        "ERP_Nguoi_danh_gia_bat_buoc_nhap": "",
        "ERP_Nguoi_dung_khong_su_dung": "",
        "ERP_Nguoi_duyet": "承認者",
        "ERP_Nguoi_lap": "によって作成された",
        "ERP_Nguoi_lapU": "",
        "ERP_Nguoi_lien_he": "",
        "ERP_Nguoi_nuoc_ngoai": "",
        "ERP_Nguoi_phu_trach": "",
        "ERP_Nguoi_quan_ly_truc_tiep": "Direct Manager",
        "ERP_Nguoi_tao": "",
        "ERP_Nguoi_uy_quyen": "",
        "ERP_Nha_cung_cap": "Supplier",
        "ERP_Nhan_mac": "",
        "ERP_Nhan_vien": "",
        "ERP_Nhap": "",
        "ERP_Nhap_Ten_Dang_Nhap": "Enter username",
        "ERP_Nhap_mat_khau": "パスワードを入力",
        "ERP_Nhap_tiep": "",
        "ERP_Nhap_tu_tim_kiem": "",
        "ERP_Nho_mat_khau": "",
        "ERP_Nho_mat_khau1": "",
        "ERP_Nhom_hanh_vi_vi_pham_ky_luat": "",
        "ERP_Nhom_luong": "",
        "ERP_Nhom_nhan_vien": "",
        "ERP_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "",
        "ERP_No": "借方",
        "ERP_No_data": "",
        "ERP_No_link": "",
        "ERP_Noi_cap": "発行場所",
        "ERP_Noi_cap_CMND": "",
        "ERP_Noi_dung": "内容",
        "ERP_Noi_dung_can_tim_kiem": "",
        "ERP_Noi_sinh": "出生地",
        "ERP_O_tab": "",
        "ERP_Phai_thuc_hien_phan_bo_cac_dong_chi_tiet_bao_bi_va_chi_tiet_giao_hang": "",
        "ERP_Phan_bo_chi_tiet_giao_hang": "",
        "ERP_Phan_bo_mapping_tu_yeu_cau_tach_lot": "",
        "ERP_Phan_bo_thong_tin_chi_tiet_giao_hang": "",
        "ERP_Phan_quyen": "",
        "ERP_Phan_quyen_chuc_nang": "",
        "ERP_Phan_quyen_du_lieu": "",
        "ERP_Phan_quyen_theo_vai_tro": "",
        "ERP_Phep": "休暇",
        "ERP_Phien_ban": "",
        "ERP_Phieu_cham_cong": "",
        "ERP_Phieu_dang_ky_da_duoc_duyet_ban_khong_duoc_phep_thay_doi": "",
        "ERP_Phong_ban": "部門",
        "ERP_Phong_ban_moi": "",
        "ERP_Phu_luc_da_duoc_duyet": "",
        "ERP_Phu_luc_da_duoc_duyet_ban_khong_duoc_sua": "",
        "ERP_Phu_luc_da_duoc_duyet_ban_khong_duoc_xoa": "",
        "ERP_Phu_luc_hop_dong": "",
        "ERP_Phu_trach_chung_tu": "",
        "ERP_Phun_trung": "",
        "ERP_Phuong_thuc": "",
        "ERP_Phuong_thuc_da_ton_tai": "",
        "ERP_Phuong_thuc_khong_duoc_de_trong": "",
        "ERP_Phuong_thuc_thanh_toan": "支払方法",
        "ERP_Phuong_tien": "",
        "ERP_Phuong_tien_van_chuyen": "",
        "ERP_Phuong_tien_van_chuyen_bat_buoc_nhap": "",
        "ERP_Quan_he": "関係",
        "ERP_Quan_he_gia_dinh": "家族関係",
        "ERP_Quan_ly": "",
        "ERP_Quoc_gia": "",
        "ERP_Quoc_tich": "",
        "ERP_Quy_cach_dong_goi": "",
        "ERP_Quy_uoc_nhu_sau": "",
        "ERP_Quyen_han": "",
        "ERP_SDT_ho_tro": "",
        "ERP_STT": "次",
        "ERP_SXDong_goi": "",
        "ERP_Sao_chep": "",
        "ERP_Sinh_nhat": "",
        "ERP_So_BL": "",
        "ERP_So_Fax": "",
        "ERP_So_bao_hiem": "",
        "ERP_So_chi_tiet_bao_bi": "",
        "ERP_So_chi_tiet_giao_hang": "",
        "ERP_So_dien_thoai": "Tel no.",
        "ERP_So_dien_thoai_khong_hop_le": "",
        "ERP_So_dong": "",
        "ERP_So_hoa_don": "",
        "ERP_So_hop_dong": "Contract No",
        "ERP_So_hop_dong_ban": "",
        "ERP_So_hop_dong_da_ton_tai": "",
        "ERP_So_hop_dong_mua": "",
        "ERP_So_lan_khu_trung": "",
        "ERP_So_lot": "",
        "ERP_So_luong": "数量",
        "ERP_So_luong_QD": "",
        "ERP_So_luong_bao": "",
        "ERP_So_luong_bao_yeu_cau": "",
        "ERP_So_luong_bao_yeu_cau_mapping": "",
        "ERP_So_luong_bao_yeu_cau_vua_nhap_khong_hop_le": "",
        "ERP_So_luong_cho_phep": "",
        "ERP_So_luong_chua_trien_khai": "",
        "ERP_So_luong_co_the_mapping": "",
        "ERP_So_luong_con_lai": "Remain Quantity",
        "ERP_So_luong_con_lai_cua_hop_dong_mua_phai_bang_0": "",
        "ERP_So_luong_con_lai_dong_cho_sa_lan_toi_cuoi_ngay_nhap_lieu": "",
        "ERP_So_luong_da_giao": "",
        "ERP_So_luong_da_giao_vua_nhap_khong_hop_le": "",
        "ERP_So_luong_da_mapping": "",
        "ERP_So_luong_da_nghi_den_ky_hien_tai": "",
        "ERP_So_luong_da_thuc_hien": "",
        "ERP_So_luong_da_trien_khai": "",
        "ERP_So_luong_dang_trien_khai_la": "",
        "ERP_So_luong_di_cua_booking": "",
        "ERP_So_luong_duoc_cap": "Quantity",
        "ERP_So_luong_duoc_nhap_nho_hon_tong_so_luong_da_giao": "",
        "ERP_So_luong_huy1": "",
        "ERP_So_luong_khong_duoc_bang_0": "",
        "ERP_So_luong_khong_duoc_de_trong": "",
        "ERP_So_luong_khong_duoc_vuot_qua": "",
        "ERP_So_luong_khong_hop_le": "",
        "ERP_So_luong_lot": "",
        "ERP_So_luong_lot_tach": "",
        "ERP_So_luong_phuong_tien_da_dong": "",
        "ERP_So_luong_phuong_tien_da_dong_goi": "",
        "ERP_So_luong_thuc_hien_cua_mapping": "",
        "ERP_So_luong_thuc_hien_mapping": "",
        "ERP_So_luong_ton_den_cuoi_nam": "Leave Quantity To End Year",
        "ERP_So_luong_ton_den_ky_hien_tai": "",
        "ERP_So_luong_tong_cua_dot_trien_khai": "",
        "ERP_So_luong_vua_nhap_nho_hon_so_luong_dang_trien_khai": "",
        "ERP_So_luong_vua_nhap_nho_hon_so_luong_dang_trien_khai_ban_co_muon_chinh_sua_so_luong_dang_trien_khai_cua_hang_hoa_nay": "",
        "ERP_So_luong_yeu_cau": "",
        "ERP_So_luong_yeu_cau_cua_mapping": "",
        "ERP_So_luong_yeu_cau_mapping": "",
        "ERP_So_mapping": "",
        "ERP_So_phieu": "バウチャー番号",
        "ERP_So_phu_luc": "",
        "ERP_So_tai_khoan": "",
        "ERP_So_thang": "月数",
        "ERP_So_thu_tu": "",
        "ERP_So_tien_thanh_toan": "",
        "ERP_So_tien_thuc_te": "",
        "ERP_So_tien_yeu_cau": "",
        "ERP_Su_dung": "",
        "ERP_Sua": "Edit",
        "ERP_Sua_khac": "",
        "ERP_Tach": "分割",
        "ERP_Tach_lot": "",
        "ERP_Tai_khoan": "口座",
        "ERP_Tai_khoan_bi_khoa_10_phut": "",
        "ERP_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "",
        "ERP_Tai_khoan_khong_co_quyen": "",
        "ERP_Tai_lieu": "",
        "ERP_Tai_trong": "",
        "ERP_Tai_trong_phuong_tien": "",
        "ERP_Tam_dap_ung_Can_cai_tien_them": "",
        "ERP_Tap_tin": "",
        "ERP_Tap_tin_dinh_kem_dang_duoc_xu_ly": "",
        "ERP_Tap_tin_tai_len": "",
        "ERP_Tat_ca": "",
        "ERP_Tat_ca_cac_lot_phai_ket_thuc_khi_ket_thuc_dot_trien_khai": "",
        "ERP_Ten": "名前",
        "ERP_Ten_Dang_Nhap": "TÊN ĐĂNG NHẬP",
        "ERP_Ten_cong_ty": "",
        "ERP_Ten_dieu_chinh_chung_tu_cua_lot": "",
        "ERP_Ten_doi_tuong": "オブジェクト名",
        "ERP_Ten_hang": "アイテム名",
        "ERP_Ten_hang_hop_dong_ban": "",
        "ERP_Ten_hang_hop_dong_mua": "",
        "ERP_Ten_hang_khong_duoc_de_trong": "",
        "ERP_Ten_hang_thuc_te": "",
        "ERP_Ten_lo": "",
        "ERP_Ten_man_hinh": "スクリーンネーム",
        "ERP_Ten_nguoi_dung": "ユーザー名",
        "ERP_Ten_nguoi_quan_he": "",
        "ERP_Ten_nhan_vien": "",
        "ERP_Ten_phong_ban": "部署名",
        "ERP_Ten_tau": "",
        "ERP_Ten_thong_tin_thanh_toan": "",
        "ERP_Ten_vai_tro": "",
        "ERP_Tham_nien": "年功序列",
        "ERP_Tham_so_truyen_vao_khong_hop_le": "",
        "ERP_Thang_nay": "今月",
        "ERP_Thang_truoc": "",
        "ERP_Thanh_tien": "Amount",
        "ERP_Thanh_tien_QD": "",
        "ERP_Thanh_tien_deposit": "",
        "ERP_Thanh_tien_deposit_QD": "",
        "ERP_Thanh_toan": "支払い",
        "ERP_Thanh_vien_cua_Nihon_Grouping_va_Pan": "",
        "ERP_Thay_doi_hop_dong_se_lam_thay_doi_du_lieu": "",
        "ERP_The_can_cuoc": "",
        "ERP_The_luu_tru": "",
        "ERP_Them": "Add",
        "ERP_Them_chi_tiet_giao_hang_vuot_qua_so_luong_mapping": "",
        "ERP_Them_dieu_kien": "",
        "ERP_Them_dot_trien_khai": "",
        "ERP_Them_hang_hoa": "",
        "ERP_Them_lo": "",
        "ERP_Them_mapping": "",
        "ERP_Them_moi": "",
        "ERP_Them_moi1": "新しく作る",
        "ERP_Them_moi_lo": "",
        "ERP_Thoi_gian": "時間",
        "ERP_Thoi_gian_bat_dau_dong_hang": "",
        "ERP_Thoi_gian_den_cang": "",
        "ERP_Thoi_gian_dong_hang": "",
        "ERP_Thoi_gian_giao_bao": "",
        "ERP_Thoi_gian_giao_hang": "",
        "ERP_Thoi_gian_giao_hang_den_ngay": "",
        "ERP_Thoi_gian_giao_hang_du_kien": "",
        "ERP_Thoi_gian_giao_hang_du_kien_cua_dot_trien_khai": "",
        "ERP_Thoi_gian_giao_hang_du_kien_den": "",
        "ERP_Thoi_gian_giao_hang_du_kien_tu": "",
        "ERP_Thoi_gian_giao_hang_theo_lot": "",
        "ERP_Thoi_gian_giao_hang_tu_ngay": "",
        "ERP_Thoi_gian_hieu_luc": "",
        "ERP_Thoi_gian_ket_thuc_dong_hang": "",
        "ERP_Thoi_gian_ra_vao_VN": "",
        "ERP_Thong_bao": "Announcement",
        "ERP_Thong_bao1": "",
        "ERP_Thong_baoU": "",
        "ERP_Thong_bao_giao_hang": "",
        "ERP_Thong_bao_lot_da_duoc_mapping": "",
        "ERP_Thong_bao_lot_da_mapping": "",
        "ERP_Thong_bao_xac_nhan_chung_tu": "",
        "ERP_Thong_tin_bao_bi": "",
        "ERP_Thong_tin_ca_nhan": "個人情報",
        "ERP_Thong_tin_chinh": "",
        "ERP_Thong_tin_chung": "一般的な情報",
        "ERP_Thong_tin_cu": "",
        "ERP_Thong_tin_cung_cap_khong_hop_le": "",
        "ERP_Thong_tin_dong_goi": "",
        "ERP_Thong_tin_giao_hang": "",
        "ERP_Thong_tin_hoa_don": "",
        "ERP_Thong_tin_huy": "",
        "ERP_Thong_tin_khac": "",
        "ERP_Thong_tin_lien_lac": "連絡先",
        "ERP_Thong_tin_mapping": "",
        "ERP_Thong_tin_nguoi_dung": "",
        "ERP_Thong_tin_phan_quyen": "",
        "ERP_Thong_tin_phu": "",
        "ERP_Thong_tin_phu_luc": "",
        "ERP_Thong_tin_tang_nhan_vien": "",
        "ERP_Thong_tin_thanh_toan": "",
        "ERP_Thong_tin_thanh_toan_cua_lot": "",
        "ERP_Thong_tin_thanh_toan_lot": "",
        "ERP_Thong_tin_vai_tro": "",
        "ERP_Thuc_hien": "",
        "ERP_Thuc_hien_mapping": "",
        "ERP_Thuc_hien_thanh_cong": "",
        "ERP_Tieu_chuan_chat_luong": "",
        "ERP_Tim_doi_tuong": "",
        "ERP_Tim_kiem": "",
        "ERP_Tim_kiem_cham_cong": "",
        "ERP_Tim_kiem_cong_ty": "会社を探す",
        "ERP_Tim_kiem_doi_tuong": "",
        "ERP_Tim_kiem_hang_hoa": "",
        "ERP_Tim_kiem_ho_so_nhan_su": "",
        "ERP_Tim_kiem_hop_dong": "",
        "ERP_Tim_kiem_ten_nhan_vien_va_ma_nhan_vien": "",
        "ERP_Tinh_trang_hien_tai": "",
        "ERP_Tinh_trang_hoan_thanh": "",
        "ERP_Tinh_trang_hon_nhan": "婚姻状況",
        "ERP_To_chuc": "組織",
        "ERP_To_nhom": " 組",
        "ERP_To_nhom_moi": "",
        "ERP_Toan_tu": "",
        "ERP_Toan_tu_khong_duoc_de_trong": "",
        "ERP_Ton_giao": "宗教",
        "ERP_Tong": "",
        "ERP_Tong_diem": "",
        "ERP_Tong_gia_tri_hoa_don": "",
        "ERP_Tong_gia_tri_hoa_don_QD": "",
        "ERP_Tong_gia_tri_hop_dong": "Total contract amount",
        "ERP_Tong_gia_tri_hop_dong_QD": "",
        "ERP_Tong_so_luong": "",
        "ERP_Tong_so_luong_cac_lot_phai_bang_so_luong_cua_lot_tach": "",
        "ERP_Tong_so_luong_chi_tiet_giao_hang_duoc_phan_bo_cua_mapping": "",
        "ERP_Tong_so_luong_dong_goi_cua_cac_mapping_phai_bang_so_luong_dong_goi_cua_chi_tiet_giao_hang": "",
        "ERP_Tong_so_luong_dong_goi_cua_cac_mapping_vuot_qua_so_luong_dong_goi_cua_chi_tiet_giao_hang": "",
        "ERP_Tong_so_ngay": "",
        "ERP_Trang": "",
        "ERP_Trang_Chu": "ホームページ",
        "ERP_Trang_thai": "ステータス",
        "ERP_Trang_thai_Phu_luc_hop_dong": "",
        "ERP_Trang_thai_chi_tiet_giao_hang": "",
        "ERP_Trang_thai_cu": "",
        "ERP_Trang_thai_deposit": "",
        "ERP_Trang_thai_duyet": "承認状況",
        "ERP_Trang_thai_duyet_Hop_dong": "",
        "ERP_Trang_thai_hoa_don_": "",
        "ERP_Trang_thai_hoan_tat_ban_khong_duoc_phep_xoa": "",
        "ERP_Trang_thai_hop_dong": "",
        "ERP_Trang_thai_lam_viec": "",
        "ERP_Trang_thai_moi": "",
        "ERP_Trang_thai_phuong_tien": "",
        "ERP_Trinh_do_hoc_van": "教育レベル",
        "ERP_Trinh_do_van_hoaU": "",
        "ERP_Tro_giup": "",
        "ERP_Trong_luong_Gross": "",
        "ERP_Trong_luong_bao_bi": "",
        "ERP_Trong_luong_cont": "",
        "ERP_Truong_nay_bat_buoc_nhap": "",
        "ERP_Truong_nay_khong_hop_le": "",
        "ERP_Truong_ngay_la_bat_buoc": "",
        "ERP_Truy_cap_khong_hop_le": "",
        "ERP_Truy_van_tang_nhan_vien": "",
        "ERP_Truy_van_thong_tin_cong_phep": "",
        "ERP_Tu": "から",
        "ERP_Tu_choi": "拒絶",
        "ERP_Tu_choi_W75": "",
        "ERP_Tu_choi_khong_thanh_cong": "",
        "ERP_Tu_choi_thanh_cong": "",
        "ERP_Tu_ngay": "Date from",
        "ERP_Tuan_nay": "Konshū",
        "ERP_Tuan_truoc": "",
        "ERP_Tuoi": "年齢",
        "ERP_Tuyen_dung": "募集",
        "ERP_Ty_gia": "為替レート",
        "ERP_Ty_le_deposit": "",
        "ERP_URL": "",
        "ERP_URL_khong_hop_le": "",
        "ERP_Username_va_Password_khong_hop_le!": "",
        "ERP_Vai_tro": "",
        "ERP_Van_chuyen_thanh_toan": "",
        "ERP_VoucherID_khong_duoc_de_trong": "",
        "ERP_Vui_long_chon_hang_hoa": "",
        "ERP_Vui_long_nhap_so_luong_va_gia_hang_hoa": "",
        "ERP_Vui_long_truy_cap_sau": "",
        "ERP_Vuot_qua_so_luong_tap_tin_cho_phep": "",
        "ERP_Xac_nhan": "Confirm",
        "ERP_Xac_nhan_giao_hang": "",
        "ERP_Xac_nhan_nhan_vien_nhan_viec_tai_du_an": "",
        "ERP_Xac_nhan_so_luong_da_giao": "",
        "ERP_Xac_nhan_tat_ca": "",
        "ERP_Xac_nhan_thanh_cong": "",
        "ERP_Xem": "表示",
        "ERP_Xem_chi_tiet": "View Detail",
        "ERP_Xem_hop_dong_goc": "",
        "ERP_Xem_tap_tin": "",
        "ERP_Xem_tat_ca": "",
        "ERP_Xem_them": "",
        "ERP_Xem_truoc": "",
        "ERP_Xin_cam_on": "",
        "ERP_Xoa": "Delete",
        "ERP_Xoa_dong": "",
        "ERP_Xoa_du_lieu": "",
        "ERP_Xoa_tam": "",
        "ERP_Xoa_thanh_cong": "",
        "ERP_Xuat": "",
        "ERP_Xuat_Excel": "",
        "ERP_Xuat_PDF": "",
        "ERP_Xuat_bao_cao": "レポートのエクスポート",
        "ERP_Xuat_du_lieu": "",
        "ERP_Xuat_du_lieu_thanh_cong": "",
        "ERP_Xuat_mau_in_xac_nhan_giao_hang": "",
        "ERP_Xuat_thong_bao_giao_hang": "",
        "ERP_Xuat_word": "",
        "ERP_Y_kien_dong_gop": "",
        "ERP_Yes": "",
        "ERP_Yeu_cau_tach_lot": "",
        "ERP_Yeu_cau_thanh_toan": "",
        "ERP_dang_nhap_lai": "再度ログオンを",
        "ERP_dong": "",
        "ERP_khong_co_du_lieu": "",
        "ERP_trong": ""
    },
    "zh": {
        "ERP_403_loi": "",
        "ERP_404_loi": "",
        "ERP_AC_vui_long_chon_y_kien_cua_minh": "",
        "ERP_Ban_can_nhap_hang_hoa_cho_hoa_don": "",
        "ERP_Ban_chac_chan_muon_sao_chep_cot_gia_tri_nay?": "",
        "ERP_Ban_chi_duoc_dieu_chinh_giam_0_5_phep": "",
        "ERP_Ban_chi_duoc_phep_sua_so_luong_lon_hon_hoac_bang_so_luong_da_trien_khai_la": "",
        "ERP_Ban_chua_trien_khai_hop_dong_ban_khong_duoc_ket_thuc_hop_dong": "",
        "ERP_Ban_co_chac_chan_muon_xac_nhan_chi_tiet_giao_hang_nay_khong": "",
        "ERP_Ban_co_chac_chan_muon_xac_nhan_tat_ca_cac_chi_tiet_giao_hang_khong": "",
        "ERP_Ban_co_chac_chan_muon_xoa_du_lieu_nay": "",
        "ERP_Ban_co_chac_chan_muon_xoa_thong_tin_thanh_toan_nay_khong": "",
        "ERP_Ban_co_chac_muon_bo_trang_thai_nay?": "",
        "ERP_Ban_co_chac_muon_duyet": "",
        "ERP_Ban_co_chac_muon_huy_phep?": "",
        "ERP_Ban_co_chac_muon_luu_khong?": "",
        "ERP_Ban_co_chac_muon_xoa?": "",
        "ERP_Ban_co_muon_chuyen_trang_thai_chung_tu": "",
        "ERP_Ban_co_muon_tiep_tuc_sua": "",
        "ERP_Ban_co_muon_xoa_du_lieu": "",
        "ERP_Ban_da_check_hoan_tat_chung_tu_nay": "",
        "ERP_Ban_da_check_hoan_thanh_chung_tu_nay": "",
        "ERP_Ban_da_chon_phuong_thuc_nay": "",
        "ERP_Ban_giao": "",
        "ERP_Ban_khong_co_quyen_duyet": "",
        "ERP_Ban_khong_duoc_sua_so_luong_hang_hoa_nho_hon_so_luong_dang_trien_khai_la": "",
        "ERP_Ban_khong_duoc_xoa_hang_hoa_da_trien_khai": "",
        "ERP_Ban_khong_the_luu": "",
        "ERP_Ban_phai_check_su_dung_truoc": "",
        "ERP_Ban_phai_chon_doanh_nghiep": "",
        "ERP_Bao_cao": "",
        "ERP_Bao_cao_cong": "",
        "ERP_Bao_yeu_cau": "",
        "ERP_Bat_buoc": "",
        "ERP_Bat_buoc_nhap_du_lieu": "",
        "ERP_Bat_dau": "",
        "ERP_Bo_duyet": "",
        "ERP_Bo_xac_nhan": "",
        "ERP_Boi": "",
        "ERP_CDN_chua_san_sang": "",
        "ERP_CMND": "",
        "ERP_Ca": "班次",
        "ERP_Ca_nhan": "Personal",
        "ERP_Cac_dot_trien_khai_chua_ket_thuc_ban_khong_the_ket_thuc_hop_dong": "",
        "ERP_Cac_gop_y_khac": "",
        "ERP_Cac_tham_so_la_bat_buoc": "",
        "ERP_Cac_tieu_chi_la_bat_buoc_nhap": "",
        "ERP_Cac_truong_khong_bang_nhau": "",
        "ERP_Cai_dat": "",
        "ERP_Cang_den": "",
        "ERP_Cang_di": "",
        "ERP_Cap": "级",
        "ERP_Cap_co_cau": "",
        "ERP_Cap_khen_thuong": "",
        "ERP_Cap_ky_luat": "",
        "ERP_Cap_nhat_Lo": "",
        "ERP_Cap_nhat_chung_tu": "",
        "ERP_Cap_nhat_cuoc_phi": "",
        "ERP_Cap_nhat_thong_tin_huy": "",
        "ERP_Cap_nhat_trang_thai": "",
        "ERP_Cap_nhat_trang_thai_duyet": "",
        "ERP_Cap_nhat_trang_thai_duyet_phu_luc": "",
        "ERP_Cap_nhat_trang_thai_hoa_don": "",
        "ERP_Cap_nhat_trang_thai_hop_dong_lao_dong": "",
        "ERP_Cham_cong_chi_tiet": "",
        "ERP_Cham_cong_theo_du_an": "",
        "ERP_Chao_buoi_sang": "",
        "ERP_Chao_mung_login": "",
        "ERP_Chi_phi_huy": "",
        "ERP_Chi_tiet": "细节",
        "ERP_Chi_tiet_bao_bi": "",
        "ERP_Chi_tiet_can_phan_bo": "",
        "ERP_Chi_tiet_dieu_kien": "",
        "ERP_Chi_tiet_dot_trien_khai": "",
        "ERP_Chi_tiet_giao_hang": "",
        "ERP_Chi_tiet_hoa_don": "",
        "ERP_Chi_tiet_hop_dong": "",
        "ERP_Chi_tiet_hop_dong_ban": "",
        "ERP_Chi_tiet_hop_dong_mua": "",
        "ERP_Chi_tiet_hop_dong_trong_mapping": "",
        "ERP_Chi_tiet_mapping": "",
        "ERP_Chi_tiet_nhan_vien": "",
        "ERP_Chi_tiet_phep": "",
        "ERP_Chi_tiet_phu_luc_hop_dong_ban": "",
        "ERP_Chi_tiet_phu_luc_hop_dong_mua": "",
        "ERP_Chinh_sua": "",
        "ERP_Chinh_tri": "",
        "ERP_Cho_duyet": "待批准",
        "ERP_Chon": "Select",
        "ERP_Chon_ban_giao": "",
        "ERP_Chon_co_cau_to_chuc": "",
        "ERP_Chon_du_an": "",
        "ERP_Chon_hop_dong": "",
        "ERP_Chon_hop_dong_lap_phu_luc": "",
        "ERP_Chon_ky": "",
        "ERP_Chon_ky_luong": "",
        "ERP_Chon_mapping": "",
        "ERP_Chon_mau_bao_cao": "",
        "ERP_Chon_ngay": "",
        "ERP_Chon_nhan_vien": "",
        "ERP_Chon_nhom_luong": "",
        "ERP_Chon_thoi_gian": "",
        "ERP_Chon_trang_thai": "",
        "ERP_Chon_trang_thai_duyet": "",
        "ERP_Chu_de": "",
        "ERP_Chua_chon_hang_hoa": "",
        "ERP_Chua_co_lien_ket_nao": "",
        "ERP_Chua_dap_ung": "",
        "ERP_Chua_duyet": "",
        "ERP_Chua_hoan_thanh": "",
        "ERP_Chua_xac_nhan": "",
        "ERP_Chuc_danh": "",
        "ERP_Chuc_danh_cong_viec": "",
        "ERP_Chuc_vu": "位置",
        "ERP_Chuc_vu_bat_buoc_nhap": "",
        "ERP_Chung_minh_nhan_dan": "",
        "ERP_Chung_tu": "",
        "ERP_Chung_tu_da_hoan_thanh_khong_the_sua": "",
        "ERP_Chung_tu_da_hoan_thanh_khong_the_xoa": "",
        "ERP_Chuyen_doi_trang_thai_LOT": "",
        "ERP_Chuyen_mapping": "",
        "ERP_Chuyen_mon": "",
        "ERP_Chuyen_nganh": "",
        "ERP_Chuyen_trang_thai_Lot": "",
        "ERP_Co1": "",
        "ERP_Co_cau_cha": "",
        "ERP_Co_cau_to_chuc": "",
        "ERP_Co_hop_dong_ban_chua_thuc_hien_mapping": "",
        "ERP_Co_loi_khi_luu": "",
        "ERP_Co_loi_xay_ra_trong_qua_trinh_xu_ly": "",
        "ERP_Co_so_luong_ban_khong_duoc_phep_xoa": "",
        "ERP_Con_dong_chua_duoc_nhap_lieu/Ban_co_muon_luu_khong?": "",
        "ERP_Con_dong_chua_duoc_nhap_lieu_Ban_co_muon_luu_khong": "",
        "ERP_Cong_trinh/Cong_ty": "",
        "ERP_Cong_ty": "公司",
        "ERP_Cong_ty_bao_hiem": "",
        "ERP_Cong_ty_phun": "",
        "ERP_Cong_viec": "工作",
        "ERP_Cong_viec_moi": "",
        "ERP_Cua_lot": "",
        "ERP_DHR_Ban_chac_chan_muon_sao_chep_cot_gia_tri_nay?": "",
        "ERP_DHR_Gia_tri": "",
        "ERP_DHR_Xoa": "",
        "ERP_Da_duyet": "已批准",
        "ERP_Da_hoan_tat": "",
        "ERP_Da_ket_noi": "",
        "ERP_Da_mapping": "",
        "ERP_Da_nghi_viec": "",
        "ERP_Da_sao_chep": "",
        "ERP_Da_trien_khai": "",
        "ERP_Da_vuot_qua_so_luong_cho_phep": "",
        "ERP_Da_xac_nhan": "",
        "ERP_Dai_ly_tau": "",
        "ERP_Dan_toc": "民族",
        "ERP_Dang_Lam_Viec": "",
        "ERP_Dang_ky_nghi": "",
        "ERP_Dang_ky_nghi_viec": "",
        "ERP_Dang_nhap": "登录",
        "ERP_Dang_nhap_lai2": "",
        "ERP_Dang_xuat": "",
        "ERP_Dang_xuat1": "",
        "ERP_Danh_sach_bao_bi": "",
        "ERP_Danh_sach_bao_cao": "",
        "ERP_Danh_sach_cong_ty": "",
        "ERP_Danh_sach_cuoc_phi": "",
        "ERP_Danh_sach_doi_tuong": "",
        "ERP_Danh_sach_dot_trien_khai": "",
        "ERP_Danh_sach_giao_hang": "",
        "ERP_Danh_sach_hang_hoa": "",
        "ERP_Danh_sach_hang_hoa_chua_trien_khai": "",
        "ERP_Danh_sach_hoa_donU": "",
        "ERP_Danh_sach_hop_dong": "",
        "ERP_Danh_sach_hop_dong_ban": "",
        "ERP_Danh_sach_hop_dong_mua": "",
        "ERP_Danh_sach_lo": "",
        "ERP_Danh_sach_lot": "",
        "ERP_Danh_sach_mapping": "",
        "ERP_Danh_sach_mapping_cu": "",
        "ERP_Danh_sach_nhan_vien": "雇员名单",
        "ERP_Danh_sach_phu_luc_hop_dong_ban": "",
        "ERP_Danh_sach_phu_luc_hop_dong_mua": "",
        "ERP_Danh_sach_vai_tro": "",
        "ERP_Dap_ung": "",
        "ERP_De_xuat_dieu_chinh_lao_dong": "",
        "ERP_De_xuat_khen_thuong": "",
        "ERP_De_xuat_ky_luat": "",
        "ERP_Den": "至",
        "ERP_Den_ngay": "Date to",
        "ERP_Di_cont": "",
        "ERP_Di_dong": "手机",
        "ERP_Di_tau": "",
        "ERP_Dia_chi": "地址",
        "ERP_Dia_chi_lien_lac": "联系地址",
        "ERP_Dia_chi_tam_tru": "临时地址",
        "ERP_Dia_chi_thuong_tru": "永久地址",
        "ERP_Dien_giai": "描述",
        "ERP_Dien_thoai": "电话",
        "ERP_Dieu_khoan_thuong_mai": "",
        "ERP_Dieu_kien": "Filter",
        "ERP_Dieu_kien_dieu_chinh": "",
        "ERP_Dieu_kien_dieu_chinh_dot_trien_khai": "",
        "ERP_Dieu_kien_khong_duoc_de_trong": "",
        "ERP_Dinh_dang_file_khong_hop_le": "",
        "ERP_Dinh_dang_ngay_khong_dung": "",
        "ERP_Dinh_kem": "Attachments",
        "ERP_Dinh_vi_khoa": "",
        "ERP_Doanh_nghiep": "業務",
        "ERP_Doi_tuong_ap_dung": "",
        "ERP_Doi_tuong_cong_phep": "Leave object",
        "ERP_Doi_tuong_lao_dong": "",
        "ERP_Doi_tuong_tinh_luong": "",
        "ERP_Don_gia": "价格",
        "ERP_Don_gia_dieu_chinh": "",
        "ERP_Don_gia_khong_duoc_de_trong": "",
        "ERP_Don_gia_thuc_te": "",
        "ERP_Don_vi_chiu_phi": "",
        "ERP_Don_vi_det_bao": "",
        "ERP_Don_vi_tinh": "",
        "ERP_Don_vi_uy_thac": "",
        "ERP_Don_vi_uy_thac_khong_duoc_giong_voi_khach_hang_da_chon": "",
        "ERP_Don_vi_uy_thac_nay_khong_duoc_giong_voi_nha_cung_cap_da_chon": "",
        "ERP_Dong1": "",
        "ERP_Dong_goi": "",
        "ERP_Dong_y": "",
        "ERP_Dot_trien_khai": "",
        "ERP_Dot_trien_khai_co_lot_da_mapping_ban_khong_the_xoa_ban_co_muon_gui_yeu_cau_xoa_mapping_khong": "",
        "ERP_Dot_trien_khai_co_lot_da_mapping_ban_khong_the_xoa_dot_trien_khai": "",
        "ERP_Du_an": "Project",
        "ERP_Du_an_cong_trinh": "",
        "ERP_Du_an_moi": "",
        "ERP_Du_lieu": "数据",
        "ERP_Du_lieu_cham_phep": "Record Leave Data",
        "ERP_Du_lieu_chua_duoc_luu_ban_co_muon_thoat_khong": "",
        "ERP_Du_lieu_chua_duoc_luu_ban_co_muon_tiep_tuc_khong": "",
        "ERP_Du_lieu_da_duoc_su_dung": "",
        "ERP_Du_lieu_da_duoc_su_dung_ban_khong_duoc_xoa": "",
        "ERP_Du_lieu_dac_biet": "",
        "ERP_Du_lieu_dau_vao_la_bat_buoc": "",
        "ERP_Du_lieu_phep_tong_hop": "",
        "ERP_Du_lieu_xuat_excel_rong": "",
        "ERP_Dung_luong_File_khong_duoc_lon_hon": "",
        "ERP_Dung_sai": "",
        "ERP_Duyet": "批准",
        "ERP_Duyet_chung": "",
        "ERP_Duyet_cuoc_phi": "",
        "ERP_Duyet_de_xuat_dieu_chuyen_lao_dong": "",
        "ERP_Duyet_de_xuat_khen_thuong": "",
        "ERP_Duyet_dieu_chinh_thu_nhap": "",
        "ERP_Duyet_hop_dong": "",
        "ERP_Duyet_khong_thanh_cong": "",
        "ERP_Duyet_ky_luat": "",
        "ERP_Duyet_nghi_phep": "",
        "ERP_Duyet_thanh_cong": "",
        "ERP_Duyet_yeu_cau_tach_lot": "",
        "ERP_Email": "电子邮件",
        "ERP_Email_ca_nhan": "",
        "ERP_Email_ho_tro": "",
        "ERP_Email_khong_hop_le": "",
        "ERP_Ghi_chu": "注解 ",
        "ERP_Ghi_chu_deposit": "",
        "ERP_Ghi_chu_duyet": "Approve Notes",
        "ERP_Gia": "",
        "ERP_Gia_dinh": "",
        "ERP_Gia_mo_to_khai": "",
        "ERP_Gia_tri": "",
        "ERP_Gia_tri_bat_buoc_nhap": "",
        "ERP_Gia_tri_den": "",
        "ERP_Gia_tri_den_khong_de_trong": "",
        "ERP_Gia_tri_dot_trien_khai": "",
        "ERP_Gia_tri_dot_trien_khai_QD": "",
        "ERP_Gia_tri_hop_dong": "Contract amount",
        "ERP_Gia_tri_hop_dong_QD": "",
        "ERP_Gia_tri_lot": "",
        "ERP_Gia_tri_lot_QD": "",
        "ERP_Gia_tri_tu": "",
        "ERP_Gia_tri_tu_khong_de_trong": "",
        "ERP_Giam_dinh": "",
        "ERP_Giay_phep_lao_dong": "",
        "ERP_Gio_cong": "",
        "ERP_Gio_ra": "去",
        "ERP_Gio_tu_phai_nho_hon_Gio_den": "",
        "ERP_Gio_vao": "进来",
        "ERP_Gioi_tinh": "性别",
        "ERP_Gop": "",
        "ERP_Gop_lot": "",
        "ERP_Gui": "Send",
        "ERP_Gui_yeu_cau_thanh_cong": "",
        "ERP_Gui_yeu_cau_thanh_toan": "",
        "ERP_Hai_long_can_cai_tien_them": "",
        "ERP_Hang_hoa": "",
        "ERP_Hang_hoa_can_tim": "",
        "ERP_Hang_hoa_da_lap_dot_trien_khai_ban_khong_duoc_phep_xoa": "",
        "ERP_Hanh_dong": "",
        "ERP_Hanh_vi_vi_pham_ky_luat": "",
        "ERP_He_so_quy_cach_dong_goi": "",
        "ERP_He_thong_dang_trong_qua_trinh_nang_cap": "",
        "ERP_Hien_thi_tat_ca": "",
        "ERP_Hien_thi_vai_tro_khong_con_su_dung": "",
        "ERP_Hinh_thuc": "类型",
        "ERP_Hinh_thuc_huong_luong": "",
        "ERP_Hinh_thuc_khen_thuong": "",
        "ERP_Hinh_thuc_ky_luat": "",
        "ERP_Hinh_thuc_lam_viec": "工作类型",
        "ERP_Hinh_thuc_nghi_viec": "",
        "ERP_Ho_chieu": "",
        "ERP_Ho_so_nhan_vien": "员工文件",
        "ERP_Ho_tro": "",
        "ERP_Ho_va_ten": "全名",
        "ERP_Hoan_tat": "完成",
        "ERP_Hoan_thanh": "",
        "ERP_Hoan_toan_hai_long": "",
        "ERP_Hoc_van_chuyen_mon": "",
        "ERP_Hom_nay": "今天",
        "ERP_Hom_qua": "",
        "ERP_Hop_dong": "",
        "ERP_Hop_dong_ban": "",
        "ERP_Hop_dong_chua_duyet_ban_khong_the_doi_trang_thai": "",
        "ERP_Hop_dong_co_lot_da_mapping_ban_khong_the_xoa": "",
        "ERP_Hop_dong_da_co_dot_trien_khai_ban_khong_the_xoa": "",
        "ERP_Hop_dong_da_duoc_duyet_ban_khong_the_sua": "",
        "ERP_Hop_dong_da_duoc_duyet_ban_khong_the_xoa": "",
        "ERP_Hop_dong_da_mapping_ban_khong_the_xoa": "",
        "ERP_Hop_dong_mapping": "",
        "ERP_Hop_dong_mua": "",
        "ERP_Hun_trung": "",
        "ERP_Huong_dan_su_dung": "",
        "ERP_Huy": "Cancel",
        "ERP_Huy_phep": "",
        "ERP_Huy_phep_thanh_cong": "",
        "ERP_In_bao_cao": "Print Report",
        "ERP_Keo_tap_tin_vao_day": "",
        "ERP_Keo_va_tha_tap_tin_dinh_kem_vao_day": "",
        "ERP_Ket_thuc": "",
        "ERP_KeyID_dang_rong_hoac_thieu_khong_the_tai_danh_sach_dinh_kem": "",
        "ERP_Khac": "其他",
        "ERP_Khach_hang": "顾客",
        "ERP_Khao_sat_y_kien_khach_hang": "",
        "ERP_Khi_xac_nhan_hoan_tat_ban_se_khong_duoc_sua": "",
        "ERP_Kho_dong_hang": "",
        "ERP_Khoa_dong_mapping": "",
        "ERP_Khoi_la_bat_buoc_nhap": "",
        "ERP_Khong": " 不是",
        "ERP_Khong_co_dong_du_lieu_duoc_cap_nhat": "",
        "ERP_Khong_co_ket_noi": "",
        "ERP_Khong_co_tap_tin_dinh_kem": "",
        "ERP_Khong_co_thong_bao": "",
        "ERP_Khong_du_quyen": "",
        "ERP_Khong_duoc_nhap_trung_so_lot": "",
        "ERP_Khong_luu": "Not saved",
        "ERP_Khong_su_dung": "迟顿 ",
        "ERP_Khong_ten": "",
        "ERP_Khong_tim_thay_trang": "",
        "ERP_Khong_trang_thai": "",
        "ERP_Kich_co_toi_da": "",
        "ERP_Kien_thuc": "",
        "ERP_Ky_luong": "",
        "ERP_Lan": "Times",
        "ERP_Lap_de_xuat_dieu_chinh_thu_nhap": "",
        "ERP_Lap_de_xuat_dieu_chuyen_lao_dong": "",
        "ERP_Lap_de_xuat_khen_thuong": "",
        "ERP_Lap_de_xuat_ky_luat": "",
        "ERP_Lap_nghi_phep": "",
        "ERP_Lap_phu_luc": "",
        "ERP_Len_tau": "",
        "ERP_Lich_su": "历史",
        "ERP_Lich_su_tac_dong": "",
        "ERP_Lo": "",
        "ERP_Lo1": "",
        "ERP_Loai_HDLD": "",
        "ERP_Loai_bao_dong_goi": "",
        "ERP_Loai_chung_tu": "",
        "ERP_Loai_dieu_chinh": "",
        "ERP_Loai_doi_tuong_ap_dung": "",
        "ERP_Loai_gao_PO": "",
        "ERP_Loai_gao_SO": "",
        "ERP_Loai_hoa_don": "",
        "ERP_Loai_hop_dong": "合同类型",
        "ERP_Loai_nghiep_vu": "",
        "ERP_Loai_phep": "Leave Types",
        "ERP_Loai_phu_luc": "",
        "ERP_Loai_quy_trinh": "",
        "ERP_Loai_tap_tin_ho_tro": "",
        "ERP_Loai_thu_nhap": "",
        "ERP_Loai_tien": "货币",
        "ERP_Loc_chon_hanh_dong": "",
        "ERP_Loi_chua_xac_dinh": "",
        "ERP_Loi_khong_lay_duoc_token_CDN": "",
        "ERP_Lot_da_mapping_khong_the_xoa_ban_co_muon_gui_yeu_cau_xoa_mapping_khong": "",
        "ERP_Lot_da_tach": "",
        "ERP_Lot_yeu_cau_tach": "",
        "ERP_Luu": "保存",
        "ERP_Luu_dinh_kem_khong_thanh_cong": "",
        "ERP_Luu_khong_thanh_cong": "",
        "ERP_Luu_lich_su_khong_thanh_cong": "",
        "ERP_Luu_thanh_cong": "",
        "ERP_Luu_thong_tin_chung_tu_khong_thanh_cong": "",
        "ERP_Luu_va_nhap_tiep": "",
        "ERP_Ly_do": "原因",
        "ERP_Ly_do_huy": "",
        "ERP_Ma": "Code",
        "ERP_Ma_HDLD": "",
        "ERP_Ma_LOT": "",
        "ERP_Ma_Lo_da_ton_tai": "",
        "ERP_Ma_NV": "员工代码",
        "ERP_Ma_booking": "",
        "ERP_Ma_cham_cong": "",
        "ERP_Ma_chi_tiet_bao_bi": "",
        "ERP_Ma_chi_tiet_giao_hang": "",
        "ERP_Ma_co_cau": "",
        "ERP_Ma_cong_ty": "",
        "ERP_Ma_detail_cua_hop_dong_mua": "",
        "ERP_Ma_doi_tuong": "对象代码",
        "ERP_Ma_dot_trien_khai": "",
        "ERP_Ma_hang": "产品编号",
        "ERP_Ma_hang_PO": "",
        "ERP_Ma_hang_SO": "",
        "ERP_Ma_hang_hop_dong_ban": "",
        "ERP_Ma_hang_hop_dong_mua": "",
        "ERP_Ma_hop_dong": "",
        "ERP_Ma_khoa_cua_dot_trien_khai": "",
        "ERP_Ma_khoa_dong_hop_dong": "",
        "ERP_Ma_khoa_dong_mapping": "",
        "ERP_Ma_khoa_dot_trien_khai": "",
        "ERP_Ma_khoa_hop_dong": "",
        "ERP_Ma_khoa_hop_dong_ban": "",
        "ERP_Ma_khoa_hop_dong_mua": "",
        "ERP_Ma_khoa_lot": "",
        "ERP_Ma_lo": "",
        "ERP_Ma_loai_chung_tu": "",
        "ERP_Ma_lot_cua_dot_trien_khai": "",
        "ERP_Ma_mapping": "",
        "ERP_Ma_nguoi_dung": "用户代码",
        "ERP_Ma_nhan_vien": "员工代码",
        "ERP_Ma_nhan_vien_phu": "",
        "ERP_Ma_phuong_thuc_thanh_toan": "",
        "ERP_Ma_so_thue": "",
        "ERP_Ma_tau": "",
        "ERP_Ma_the_cham_cong": "",
        "ERP_Ma_thong_tin_thanh_toan": "",
        "ERP_Ma_trien_khai": "",
        "ERP_Ma_vai_tro": "",
        "ERP_Man_hinh": "",
        "ERP_Mapping": "",
        "ERP_Mapping_cu": "",
        "ERP_Mat_hang": "Item",
        "ERP_Mat_hang_khong_duoc_de_trong": "",
        "ERP_Mat_khau": "密码",
        "ERP_Mau_bao_cao": "報告碼",
        "ERP_Mau_bao_cao_khong_duoc_tim_thay": "",
        "ERP_Mau_giao_dien": "",
        "ERP_Mo_rong": "",
        "ERP_Mo_ta": "",
        "ERP_Muc_do_dong_y": "",
        "ERP_NVA_nghiep_vu_a": "",
        "ERP_Nam_nay": "",
        "ERP_Nam_truoc": "",
        "ERP_Ngan_hang": "",
        "ERP_Ngay": "日期",
        "ERP_Ngay_bat_dau": "开始日期",
        "ERP_Ngay_cap": "发行日期",
        "ERP_Ngay_cap_CMND": "",
        "ERP_Ngay_cham_cong": "",
        "ERP_Ngay_cong": "",
        "ERP_Ngay_dang_ky_tu_khong_duoc_lon_hon_ngay_dang_ky_den": "",
        "ERP_Ngay_danh_gia": "",
        "ERP_Ngay_danh_gia_bat_buoc_nhap": "",
        "ERP_Ngay_de_xuat": "Proposal Date",
        "ERP_Ngay_hieu_luc": "有效期",
        "ERP_Ngay_hieu_luc_den": "",
        "ERP_Ngay_hieu_luc_tu": "",
        "ERP_Ngay_in": "",
        "ERP_Ngay_ket_thuc": "完成日期",
        "ERP_Ngay_ky": "签署日期",
        "ERP_Ngay_lap": "Created Date",
        "ERP_Ngay_nghi": "",
        "ERP_Ngay_nghi_viec": "",
        "ERP_Ngay_nhan": "Received Date",
        "ERP_Ngay_phep": "Leave date",
        "ERP_Ngay_phieu": "傳票日期",
        "ERP_Ngay_phun": "",
        "ERP_Ngay_sinh": "出生日期",
        "ERP_Ngay_tac_dong": "",
        "ERP_Ngay_tao": "",
        "ERP_Ngay_tau_di": "",
        "ERP_Ngay_tau_vao": "",
        "ERP_Ngay_tham_gia_bao_hiem": "",
        "ERP_Ngay_thuc_te_booking": "",
        "ERP_Ngay_tu_khong_duoc_lon_hon_ngay_den": "",
        "ERP_Ngay_vao": "开始日期",
        "ERP_Ngay_vao_lam": "开始日期",
        "ERP_Nghi_phep": "",
        "ERP_Nghi_viec": "",
        "ERP_Nghiep_vu": "",
        "ERP_Nghiep_vu_da_co_du_lieu": "",
        "ERP_Nguoi_dai_dien": "Agency",
        "ERP_Nguoi_danh_gia": "",
        "ERP_Nguoi_danh_gia_bat_buoc_nhap": "",
        "ERP_Nguoi_dung_khong_su_dung": "",
        "ERP_Nguoi_duyet": "批准者",
        "ERP_Nguoi_lap": "创建者",
        "ERP_Nguoi_lapU": "",
        "ERP_Nguoi_lien_he": "",
        "ERP_Nguoi_nuoc_ngoai": "",
        "ERP_Nguoi_phu_trach": "",
        "ERP_Nguoi_quan_ly_truc_tiep": "Direct Manager",
        "ERP_Nguoi_tao": "",
        "ERP_Nguoi_uy_quyen": "",
        "ERP_Nha_cung_cap": "Supplier",
        "ERP_Nhan_mac": "",
        "ERP_Nhan_vien": "",
        "ERP_Nhap": "",
        "ERP_Nhap_Ten_Dang_Nhap": "Enter username",
        "ERP_Nhap_mat_khau": "输入密码",
        "ERP_Nhap_tiep": "",
        "ERP_Nhap_tu_tim_kiem": "",
        "ERP_Nho_mat_khau": "",
        "ERP_Nho_mat_khau1": "",
        "ERP_Nhom_hanh_vi_vi_pham_ky_luat": "",
        "ERP_Nhom_luong": "",
        "ERP_Nhom_nhan_vien": "",
        "ERP_Nhung_y_kien_nay_se_duoc_cong_ty_chung_toi_cong_nhan": "",
        "ERP_No": "借方",
        "ERP_No_data": "",
        "ERP_No_link": "",
        "ERP_Noi_cap": "发行地点",
        "ERP_Noi_cap_CMND": "",
        "ERP_Noi_dung": "内容",
        "ERP_Noi_dung_can_tim_kiem": "",
        "ERP_Noi_sinh": "出生地",
        "ERP_O_tab": "",
        "ERP_Phai_thuc_hien_phan_bo_cac_dong_chi_tiet_bao_bi_va_chi_tiet_giao_hang": "",
        "ERP_Phan_bo_chi_tiet_giao_hang": "",
        "ERP_Phan_bo_mapping_tu_yeu_cau_tach_lot": "",
        "ERP_Phan_bo_thong_tin_chi_tiet_giao_hang": "",
        "ERP_Phan_quyen": "",
        "ERP_Phan_quyen_chuc_nang": "",
        "ERP_Phan_quyen_du_lieu": "",
        "ERP_Phan_quyen_theo_vai_tro": "",
        "ERP_Phep": "年假",
        "ERP_Phien_ban": "",
        "ERP_Phieu_cham_cong": "",
        "ERP_Phieu_dang_ky_da_duoc_duyet_ban_khong_duoc_phep_thay_doi": "",
        "ERP_Phong_ban": "部门",
        "ERP_Phong_ban_moi": "",
        "ERP_Phu_luc_da_duoc_duyet": "",
        "ERP_Phu_luc_da_duoc_duyet_ban_khong_duoc_sua": "",
        "ERP_Phu_luc_da_duoc_duyet_ban_khong_duoc_xoa": "",
        "ERP_Phu_luc_hop_dong": "",
        "ERP_Phu_trach_chung_tu": "",
        "ERP_Phun_trung": "",
        "ERP_Phuong_thuc": "",
        "ERP_Phuong_thuc_da_ton_tai": "",
        "ERP_Phuong_thuc_khong_duoc_de_trong": "",
        "ERP_Phuong_thuc_thanh_toan": "付款方法",
        "ERP_Phuong_tien": "",
        "ERP_Phuong_tien_van_chuyen": "",
        "ERP_Phuong_tien_van_chuyen_bat_buoc_nhap": "",
        "ERP_Quan_he": "关系",
        "ERP_Quan_he_gia_dinh": "家庭关系",
        "ERP_Quan_ly": "",
        "ERP_Quoc_gia": "",
        "ERP_Quoc_tich": "",
        "ERP_Quy_cach_dong_goi": "",
        "ERP_Quy_uoc_nhu_sau": "",
        "ERP_Quyen_han": "",
        "ERP_SDT_ho_tro": "",
        "ERP_STT": " 序",
        "ERP_SXDong_goi": "",
        "ERP_Sao_chep": "",
        "ERP_Sinh_nhat": "",
        "ERP_So_BL": "",
        "ERP_So_Fax": "",
        "ERP_So_bao_hiem": "",
        "ERP_So_chi_tiet_bao_bi": "",
        "ERP_So_chi_tiet_giao_hang": "",
        "ERP_So_dien_thoai": "Tel no.",
        "ERP_So_dien_thoai_khong_hop_le": "",
        "ERP_So_dong": "",
        "ERP_So_hoa_don": "",
        "ERP_So_hop_dong": "Contract No",
        "ERP_So_hop_dong_ban": "",
        "ERP_So_hop_dong_da_ton_tai": "",
        "ERP_So_hop_dong_mua": "",
        "ERP_So_lan_khu_trung": "",
        "ERP_So_lot": "",
        "ERP_So_luong": "数量",
        "ERP_So_luong_QD": "",
        "ERP_So_luong_bao": "",
        "ERP_So_luong_bao_yeu_cau": "",
        "ERP_So_luong_bao_yeu_cau_mapping": "",
        "ERP_So_luong_bao_yeu_cau_vua_nhap_khong_hop_le": "",
        "ERP_So_luong_cho_phep": "",
        "ERP_So_luong_chua_trien_khai": "",
        "ERP_So_luong_co_the_mapping": "",
        "ERP_So_luong_con_lai": "Remain Quantity",
        "ERP_So_luong_con_lai_cua_hop_dong_mua_phai_bang_0": "",
        "ERP_So_luong_con_lai_dong_cho_sa_lan_toi_cuoi_ngay_nhap_lieu": "",
        "ERP_So_luong_da_giao": "",
        "ERP_So_luong_da_giao_vua_nhap_khong_hop_le": "",
        "ERP_So_luong_da_mapping": "",
        "ERP_So_luong_da_nghi_den_ky_hien_tai": "",
        "ERP_So_luong_da_thuc_hien": "",
        "ERP_So_luong_da_trien_khai": "",
        "ERP_So_luong_dang_trien_khai_la": "",
        "ERP_So_luong_di_cua_booking": "",
        "ERP_So_luong_duoc_cap": "Quantity",
        "ERP_So_luong_duoc_nhap_nho_hon_tong_so_luong_da_giao": "",
        "ERP_So_luong_huy1": "",
        "ERP_So_luong_khong_duoc_bang_0": "",
        "ERP_So_luong_khong_duoc_de_trong": "",
        "ERP_So_luong_khong_duoc_vuot_qua": "",
        "ERP_So_luong_khong_hop_le": "",
        "ERP_So_luong_lot": "",
        "ERP_So_luong_lot_tach": "",
        "ERP_So_luong_phuong_tien_da_dong": "",
        "ERP_So_luong_phuong_tien_da_dong_goi": "",
        "ERP_So_luong_thuc_hien_cua_mapping": "",
        "ERP_So_luong_thuc_hien_mapping": "",
        "ERP_So_luong_ton_den_cuoi_nam": "Leave Quantity To End Year",
        "ERP_So_luong_ton_den_ky_hien_tai": "",
        "ERP_So_luong_tong_cua_dot_trien_khai": "",
        "ERP_So_luong_vua_nhap_nho_hon_so_luong_dang_trien_khai": "",
        "ERP_So_luong_vua_nhap_nho_hon_so_luong_dang_trien_khai_ban_co_muon_chinh_sua_so_luong_dang_trien_khai_cua_hang_hoa_nay": "",
        "ERP_So_luong_yeu_cau": "",
        "ERP_So_luong_yeu_cau_cua_mapping": "",
        "ERP_So_luong_yeu_cau_mapping": "",
        "ERP_So_mapping": "",
        "ERP_So_phieu": "优惠券号码",
        "ERP_So_phu_luc": "",
        "ERP_So_tai_khoan": "",
        "ERP_So_thang": "月数",
        "ERP_So_thu_tu": "",
        "ERP_So_tien_thanh_toan": "",
        "ERP_So_tien_thuc_te": "",
        "ERP_So_tien_yeu_cau": "",
        "ERP_Su_dung": "",
        "ERP_Sua": "Edit",
        "ERP_Sua_khac": "",
        "ERP_Tach": "分裂",
        "ERP_Tach_lot": "",
        "ERP_Tai_khoan": "帐户",
        "ERP_Tai_khoan_bi_khoa_10_phut": "",
        "ERP_Tai_khoan_dang_nhap_hoac_mat_khau_khong_dung": "",
        "ERP_Tai_khoan_khong_co_quyen": "",
        "ERP_Tai_lieu": "",
        "ERP_Tai_trong": "",
        "ERP_Tai_trong_phuong_tien": "",
        "ERP_Tam_dap_ung_Can_cai_tien_them": "",
        "ERP_Tap_tin": "",
        "ERP_Tap_tin_dinh_kem_dang_duoc_xu_ly": "",
        "ERP_Tap_tin_tai_len": "",
        "ERP_Tat_ca": "",
        "ERP_Tat_ca_cac_lot_phai_ket_thuc_khi_ket_thuc_dot_trien_khai": "",
        "ERP_Ten": "名称 \t",
        "ERP_Ten_Dang_Nhap": "TÊN ĐĂNG NHẬP",
        "ERP_Ten_cong_ty": "",
        "ERP_Ten_dieu_chinh_chung_tu_cua_lot": "",
        "ERP_Ten_doi_tuong": "对象名称",
        "ERP_Ten_hang": "产品名称",
        "ERP_Ten_hang_hop_dong_ban": "",
        "ERP_Ten_hang_hop_dong_mua": "",
        "ERP_Ten_hang_khong_duoc_de_trong": "",
        "ERP_Ten_hang_thuc_te": "",
        "ERP_Ten_lo": "",
        "ERP_Ten_man_hinh": "用户名称",
        "ERP_Ten_nguoi_dung": "用户名",
        "ERP_Ten_nguoi_quan_he": "",
        "ERP_Ten_nhan_vien": "",
        "ERP_Ten_phong_ban": "部门名称",
        "ERP_Ten_tau": "",
        "ERP_Ten_thong_tin_thanh_toan": "",
        "ERP_Ten_vai_tro": "",
        "ERP_Tham_nien": "排行",
        "ERP_Tham_so_truyen_vao_khong_hop_le": "",
        "ERP_Thang_nay": "本月",
        "ERP_Thang_truoc": "",
        "ERP_Thanh_tien": "Amount",
        "ERP_Thanh_tien_QD": "",
        "ERP_Thanh_tien_deposit": "",
        "ERP_Thanh_tien_deposit_QD": "",
        "ERP_Thanh_toan": "付款",
        "ERP_Thanh_vien_cua_Nihon_Grouping_va_Pan": "",
        "ERP_Thay_doi_hop_dong_se_lam_thay_doi_du_lieu": "",
        "ERP_The_can_cuoc": "",
        "ERP_The_luu_tru": "",
        "ERP_Them": "Add",
        "ERP_Them_chi_tiet_giao_hang_vuot_qua_so_luong_mapping": "",
        "ERP_Them_dieu_kien": "",
        "ERP_Them_dot_trien_khai": "",
        "ERP_Them_hang_hoa": "",
        "ERP_Them_lo": "",
        "ERP_Them_mapping": "",
        "ERP_Them_moi": "",
        "ERP_Them_moi1": "添新",
        "ERP_Them_moi_lo": "",
        "ERP_Thoi_gian": "时间",
        "ERP_Thoi_gian_bat_dau_dong_hang": "",
        "ERP_Thoi_gian_den_cang": "",
        "ERP_Thoi_gian_dong_hang": "",
        "ERP_Thoi_gian_giao_bao": "",
        "ERP_Thoi_gian_giao_hang": "",
        "ERP_Thoi_gian_giao_hang_den_ngay": "",
        "ERP_Thoi_gian_giao_hang_du_kien": "",
        "ERP_Thoi_gian_giao_hang_du_kien_cua_dot_trien_khai": "",
        "ERP_Thoi_gian_giao_hang_du_kien_den": "",
        "ERP_Thoi_gian_giao_hang_du_kien_tu": "",
        "ERP_Thoi_gian_giao_hang_theo_lot": "",
        "ERP_Thoi_gian_giao_hang_tu_ngay": "",
        "ERP_Thoi_gian_hieu_luc": "",
        "ERP_Thoi_gian_ket_thuc_dong_hang": "",
        "ERP_Thoi_gian_ra_vao_VN": "",
        "ERP_Thong_bao": "Announcement",
        "ERP_Thong_bao1": "",
        "ERP_Thong_baoU": "",
        "ERP_Thong_bao_giao_hang": "",
        "ERP_Thong_bao_lot_da_duoc_mapping": "",
        "ERP_Thong_bao_lot_da_mapping": "",
        "ERP_Thong_bao_xac_nhan_chung_tu": "",
        "ERP_Thong_tin_bao_bi": "",
        "ERP_Thong_tin_ca_nhan": "个人信息",
        "ERP_Thong_tin_chinh": "",
        "ERP_Thong_tin_chung": "一般资料",
        "ERP_Thong_tin_cu": "",
        "ERP_Thong_tin_cung_cap_khong_hop_le": "",
        "ERP_Thong_tin_dong_goi": "",
        "ERP_Thong_tin_giao_hang": "",
        "ERP_Thong_tin_hoa_don": "",
        "ERP_Thong_tin_huy": "",
        "ERP_Thong_tin_khac": "",
        "ERP_Thong_tin_lien_lac": "联系信息",
        "ERP_Thong_tin_mapping": "",
        "ERP_Thong_tin_nguoi_dung": "",
        "ERP_Thong_tin_phan_quyen": "",
        "ERP_Thong_tin_phu": "",
        "ERP_Thong_tin_phu_luc": "",
        "ERP_Thong_tin_tang_nhan_vien": "",
        "ERP_Thong_tin_thanh_toan": "",
        "ERP_Thong_tin_thanh_toan_cua_lot": "",
        "ERP_Thong_tin_thanh_toan_lot": "",
        "ERP_Thong_tin_vai_tro": "",
        "ERP_Thuc_hien": "",
        "ERP_Thuc_hien_mapping": "",
        "ERP_Thuc_hien_thanh_cong": "",
        "ERP_Tieu_chuan_chat_luong": "",
        "ERP_Tim_doi_tuong": "",
        "ERP_Tim_kiem": "",
        "ERP_Tim_kiem_cham_cong": "",
        "ERP_Tim_kiem_cong_ty": "尋找公司",
        "ERP_Tim_kiem_doi_tuong": "",
        "ERP_Tim_kiem_hang_hoa": "",
        "ERP_Tim_kiem_ho_so_nhan_su": "",
        "ERP_Tim_kiem_hop_dong": "",
        "ERP_Tim_kiem_ten_nhan_vien_va_ma_nhan_vien": "",
        "ERP_Tinh_trang_hien_tai": "",
        "ERP_Tinh_trang_hoan_thanh": "",
        "ERP_Tinh_trang_hon_nhan": "婚姻状况",
        "ERP_To_chuc": "组织",
        "ERP_To_nhom": "团队",
        "ERP_To_nhom_moi": "",
        "ERP_Toan_tu": "",
        "ERP_Toan_tu_khong_duoc_de_trong": "",
        "ERP_Ton_giao": "宗教",
        "ERP_Tong": "",
        "ERP_Tong_diem": "",
        "ERP_Tong_gia_tri_hoa_don": "",
        "ERP_Tong_gia_tri_hoa_don_QD": "",
        "ERP_Tong_gia_tri_hop_dong": "Total contract amount",
        "ERP_Tong_gia_tri_hop_dong_QD": "",
        "ERP_Tong_so_luong": "",
        "ERP_Tong_so_luong_cac_lot_phai_bang_so_luong_cua_lot_tach": "",
        "ERP_Tong_so_luong_chi_tiet_giao_hang_duoc_phan_bo_cua_mapping": "",
        "ERP_Tong_so_luong_dong_goi_cua_cac_mapping_phai_bang_so_luong_dong_goi_cua_chi_tiet_giao_hang": "",
        "ERP_Tong_so_luong_dong_goi_cua_cac_mapping_vuot_qua_so_luong_dong_goi_cua_chi_tiet_giao_hang": "",
        "ERP_Tong_so_ngay": "",
        "ERP_Trang": "",
        "ERP_Trang_Chu": "首页",
        "ERP_Trang_thai": "状态",
        "ERP_Trang_thai_Phu_luc_hop_dong": "",
        "ERP_Trang_thai_chi_tiet_giao_hang": "",
        "ERP_Trang_thai_cu": "",
        "ERP_Trang_thai_deposit": "",
        "ERP_Trang_thai_duyet": "审批状态",
        "ERP_Trang_thai_duyet_Hop_dong": "",
        "ERP_Trang_thai_hoa_don_": "",
        "ERP_Trang_thai_hoan_tat_ban_khong_duoc_phep_xoa": "",
        "ERP_Trang_thai_hop_dong": "",
        "ERP_Trang_thai_lam_viec": "",
        "ERP_Trang_thai_moi": "",
        "ERP_Trang_thai_phuong_tien": "",
        "ERP_Trinh_do_hoc_van": "教育程度",
        "ERP_Trinh_do_van_hoaU": "",
        "ERP_Tro_giup": "",
        "ERP_Trong_luong_Gross": "",
        "ERP_Trong_luong_bao_bi": "",
        "ERP_Trong_luong_cont": "",
        "ERP_Truong_nay_bat_buoc_nhap": "",
        "ERP_Truong_nay_khong_hop_le": "",
        "ERP_Truong_ngay_la_bat_buoc": "",
        "ERP_Truy_cap_khong_hop_le": "",
        "ERP_Truy_van_tang_nhan_vien": "",
        "ERP_Truy_van_thong_tin_cong_phep": "",
        "ERP_Tu": "从",
        "ERP_Tu_choi": "拒绝",
        "ERP_Tu_choi_W75": "",
        "ERP_Tu_choi_khong_thanh_cong": "",
        "ERP_Tu_choi_thanh_cong": "",
        "ERP_Tu_ngay": "Date from",
        "ERP_Tuan_nay": "本星期",
        "ERP_Tuan_truoc": "",
        "ERP_Tuoi": "年龄",
        "ERP_Tuyen_dung": "招聘",
        "ERP_Ty_gia": "汇率",
        "ERP_Ty_le_deposit": "",
        "ERP_URL": "",
        "ERP_URL_khong_hop_le": "",
        "ERP_Username_va_Password_khong_hop_le!": "",
        "ERP_Vai_tro": "",
        "ERP_Van_chuyen_thanh_toan": "",
        "ERP_VoucherID_khong_duoc_de_trong": "",
        "ERP_Vui_long_chon_hang_hoa": "",
        "ERP_Vui_long_nhap_so_luong_va_gia_hang_hoa": "",
        "ERP_Vui_long_truy_cap_sau": "",
        "ERP_Vuot_qua_so_luong_tap_tin_cho_phep": "",
        "ERP_Xac_nhan": "Confirm",
        "ERP_Xac_nhan_giao_hang": "",
        "ERP_Xac_nhan_nhan_vien_nhan_viec_tai_du_an": "",
        "ERP_Xac_nhan_so_luong_da_giao": "",
        "ERP_Xac_nhan_tat_ca": "",
        "ERP_Xac_nhan_thanh_cong": "",
        "ERP_Xem": "看",
        "ERP_Xem_chi_tiet": "View Detail",
        "ERP_Xem_hop_dong_goc": "",
        "ERP_Xem_tap_tin": "",
        "ERP_Xem_tat_ca": "",
        "ERP_Xem_them": "",
        "ERP_Xem_truoc": "",
        "ERP_Xin_cam_on": "",
        "ERP_Xoa": "Delete",
        "ERP_Xoa_dong": "",
        "ERP_Xoa_du_lieu": "",
        "ERP_Xoa_tam": "",
        "ERP_Xoa_thanh_cong": "",
        "ERP_Xuat": "",
        "ERP_Xuat_Excel": "",
        "ERP_Xuat_PDF": "",
        "ERP_Xuat_bao_cao": "出口報告",
        "ERP_Xuat_du_lieu": "",
        "ERP_Xuat_du_lieu_thanh_cong": "",
        "ERP_Xuat_mau_in_xac_nhan_giao_hang": "",
        "ERP_Xuat_thong_bao_giao_hang": "",
        "ERP_Xuat_word": "",
        "ERP_Y_kien_dong_gop": "",
        "ERP_Yes": "",
        "ERP_Yeu_cau_tach_lot": "",
        "ERP_Yeu_cau_thanh_toan": "",
        "ERP_dang_nhap_lai": "以再次登陆",
        "ERP_dong": "",
        "ERP_khong_co_du_lieu": "",
        "ERP_trong": ""
    },
    "timestamps": 1645669418012
};
