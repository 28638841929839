/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/9/2019
 * @Example
 */

import React, {Component} from 'react';
import { connect } from 'react-redux';

import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";
import {ThemeProvider as CoreThemeProvider} from "diginet-core-ui/theme";

class ThemeWrapper extends Component {

    // hexToRGB = (hex) => {
    //     const r = parseInt(hex.slice(1, 3), 16),
    //         g = parseInt(hex.slice(3, 5), 16),
    //         b = parseInt(hex.slice(5, 7), 16);
    //
    //         return "rgb(" + r + ", " + g + ", " + b + ")";
    // };

    hexToRGB = (hex, alpha) => {
        const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
        if (alpha) {
            return r + ", " + g + ", " + b + ", " + alpha;
        } else {
            return r + ", " + g + ", " + b;
        }
    };

    setColorScssStatic = (color) => {
        if (!color) return false;
        if (color.SubColor) {
            document.body.style.setProperty('--primary-bg-color-light', this.hexToRGB(color.SubColor));
        }
        if (color.MainColor) {
            document.body.style.setProperty('--primary-bg-color', this.hexToRGB(color.MainColor));
        }
        if (color.contrastText) {
            document.body.style.setProperty('--primary-font-color', color.contrastText);
        }
        if (color.SecondaryColor) {
            document.body.style.setProperty('--secondary-bg-color', this.hexToRGB(color.SecondaryColor));
        }

        //general color
        if (color.Success) {
            document.body.style.setProperty('--success-bg-color', this.hexToRGB(color.Success));
        }
        if (color.Info) {
            document.body.style.setProperty('--info-bg-color', this.hexToRGB(color.Info));
        }
        if (color.Warning) {
            document.body.style.setProperty('--warning-bg-color', this.hexToRGB(color.Warning));
        }
        if (color.Danger) {
            document.body.style.setProperty('--danger-bg-color', this.hexToRGB(color.Danger));
        }
    };

    render() {
        const {color} = this.props;
        this.setColorScssStatic(color);
        const textColor = "#222B45";
        const textLabelDefault = "#505C80";
        const subTextColor = "#687799";
        const textDisabledColor = "#8F9BB3";
        const theme = createMuiTheme({
            palette: {
                primary: {
                    light: color && color.SubColor ? color.SubColor : '#111D5E21',
                    main: color && color.MainColor ? color.MainColor : '#111D5E',
                    contrastText: color && color.contrastText ? color.contrastText : '#fafafa',
                },
                secondary: {
                    main: color && color.SecondaryColor ? color.SecondaryColor : '#f44336',
                },
                success: {
                    main: color && color.Success ? color.Success : '#00D68F',
                },
                info: {
                    main: color && color.Info ? color.Info : '#0095FF',
                },
                warning: {
                    main: color && color.Warning ? color.Warning : '#FFAA00',
                },
                danger: {
                    main: color && color.Danger ? color.Danger : '#FF3D71',
                },
                hover: {
                    main: '#EFF5FF'
                },
                text: {
                    subtext: subTextColor
                }
            },
            props: {
                MuiTextField: {
                    margin: 'dense',
                },
            },
            overrides: {
                MuiTextField: {
                    root: {
                        backgroundColor: '#fff'
                    }
                },
                MuiFormControlLabel: {
                    root: {
                        marginLeft: 0
                    }
                },
                MuiTabs: {
                    root: {
                        minHeight: 42,
                    },
                    indicator: {
                        height: 4,
                        borderRadius: 1,
                        width: "95%",
                        marginRight: 2
                    }
                },
                MuiTab: {
                    root: {
                        fontSize: '1rem !important',
                        minHeight: 42,
                        margin: '0 2px'
                    },
                    textColorPrimary: {
                        color: subTextColor
                    }
                },
                MuiOutlinedInput: {
                    root: {
                        height: '100%'
                    }
                },
                MuiSelect: {
                    icon: {
                        top: 'calc(50% - 14px)'
                    },
                    iconOutlined: {
                        right: 0
                    }
                },
                MuiCardContent: {
                    root: {
                        padding: 15
                    }
                },
                MuiFormHelperText: {
                    root: {
                        position: "absolute",
                        bottom: '-20px',
                        left: 0,
                        fontSize: 11
                    }
                },
                MuiAutocomplete: {
                    popup: {
                        zIndex: 10000
                    }
                },
                PrivateNotchedOutline: {
                    legend: {
                        border: 'none'
                    }
                },
                MuiDialogTitle: {
                    root: {
                        backgroundColor: '#F7F9FC'
                    }
                },
                MuiDialogActions: {
                    root: {
                        backgroundColor: '#F7F9FC'
                    }
                },
                MuiButton: {
                    root: {
                        '&:hover': {
                            backgroundColor: '#EFF5FF'
                        },
                        '&.Mui-disabled': {
                            color: textDisabledColor
                        }
                    },
                },
                MuiFormLabel: {
                    root: {
                        color: textLabelDefault,
                        '&.Mui-disabled': {
                            color: textDisabledColor
                        }
                    }
                },
                MuiInput: {
                    underline: {
                        '&:before': {
                            borderBottom: '1px solid ' + subTextColor
                        }
                    }
                },
                MuiInputBase: {root: {fontWeight: 500, '&.Mui-disabled': {color: textColor}}},
                MuiDivider: {root: {backgroundColor: '#E4E9F2'}},
                MuiIconButton: {root: {color: subTextColor, '&.Mui-disabled': {color: textDisabledColor}}},
                MuiChip: {root: {color: subTextColor}}
            },
            typography: {
                fontFamily: "'SF Text 2','Font Awesome 5 Free',sans-serif",
                textTransform: "none",
                fontSize: 14,
                button: {
                    textTransform: 'none',
                },
                input: {
                    padding: 'inherit'
                },
            }
        });

        return (
            <CoreThemeProvider>
                <MuiThemeProvider theme={theme}>
                    <div>
                        { this.props.children }
                    </div>
                </MuiThemeProvider>
            </CoreThemeProvider>
        )
    }
}

export default connect(state => ({
        color: state.main.color,
    })
)(ThemeWrapper);
